import {
  ActionIcon,
  Divider,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Popover,
  TextInput,
  Text,
  Grid,
  Title,
  Tooltip,
  Button as Buttons,
  Textarea,
  Badge,
  Box,
} from "@mantine/core";
import {
  Button,
  List,
  Select,
  message,
  Upload,
  Steps,
  Table,
  Popconfirm,
  InputNumber,
  Input,
  Form,
  Typography,
} from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddItemContext from "../../../../AddItemsContext";
import moment from "moment";

import {
  IconDeviceFloppy,
  IconEdit,
  IconMessages,
  IconSquareCheck,
  IconSquareX,
  IconThumbDown,
  IconThumbUp,
  IconTrash,
} from "@tabler/icons";

let data: any = [];

interface Item {
  key: string;
  uuid: string;
  name: string;
  button_ui: any;
  admin_qty: number;
  button_ui_admin: any;
  button_ui_administrator: any;
  age: number;
  address: string;
}

const EditableAdmin = (props: any) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [t, setT] = useState([]);
  const [value, setValue] = useState(0);
  const [adminQty, setAdminQty] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [searchValues, setSearchValues] = useState("");
  const [uuids, setUuids] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonQty, setButtonQty] = useState(false);
  const [counter, setCounter] = useState(0);
  const [values, setValues] = useState(0);

  // const { addToItem, items } = useContext(AddItemContext);

  let { user } = useSelector((state: any) => ({ ...state }));

  let { show } = useSelector((state: any) => ({ ...state }));

  const navigate = useNavigate();

  let { control_id } = useParams();

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: Item;
    index: number;
    children: React.ReactNode;
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber />
      ) : (
        <Input.TextArea showCount maxLength={35} />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ admin_qty: adminQty, remarks: "", ...record });
    setEditingKey(record.key);
  };

  const columns = [
    {
      title: "S/N",
      dataIndex: "id",
      key: "id",
      render: (value: any, item: any, index: any) =>
        index === 0 ? index + 1 : index + 1,
    },

    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: "3%",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Manager's Approval Status",
      dataIndex: "status",
      key: "status",
      width: "3%",
      render: (value: any) => (
        <Badge
          variant="gradient"
          gradient={{
            from: value === "APPROVED" ? "teal" : "orange",
            to: value === "APPROVED" ? "lime" : "red",
            deg: 105,
          }}
        >
          {value}
        </Badge>
      ),
    },

    {
      title: "Manager's Approver",
      dataIndex: "approval_name",
      key: "approval_name",
      width: "2%",
    },

    {
      title: "Requester Name",
      dataIndex: "requester_name",
      key: "requester_name",
      width: "3%",
      filteredValue: [searchValues],
      onFilter(value: any, record: any) {
        return (
          String(record.requester_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.control_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.approval_name)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },

    {
      title: "Admin's Status",
      dataIndex: "admin_status",
      key: "admin_status",
      width: "3%",
      render: (value: any) => (
        <Badge
          variant="gradient"
          gradient={{
            from: value === "RELEASED" ? "teal" : "orange",
            to: value === "RELEASED" ? "lime" : "red",
            deg: 105,
          }}
        >
          {value}
        </Badge>
      ),
    },

    {
      title: "Requester Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "3%",
      //ellipsis: true,
      // render: (value: any) =>
    },

    {
      title: "Stocks Count (Balance)",
      dataIndex: "consumable",
      key: "consumable",
      width: "2%",
      //  render: (value: any, item: any) => value.in,
    },

    {
      title: "Admin's Approved Quantity",
      dataIndex: "admin_qty",
      key: "admin_qty",
      width: "2%",
      editable: true,
    },

    {
      title: "Reason(s)",
      dataIndex: "remarks",
      key: "remarks",
      // responsive: ["lg"],
      width: "30%",
      editable: true,
      //ellipsis: true,
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // responsive: ["lg"],
      width: "25%",
      // editable: true,
      //ellipsis: true,
      render: (date: any) => {
        return <Box> {moment(date).format("MMMM Do YYYY, h:mm:s a")} </Box>;
      },
    },

    {
      title: "Operation(s)",
      dataIndex: "operation",
      width: "25%",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        // console.log(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.uuid)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Grid>
            <Group position="left" spacing="sm">
              <Tooltip
                label="Edit
                "
                withArrow
                arrowSize={5}
                transition="skew-up"
                transitionDuration={300}
              >
                <ActionIcon
                  // variant=""
                  onClick={() => edit(record)}
                  variant="subtle"
                  component="button"
                  disabled={record.button_ui_administrator}
                >
                  <IconEdit size={24} stroke={1.5} color={"black"} />
                </ActionIcon>
              </Tooltip>

              <Divider orientation="vertical" />
              <Popconfirm
                title="Confirmation"
                // description="Are you sure to delete this task?"
                // onConfirm={confirm}
                onConfirm={() => confirm(record)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip
                  label="Accept"
                  withArrow
                  arrowSize={5}
                  transition="skew-up"
                  transitionDuration={300}
                >
                  <ActionIcon
                    variant="subtle"
                    component="button"
                    disabled={record.button_ui_administrator}
                    // onClick={() => console.log(a.button_ui)}
                  >
                    <IconThumbUp size={24} stroke={1.5} color={"lime"} />
                  </ActionIcon>
                </Tooltip>
              </Popconfirm>

              <Divider orientation="vertical" />

              {/* <Popover
                width={300}
                trapFocus
                position="top-end"
                withArrow
                shadow="md"
              > 
               <Popover.Target>  */}

              <Popconfirm
                title="Confirmation"
                // description="Are you sure to delete this task?"
                // onConfirm={confirm}
                onConfirm={() => confirmDeclined(record)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip
                  label="Reject"
                  withArrow
                  arrowSize={5}
                  transition="skew-up"
                  transitionDuration={300}
                >
                  <ActionIcon
                    // variant=""

                    variant="subtle"
                    component="button"
                    disabled={record.button_ui_administrator}
                  >
                    <IconThumbDown size={24} stroke={1.5} color={"red"} />
                  </ActionIcon>
                </Tooltip>
              </Popconfirm>
            </Group>
          </Grid>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], index: any) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    //selectedRowKeys const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // console.log("Bidun", selectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const loadDataFromDB = () => {
    data = [];
    fetch(`https://frisbackendurl.azurewebsites.net/getstocks/${control_id}`)
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value: any, index: any) => {
          console.log(value);
          data.push({
            key: nanoid(),
            items: value.items,
            quantity: value.quantity,
            control_number: value.control_number,
            status: value.status,
            uuid: value.uuid,
            remarks: value.remarks,
            admin_status: value.admin_status,
            admin_qty: value.admin_qty,
            button_ui_administrator: value.button_ui_administrator,
            consumable: value.consumable.balance,
            approval_name: value.manager,
            requester_name: value.requester_name,
            id: value.id,
            date: value.updatedAt,
          });
          setValues(Number(value.count_of_items));
        });
        setDataSource(data);
      })
      .finally(() => {
        //   setLoading(false);
      });
    //  };
  };

  //   const confirm = async (data: any) => {
  //     //   console.log(data);
  //     try {
  //       await axios
  //         .put(`http://localhost:5000/requisitions/${data.uuid}`, {
  //           admin_status: "APPROVED",
  //           quantity: data.admin_qty === 0 ? data.quantity : data.admin_qty,
  //         })
  //         .then(() => {
  //           setCounter((prevCount) => prevCount + 1);
  //           loadDataFromDB();
  //           message.success({
  //             content: "Operation is Successfully !",
  //             duration: 7,
  //           });
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const confirm = async (uuid: any) => {
    //console.log(uuid.)
    await axios
      .put(`https://frisbackendurl.azurewebsites.net/getstocks/${uuid.uuid}`, {
        admin_status: "RELEASED",
        quantity: uuid.admin_qty === 0 ? uuid.quantity : uuid.admin_qty,
        items: uuid.items,
        requester: uuid.requester_name,
        admin_approver: user.name,
      })
      .then((items: any) => {
        //  setCounter((prevCount) => prevCount + 1);
        // let value: any;
        // value = _.last(dataSource);

        // if (value.uuid === items.uuid) {
        //   console.log("Good");
        // }

        loadDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const confirmDeclined = async (uuid: any) => {
    // console.log(uuid);
    try {
      await axios
        .put(
          `https://frisbackendurl.azurewebsites.net/getstocksdeclined/${uuid.uuid}`,
          {
            admin_status: "REJECTED",
          }
        )
        .then(() => {
          setCounter((prevCount) => prevCount + 1);
          loadDataFromDB();
          message.success({
            content: "Operation is Successfully !",
            duration: 7,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const changeQty = async (uuid: any, val: any) => {
    console.log(uuid);
    await axios
      .put(
        `https://firstregistrarsnigeria.com/frisportals/requisitionsqty/${uuid}`,
        {
          quantity: val,
        }
      )
      .then(() => {
        setValue(val);
        //setCounter((prevCount) => prevCount + 1);
        loadDataFromDB();
        // message.success({
        //   content: "Operation is Successfully !",
        //   duration: 7,
        // });
      });
  };

  const confirmall = async (uuid: any) => {
    try {
      await axios
        .put(
          `https://frisbackendurl.azurewebsites.net/requisitionsall/${uuid}`,
          {
            status: "APPROVED",
          }
        )
        .then(() => {
          setCounter((prevCount) => prevCount + 1);
          loadDataFromDB();
          message.success({
            content: "Operation is Successfully !",
            duration: 3,
          });
        });
    } catch (error) {}
  };

  const saveLogs = async () => {
    message.loading("Please wait while the action is in progress...", 6.5);

    try {
      await axios
        .put(
          `https://frisbackendurl.azurewebsites.net/releasedlog/${control_id}`,
          {
            status: "RELEASED",
            admin_approver: user.name,
          }
        )
        .then((item) => {
          //  setCounter((prevCount) => prevCount + 1);
          //  loadDataFromDB();
          // console.log(item);
          message.success({
            content: "Request had been updated Successfully !",
            duration: 5,
          });
          navigate("/admin/dashboard");
        });
    } catch (error) {
      // console.log(error);
      message.error({
        content: "Error occurred!",
        duration: 5,
      });
    }
  };

  useEffect(() => {
    loadDataFromDB();
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    let value: any = {};

    value = _.last(data);

    if (
      value !== undefined &&
      _.every(
        dataSource,
        (element: any) => element.button_ui_administrator === true
      )
    ) {
      saveLogs();
    }

    //if (value.button_ui_admin === true) {
    //  showPromiseConfirm();
    /// }
  }, [dataSource]);

  // const arrayFunction = (element) =>{

  // }

  // useEffect(() => {
  //   let a = _.every(dataSource, (element: any) => {
  //     console.log(element.button_ui);
  //   });

  //   //console.log(a);
  // });

  useEffect(() => {
    buttonFunction();
  }, []);

  const buttonFunction = () => {
    if (Number(values) && counter == Number(values)) {
      setButtonDisabled(false);
    } else if (
      _.every(dataSource, (element: any) => element.button_ui === true)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "admin_qty" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    message.loading("Action in progress... Please wait a moment. ", 2.0);
    try {
      const row = (await form.validateFields()) as Item;

      //console.log(hodqty);

      const newData: any = [...dataSource];
      const index = newData.findIndex((item: any) => key === item.uuid);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        // console.log(newData[index].remarks);
        // setHodQty(newData[index].hod_qty);
        await axios
          .put(
            `https://frisbackendurl.azurewebsites.net/requisitionsadminqty/${key}`,
            {
              // manager_qty: newData[index].hod_qty,
              admin_qty: newData[index].admin_qty,
              remarks: newData[index].remarks,
            }
          )
          .then((item) => {
            //  console.log("Hello");
            //  setCounter((prevCount) => prevCount + 1);
            //  loadDataFromDB();
            // console.log(item);
            message
              .loading("Action in progress...", 1)
              .then(() =>
                message.success("Successfully update the quantity", 3.5)
              );
          });

        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setAdminQty(newData.admin_qty);
        setRemarks(newData.remarks);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  return (
    <Paper
      shadow="md"
      radius="lg"
      p="lg"
      withBorder
      style={{ padding: "0px", margin: "30px" }}
    >
      <div className="removeMargin">
        <div className="firstHalf">
          <div
            style={{
              padding: 40,
              height: "auto",
            }}
          >
            <br />
            <Title order={3} color="dimmed">
              {" "}
              Batch Action(s)
            </Title>
            <p>
              <Text>
                Please use the top check-box in the table to execute the batch
                actions on the request below.{" "}
              </Text>
            </p>
            <Text>
              Kindly click the <strong>save</strong> button to exit once you
              have finished handling the request below.
            </Text>
            <Group position="apart" sx={{ padding: 15 }}>
              {hasSelected ? (
                <div className="mantine-1nqidi4">
                  <Tooltip
                    label="Accept All"
                    withArrow
                    arrowSize={5}
                    transition="skew-up"
                    transitionDuration={300}
                  >
                    <Buttons
                      color="lime"
                      rightIcon={<IconThumbUp />}
                      onClick={() => confirmall(selectedRowKeys)}
                    >
                      Accept ALL
                    </Buttons>
                  </Tooltip>

                  <span style={{ marginRight: 190, marginLeft: 110 }}>
                    <Text color="dimmed" style={{}}>
                      {" "}
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} item(s)`
                        : ""}
                    </Text>
                  </span>
                  <Tooltip
                    label="Reject All"
                    withArrow
                    arrowSize={5}
                    transition="skew-up"
                    transitionDuration={300}
                  >
                    <Buttons>Reject ALL</Buttons>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </Group>
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowSelection={rowSelection}
                className=""
                dataSource={dataSource}
                columns={mergedColumns}
                pagination={{
                  pageSize: 10,
                }}
                scroll={{
                  x: 1550,
                  //y: 300,
                }}
                bordered
                // onChange={onChange}
              />
            </Form>
            {/* <Button
              type="primary"
              danger
              disabled={buttonDisabled}
              onClick={() => saveLogs()}
            >
              {" "}
              Save
            </Button> */}
            <Divider style={{ marginTop: 20 }} />
          </div>
        </div>

        <div className="secondHalf">
          <div className="custom-shape-divider-bottom-1671865759">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fills"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default EditableAdmin;
