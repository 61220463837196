import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RedirectRoute from "../utils/RedirectRoute";

import Requisition from "../requisition/Layouts";
import LoadingtoRedirect from "./LoadingToRedirect";

const RequisitionProtectedRoute = ({ children: [], ...rest }) => {
  const { user } = useSelector((state: any) => ({ ...state }));

  // return user && user.token ? <Requisition /> : <RedirectRoute />;
  // if (!user && !user.token) {
  //   console.log(!user);
  //   return <Navigate to="/" />;
  // }

  //Object.keys(user).length === 0 &&

  return user?.role === "user" ? <Outlet /> : <LoadingtoRedirect />;
};

export default RequisitionProtectedRoute;
