import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Grid,
  SimpleGrid,
  Skeleton,
  Card,
  Anchor,
  Breadcrumbs,
  Indicator,
  Box,
  Progress,
  ThemeIcon,
  Loader,
  Center,
  Badge,
  NumberInputHandlers,
  MultiSelect,
  NumberInput,
  Button as Buttons,
  Switch as Switches,
  Drawer,
} from "@mantine/core";
import { Button, List, Popconfirm, Select, Table, Input } from "antd";
import { useScrollIntoView, useWindowScroll } from "@mantine/hooks";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { nanoid } from "nanoid";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";
import moment from "moment";
import {
  IconCheck,
  IconSettings,
  IconSquareCheck,
  IconSquareX,
  IconX,
} from "@tabler/icons";
import { Switch } from "antd";

const { Dragger } = Upload;
const { Search } = Input;

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  description: {
    maxWidth: 650,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,

    // "&:hover": {
    //   transform: "scale(0.11)",
    //   boxShadow: theme.shadows.md,
    // },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  item: {
    "& + &": {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  title: {
    lineHeight: 1,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface DataType {
  key: string;
  items: string;
  status: string;
  createdAt: any;
}

let getLocalStorageItems: any = localStorage.getItem("Items");

let data: any = [];
let datas: any = [];

const ReleasedLogs = () => {
  const [scroll, scrollTo] = useWindowScroll();
  const [display, setDisplay] = useState(false);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [checked, setChecked] = useState(false);

  const [a, seta] = useState([]);
  const [opened, setOpened] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [shows, setShows] = useState(false);
  const [c, setC] = useState([]);
  const [searchValues, setSearchValues] = useState("");
  const [values, setValues] = useState(0);
  //const [items, setItems] = useState(null);
  const handlers: any = useRef<NumberInputHandlers>(null);
  const previousValue: any = useRef(null);

  let { user } = useSelector((state: any) => ({ ...state }));

  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "id",
      key: "id",
      width: "2%",
      //responsive: ["lg"],
      render: (value: any, item: any, index: any) =>
        index === 0 ? index + 1 : index + 1,
    },
    {
      title: "Control-ID",
      dataIndex: "control_number",
      key: "control_number",
      width: "35%",
      // responsive: ["md"],
      render: (control) => (
        <span className="fw-bolder">
          <Link to={"/admin/releasedlogs/actions/" + control}>
            {/* <Buttons
            variant="subtle"
            onClick={() => {
              admin/releasedlogs/actions
              setOpened(true);
              loadDataFromDB(control);
            }}
          > */}
            <strong> {control} </strong>
            {/* </Buttons> */}
          </Link>
        </span>
      ),
    },
    // {
    //   title: "Number of Items raised",
    //   dataIndex: "countRequests",
    //   key: "countRequests",
    //   // render: (text) => <a>{text}</a>,
    // },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      //onFilter: (value: string, record) => record.status.startsWith(value),
      filters: [
        {
          text: "RELEASED",
          value: "RELEASED",
        },
        {
          text: "PICKED-UP",
          value: "PICKED-UP",
        },
      ],
      onFilter: (value: any, record): boolean =>
        record.status.indexOf(value) === 0,
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["descend"],

      render: (value) => (
        <Badge
          variant="gradient"
          // gradient={{ from: "teal", to: "Indigo", deg: 105 }}
          gradient={{
            from: value === "RELEASED" ? "teal" : "lime",
            to: value === "RELEASED" ? "indigo" : "blue",
            deg: 105,
          }}
        >
          {value}
        </Badge>
      ),
    },

    {
      title: "Action(s)",
      dataIndex: "pickedStatus",
      key: "pickedStatus",
      width: "10%",
      render: (value: any, record: any) => {
        // console.log(value);
        return (
          <Switches
            onLabel="PICKED"
            size="xl"
            offLabel="OFF"
            checked={value}
            disabled={value}
            onChange={(event) => {
              //console.log(event);

              //setChecked(event.currentTarget.checked);

              picked(record.control_number, record.pickedStatus);
            }}
            thumbIcon={
              value ? (
                <IconCheck
                  size="0.8rem"
                  color={theme.colors.teal[theme.fn.primaryShade()]}
                  stroke={3}
                />
              ) : (
                <IconX
                  size="0.8rem"
                  color={theme.colors.red[theme.fn.primaryShade()]}
                  stroke={3}
                />
              )
            }
          />
        );
      },
    },

    {
      title: "Approver(HOD)",
      dataIndex: "approval_name",
      key: "approval_name",
      width: "5%",
    },

    {
      title: "Approver(ADMIN)",
      dataIndex: "approver",
      key: "approver",
      width: "5%",
    },

    {
      title: "Requester",
      dataIndex: "requester_name",
      key: "requester_name",
      width: "5%",
      filteredValue: [searchValues],
      onFilter(value: any, record: any) {
        return (
          String(record.requester_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.approval_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.control_number)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },

    {
      title: "Approved Rate (%)",
      dataIndex: "progress_approve",
      key: "progress_approve",
      width: "5%",
      render: (progress) => {
        return (
          <Progress
            color="green"
            value={progress < 30 ? 50 : progress}
            label={progress + "%"}
            size="xl"
            radius="xl"
            striped
            animate
          />
        );
      },
    },

    {
      title: "Rejected Rate (%)",
      dataIndex: "progress_decline",
      key: "progress_decline",
      width: "4%",
      render: (progress) => {
        return (
          <Progress
            color="red"
            value={progress < 30 ? 50 : progress}
            label={progress + "%"}
            size="xl"
            radius="xl"
            striped
            animate
          />
        );
      },
    },

    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      sortDirections: ["descend", "ascend"],
      responsive: ["sm"],
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => {
        return <Box> {moment(date).format("MMMM Do YYYY, h:mm:ss a")} </Box>;
      },
    },

    // {
    //   title: "Action(s)",
    //   dataIndex: "uuid",
    //   key: "uuid",
    //   //sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    //   render: (data, a) => {
    //     return (
    //       <Group position="center" spacing="sm">
    //         <Popconfirm
    //           title="Confirmation"
    //           //  description="Are you sure to delete this task?"
    //           //  onConfirm={confirm}
    //           onConfirm={() => confirm(data)}
    //           okText="Yes"
    //           cancelText="No"
    //         >
    //           <ActionIcon
    //             variant="light"
    //             component="button"
    //             // onClick={() => {
    //             //   console.log(data);
    //             // }}
    //           >
    //             <IconSquareX size={24} color={"red"} />
    //           </ActionIcon>
    //         </Popconfirm>
    //       </Group>
    //     );
    //   },
    // },
  ];

  // let control_id = window.location.href;

  const picked = async (value: any, picked: any) => {
    //console.log(value, picked);

    await axios
      .put(`https://frisbackendurl.azurewebsites.net/pickeditems/${value}`, {
        approver: user.name,
      })
      .then(() => {
        LoadsDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", filters);
  };

  const loadDataFromDB = (control_id: any) => {
    datas = [];

    fetch(`https://frisbackendurl.azurewebsites.net/requests/${control_id}`)
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value: any, index: any) => {
          // console.log(value);
          datas.push({
            key: value.uuid,
            items: value.items,
            quantity: value.quantity,
            control_number: value.control_number,
            status: value.status,
            button_ui: value.button_ui,
            counts_of_items: value.count_of_items,
            id: value.id,
          });
          setValues(Number(value.count_of_items));
        });
        //setDataSource(datas);
      })
      .finally(() => {
        //   setLoading(false);
      });
    //  };
  };

  const LoadsDataFromDB = () => {
    // console.log(user.name);onChange

    data = [];
    fetch(`https://frisbackendurl.azurewebsites.net/releasedlogs`)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.data);
        // setOptions(res.data);

        res.data.forEach((value: any, index: any) => {
          //console.log(value);
          let progress_approve: any = [];
          let progress_declined: any = [];
          let pickedStatus: any = [];
          let firstValuePicked = null;
          let progress_approve_result = null;
          let progress_declined_result = null;

          value.requistionLogs.forEach((progress: any) => {
            progress_approve.push(progress.progress_approved);

            progress_declined.push(progress.progress_declined);

            progress_approve_result = _.sum(progress_approve);

            progress_declined_result = _.sum(progress_declined);
          });

          value.requistionLogs.forEach((status: any) => {
            pickedStatus.push(status.pickedStatus);
            firstValuePicked = _.first(pickedStatus);
          });

          data.push({
            key: nanoid(),
            control_number: value.control_number,
            countRequests: value.countRequests,
            pickedStatus: firstValuePicked,
            status: value.status,
            approver: value.admin_approver,
            progress_approve: progress_approve_result,
            progress_decline: progress_declined_result,
            requester_name: value.requester_name,
            approval_name: value.approval_name,
            createdAt: value.createdAt,
          });
        });
        setDataSource(data);
      })
      .finally(() => {
        //   setLoading(false);
      });
  };

  useEffect(() => {
    LoadsDataFromDB();
  }, []);

  const confirm = async (uuid: any) => {
    await axios
      .put(
        `https://firstregistrarsnigeria.com/frisportals/requisitions/${uuid}`,
        {
          status: "APPROVED",
        }
      )
      .then(() => {
        LoadsDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const items = datas.map((item: any, index: any) => (
    <Group position="apart" className={classes.item} noWrap spacing="md">
      <div>
        <Text>{index === 0 ? index + 1 : index + 1}. </Text>
      </div>
      <div>
        <Text>{item.items}</Text>
      </div>
      <Text size="xs" color="dimmed" className={classes.switch}>
        <Badge
          variant="gradient"
          gradient={{ from: "teal", to: "lime", deg: 105 }}
        >
          {" "}
          {item.status}
        </Badge>
      </Text>
    </Group>
  ));

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".csv",
    action: "https://firstregistrarsnigeria.com/frisportals/uploadfiles",
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        const parent: any = document.querySelector(".ant-upload-list");

        setDisplay(true);

        const newEl = document.createElement("p");

        newEl.style.cssText = "text-align:center";
        newEl.textContent = "Attachment List";

        parent.insertBefore(newEl, parent.children[0]);
        window.scroll(0, 500);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      setDisplay(false);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <SimpleGrid
        cols={1}
        spacing="md"
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        <Drawer
          opened={opened}
          overlayColor={
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2]
          }
          overlayOpacity={0.55}
          overlayBlur={3}
          position="right"
          onClose={() => setOpened(false)}
          title="Request Status"
          padding="xl"
          size="xl"
        >
          {/* <br />
          <br />
          <br /> */}
          <Card
            withBorder
            shadow="md"
            radius="md"
            p="xl"
            className={classes.card}
            style={{ marginTop: 50 }}
          >
            {items}
          </Card>
          <div className="services section"></div>
        </Drawer>
        <Card
          withBorder
          radius="md"
          p="xl"
          style={{ padding: "9px !important" }}
        >
          {/* <Image
            src={"https://i.ibb.co/M6SSP1d/Requisition.png"}
            fit="fill"
            radius="sm"
          /> */}
          <div
            className="mantine-Badge-root mantine-rhsza5"
            style={{ marginTop: 9 }}
          >
            <span className="mantine-h9iq4m mantine-Badge-inner">
              RELEASED Logs
            </span>
          </div>
          {/* <Text
            variant="gradient"
            gradient={{ from: "gold", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            weight={650}
            style={{ marginTop: 20 }}
            size={25}
            className={classes.description}
            align="center"
          >
            Central Document Request-Types Logs
          </Text> */}
          <Text size="sm" mt="sm" color="dimmed" style={{ padding: 20 }}>
            The logs that your line manager has taken action on are those that
            are listed below.
          </Text>
          <div style={{ padding: 30 }}>
            <div style={{ marginBottom: 10 }}>
              <Search
                placeholder="Input your search text..."
                onSearch={(value) => {
                  setSearchValues(value);
                }}
                onChange={(e) => {
                  setSearchValues(e.target.value);
                }}
                enterButton
              />
            </div>
            <Table
              bordered
              // style={{ padding: "20px", marginRight: "20px" }}
              //rowSelection={{}}

              dataSource={dataSource}
              columns={columns}
              onChange={onChange}
              // onChange={onChange}
              scroll={{
                x: 490,
                // y: 100,
              }}
            />
          </div>
        </Card>

        {/* <Grid gutter="md">
            <Grid.Col>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid> */}
      </SimpleGrid>
    </>
  );
};

export default ReleasedLogs;
