import { useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Breadcrumbs,
  Anchor,
  Title,
  Divider,
} from "@mantine/core";
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconUpload,
  IconBell,
  IconScanEye,
  IconHome,
  IconBooks,
} from "@tabler/icons";
import { UserButton } from "../../utils/UserButton";
import { LinksGroup, NavbarLinksGroup } from "../../utils/LinksGroup";
// import TabsDashboard from "./TabsDashboard";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import MigratationFile from "../MigratationFile";
import SelectUI from "./SelectUI";

// import CertificateUpload from "./CertificateUpload";

// import Bar from "../progressBar/Bar";
// import Containers from "../progressBar/Container";
// import { useNProgress } from "@tanem/react-nprogress";

// import LoadingBar from "react-top-loading-bar";
// import { useSelector } from "react-redux";

const mockdata = [
  { label: "Dashboard", icon: IconGauge, linked: "/" },
  {
    label: "Doc-Capture",
    icon: IconUpload,
    initiallyOpened: false,
    links: [
      { label: "Correspondence Dept.", link: "/" },
      { label: "Certificate Dept.", link: "/certificate" },
      { label: "Mutual Funds Dept.", link: "/" },
      { label: "Dividend Mgt. Dept.", link: "/dividends" },
      { label: "Branch Offices", link: "/branch" },
      { label: "Compliance Dept.", link: "/" },
      { label: "Reconcilation. Dept.", link: "/" },
    ],
  },
  {
    label: "OCR Extraction",
    icon: IconScanEye,
    initiallyOpened: true,
    links: [
      { label: "Document Table", link: "/doctable" },
      { label: "Transfer Module.", link: "/transfer" },
    ],
  },
  {
    label: "Correpondence Module",
    icon: IconBooks,
    linked: "/correspondence",
  },
  { label: "Analytics", icon: IconPresentationAnalytics },
  { label: "Contracts", icon: IconFileAnalytics },
  { label: "Settings", icon: IconAdjustments },
  {
    label: "Security",
    icon: IconLock,
    links: [
      { label: "Enable 2FA", link: "/" },
      { label: "Change password", link: "/" },
      { label: "Recovery codes", link: "/" },
    ],
  },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: "260px",
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

// const Progress: React.FC<{ isAnimating: boolean }> = ({ isAnimating }) => {
//   const { animationDuration, isFinished, progress } = useNProgress({
//     isAnimating,
//   });

//   return (
//     <Containers animationDuration={animationDuration} isFinished={isFinished}>
//       <Bar animationDuration={animationDuration} progress={progress} />
//       {/*
//       This example doesn't use a spinner component so the UI stays
//       tidy. You're free to render whatever is appropriate for your
//       use-case.
//       */}
//     </Containers>
//   );
// };

const items = [
  { title: <IconHome />, href: "/", end: "false" },
  { title: "Correspondence", href: "", end: "true" },
].map((item, index) => (
  <Anchor component={Link} to={item.href} key={index}>
    {item.title}
  </Anchor>
));

export default function Layout() {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const location = useLocation();

  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const links = mockdata.map((item) => (
    <>
      <LinksGroup {...item} label={item.label} to={item.links} />
    </>
  ));

  //   const data = useSelector((state: any) => {
  //     return { ...state };
  //   });

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.blue[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          height="auto"
          width={{ sm: 260 }}
          p="md"
          hidden={!opened}
          className={classes.navbar}
        >
          <Navbar.Section grow className={classes.links} component={ScrollArea}>
            <div className={classes.linksInner}>{links}</div>
          </Navbar.Section>
        </Navbar>
      }
      footer={
        <Footer height={50} p="md">
          <Group className={classes.links} position="center">
            <Text align="center">© 2022</Text>
            <Text align="center">FRISOPS</Text>
          </Group>

          {/* <Group spacing="xs" position="right" noWrap>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandTwitter size={12} stroke={1.5} />
            </ActionIcon>
          </Group> */}
        </Footer>
      }
      header={
        <>
          {/* <LoadingBar
            height={5}
            color="#2C5178"
            waitingTime={2000}
            progress={data.updates.values}
            onLoaderFinished={() => setProgress(0)}
          /> */}
          <Header height={70} p="md">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <Image
                src="https://i.ibb.co/ZgNdCXJ/logo.png"
                width={130}
                style={{ padding: 40 }}
              />

              <Group>
                {" "}
                <Divider
                  orientation="vertical"
                  style={{ marginLeft: 20 }}
                  color="blue"
                />
                <Title
                  order={2}
                  style={{ marginLeft: "0px" }}
                  variant="gradient"
                  gradient={{ from: "blue", to: "gold", deg: 45 }}
                >
                  {" "}
                  Requistion Portal{" "}
                </Title>{" "}
                <Divider orientation="vertical" color="blue" />
              </Group>
            </div>
            <div style={{ marginTop: -35 }}>
              <Group position="right">
                <ActionIcon variant="transparent" color="blue">
                  <IconBell size={22} />
                </ActionIcon>
                <Avatar
                  radius="xl"
                  size="md"
                  variant="outline"
                  color="grape"
                  src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                />
              </Group>
            </div>
          </Header>
        </>
      }
    >
      <Container my="xs">
        {/* <TabsDashboard /> */}

        <SelectUI />
      </Container>
    </AppShell>
  );
}
