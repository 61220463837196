import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { auth, micrsoftAuthProvider } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./Pages/Dashboard";
import "./App.css";
import DataLogs from "./Pages/TableLogs/Layout";
import SelecUI from "./Pages/clients/Layouts";
import Logins from "./Pages/login/Logins";
import ConfirmJWT from "./Pages/jwt/ConfirmJWT";
import ConfirmRejectJWT from "./Pages/jwt/ComfirmReject";
import axios from "axios";

import MainDashboard from "./Pages/dashboard/MainDashboard";
import UsersDashboard from "./Pages/User/DashboardModule/MainDashboard";
import RequisitionModule from "./Pages/User/RequisitionModule/Layout";
import AuditModule from "./Pages/User/AuditModule/Layout";
import PendingModule from "./Pages/User/NotificationModule/RequestLogs/Layout";
import TreatedModule from "./Pages/User/NotificationModule/TreatedLogs/Layout";
import ReleasedModule from "./Pages/User/NotificationModule/ReleasedLogs/Layout";
import ReleasedDetailsModule from "./Pages/User/NotificationModule/ReleasedLogs/ReleasedDetails/Layout";
import UserSettings from "./Pages/User/Settings/MainDashboard";

import RequestLogs from "./Pages/notification/request/Layouts";
import { AddItemProvider } from "./AddItemsContext";
import PrivateRoute from "./Pages/routes/PrivateRoute";
import socketIOClient from "socket.io-client";

//Protected Routes
import RequisitionProtectedRoute from "./Pages/routes/RequisitionProtectedRoute";
import AdminProtectedRoute from "./Pages/routes/AdminProtectedRoute";
import ManagerProtectedRoute from "./Pages/routes/ManagerProtectedRoute";

import Approval from "./Pages/approval/Layout";
import AdminAprover from "./Pages/admin-approval/Layout";
import ManagerActionModule from "./Pages/Manager/ApprovedModule/Layout";
import ManagerPendingModule from "./Pages/Manager/PendingModule/Layout";
import ManagerTreatedModule from "./Pages/Manager/TreatedModules/Layout";
import ManagerSettings from "./Pages/Manager/Settings/MainDashboard";
import ManagerTransfer from "./Pages/Manager/ManageUsersModule/Layout";

import SuperAdminLogs from "./Pages/superadmin/ListsControlNumber/Layout";
import SuperAdminApprover from "./Pages/superadmin/ApprovalSuperAdmin/Layout";

import ManagerDashboardModule from "./Pages/Manager/DashboardManager/MainDashboard";

import GlobalDashboardModule from "./Pages/GlobalAdmin/DashboardManager/MainDashboard";
import GlobalManagerUsersModule from "./Pages/GlobalAdmin/ManageUsersRolesModule/Layout";
import GlobalPendingLogs from "./Pages/GlobalAdmin/PendingModule/Layout";
import GlobalActionModule from "./Pages/GlobalAdmin/ApprovedModule/Layout";
import GlobalTreatedModule from "./Pages/GlobalAdmin/TreatedModules/Layout";
import GlobalTransferModule from "./Pages/GlobalAdmin/ManageUsersModule/Layout";
import GlobalSettingsModule from "./Pages/GlobalAdmin/Settings/MainDashboard";

import AdminDasboard from "./Pages/Admin/DashboardModule/MainDashboard";
import Additems from "./Pages/Admin/AddItemsModule/Layout";
import AdminPendingModule from "./Pages/Admin/Notification/PendingLogs/Layout";
import Consumables from "./Pages/Admin/ConsumablesModule/Layout";
import Migration from "./Pages/Admin/DataMigrationModule/MainDashboard";
import AdminTreatedLog from "./Pages/Admin/Notification/TreatedLogs/Layout";
import AdminReleasedLog from "./Pages/Admin/Notification/ReleasedLogs/Layout";
import AdminActiionsLogs from "./Pages/Admin/Notification/ApprovedLogs/Layout";
import AdminRequisition from "./Pages/Admin/RequisitionModule/Layout";
import ReleasedActionsModule from "./Pages/Admin/Notification/ReleasedLogs/ReleasedDetails/Layout";
import AddActionsModule from "./Pages/Admin/ConsumablesModule/ConsumableDetails/Layout";
import AdminSettings from "./Pages/Admin/Settings/MainDashboard";
import AdminReports from "./Pages/Admin/Reports/Layout";
import GlobalProtectedRoute from "./Pages/routes/GlobalProtectedRoute";

export const socket = socketIOClient(
  "https://frisbackendlogic.azurewebsites.net/"
);

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => ({ ...state }));
  // const {user.email} = useSelector((state) => ({...state}))

  //const email = user.email || ""

  useEffect(() => {
    // socket.on("initial_data", (payload) => {
    // console.log("Hello");
    // });

    //Refresh the Page...
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      //  console.log(user);

      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        //https://firstregistrarsnigeria.com/login/v1/currentuser
        await axios
          .get(
            //"https://frislogin.azurewebsites.net/v1/currentuser",

            // "http://localhost:4000/v1/currentuser",

            "https://fris-login.azurewebsites.net/v1/currentuser",

            {
              headers: { authtoken: idTokenResult.token },
            }
          )
          .then((res) => {
            //  localStorage.setItem("User", JSON.stringify(res.data));

            //  socket.emit("user_email", res.data.email);

            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                email: res.data.email,
                name: res.data.name,
                pics: res.data.photo_url,
                token: idTokenResult.token,
                role: res.data.role,
                signature_url: res.data.signature_url,
                isadmin: res.data.isadmin,
                delegate_role: res.data.delegate_role,
              },
            });
          });
      }
    });

    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   socket.emit("initial_data");
  //   socket.on("get_data", getData);
  //   // socket.on("change_data", changeData);
  //   return () => {
  //     socket.off("get_data");
  //     socket.off("change_data");
  //   };
  // });

  const getData = (feeds: any) => {
    console.log(feeds);
  };

  return (
    <>
      <AddItemProvider>
        <Routes>
          <Route path="/migrate" element={<Dashboard />} />
          <Route path="/datalogs" element={<DataLogs />} />
          <Route path="/superadmin" element={<SuperAdminLogs />} />
          <Route
            path="/admin/approval/:control_id"
            element={<AdminAprover />}
          />
          <Route
            path="/supueradmin/approval/:control_id"
            element={<SuperAdminApprover />}
          />

          {/* User Route */}

          <Route element={<RequisitionProtectedRoute children={[]} />}>
            <Route path="/users/dashboard" element={<UsersDashboard />} />
            <Route path="/users/requisition" element={<RequisitionModule />} />
            <Route
              path="/users/notification/releasedlogs"
              element={<ReleasedModule />}
            />
            <Route
              path="/users/notification/pendinglogs"
              element={<PendingModule />}
            />
            {/* /user/approvaldetails/ */}
            <Route
              path="/users/notification/treatedlogs"
              element={<TreatedModule />}
            />
            <Route path="/users/audit" element={<AuditModule />} />
            <Route path="/users/settings" element={<UserSettings />} />
            <Route
              path="/user/actions/:control_id"
              element={<ReleasedDetailsModule />}
            />
          </Route>

          <Route path="/requisition" element={<RequisitionModule />} />
          <Route path="/notification/requestlog" element={<RequestLogs />} />
          {/* <Route path="/approval" element={<Approval />} /> */}
          {/* <Route
            path="/dashboard"
            element={
              <RequisitionProtectedRoute>
                {" "}
                <MainDashboard />{" "}
              </RequisitionProtectedRoute>
            }
          /> */}
          <Route path="/" element={<Logins />} />

          <Route path="*" element={<Logins />} />
          <Route
            path="/action/approve/:control/:token"
            element={<ConfirmJWT />}
          />
          <Route
            path="/action/declined/:control/:token"
            element={<ConfirmRejectJWT />}
          />
          <Route path="/client" element={<SelecUI />} />
          {/* New Route Links - USERS   /users/notification/pendinglogs */}

          {/* <Route path="/users/requisition" element={<RequisitionModule />} />
          <Route
            path="/users/notification/releasedlogs"
            element={<ReleasedModule />}
          />
          <Route
            path="/users/notification/pendinglogs"
            element={<PendingModule />}
          />
          {/* /user/approvaldetails/ */}
          {/* <Route
            path="/users/notification/treatedlogs"
            element={<TreatedModule />}
          />
          <Route path="/users/audit" element={<AuditModule />} />
          <Route path="/users/settings" element={<UserSettings />} />
          <Route
            path="/user/actions/:control_id"
            element={<ReleasedDetailsModule />}
          />  */}

          {/* Manager's Routes */}

          <Route element={<ManagerProtectedRoute children={[]} />}>
            <Route
              path="/manager/dashboard"
              element={<ManagerDashboardModule />}
            />
            <Route
              path="/manager/pendinglogs"
              element={<ManagerPendingModule />}
            />
            <Route
              path="/manager/actions/:control_id"
              element={<ManagerActionModule />}
            />
            <Route
              path="/manager/treatedlog"
              element={<ManagerTreatedModule />}
            />
            <Route path="/manager/transfer" element={<ManagerTransfer />} />

            <Route path="/manager/settings" element={<ManagerSettings />} />
          </Route>

          {/* GLOBAL ADMIN */}

          <Route element={<GlobalProtectedRoute children={[]} />}>
            <Route
              path="/global/admin/dashboard"
              element={<GlobalDashboardModule />}
            />

            <Route
              path="/global/admin/pendinglogs"
              element={<GlobalPendingLogs />}
            />

            <Route
              path="/global/admin/pendinglogs"
              element={<GlobalPendingLogs />}
            />

            <Route
              path="/global/admin/actions/:control_id"
              element={<GlobalActionModule />}
            />

            <Route
              path="/global/admin/treatedlog"
              element={<GlobalTreatedModule />}
            />

            <Route
              path="/global/admin/transfer"
              element={<GlobalTransferModule />}
            />

            <Route
              path="/global/admin/manager-users"
              element={<GlobalManagerUsersModule />}
            />

            <Route
              path="/global/admin/settings"
              element={<GlobalSettingsModule />}
            />
          </Route>

          {/* Admin Route */}

          <Route element={<AdminProtectedRoute children={[]} />}>
            {/* <Route path="/approval" element={<Approval />} />
            <Route path="/dashboard" element={<MainDashboard />} /> */}

            <Route path="/admin/dashboard" element={<AdminDasboard />} />
            <Route
              path="/admin/notification/pendinglogs"
              element={<AdminPendingModule />}
            />
            <Route path="/admin/add-items" element={<Additems />} />
            <Route path="/admin/consumables" element={<Consumables />} />
            <Route path="/admin/migration" element={<Migration />} />
            <Route
              path="/admin/notification/treatedlogs"
              element={<AdminTreatedLog />}
            />
            <Route
              path="/admin/notification/releasedlogs"
              element={<AdminReleasedLog />}
            />
            <Route
              path="/admin/releasedlogs/actions/:control_id"
              element={<ReleasedActionsModule />}
            />
            <Route
              path="/admin/notification/actions/:control_id"
              element={<AdminActiionsLogs />}
            />
            <Route
              path="/admin/consumables/add/:uuid"
              element={<AddActionsModule />}
            />
            <Route path="/admin/requisition" element={<AdminRequisition />} />
            <Route path="/admin/settings" element={<AdminSettings />} />

            <Route path="/admin/reports" element={<AdminReports />} />
          </Route>
        </Routes>
      </AddItemProvider>
    </>
  );
}

export default App;
