import React, { useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Grid,
  SimpleGrid,
  Skeleton,
  Card,
  Anchor,
  Breadcrumbs,
  Indicator,
  Box,
  Progress,
  List,
  ThemeIcon,
  Loader,
  Center,
  Badge,
  Table,
} from "@mantine/core";
import { useScrollIntoView, useWindowScroll } from "@mantine/hooks";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";

const { Dragger } = Upload;

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  description: {
    maxWidth: 650,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,

    "&:hover": {
      transform: "scale(1.11)",
      boxShadow: theme.shadows.md,
    },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: "260px",
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

const TableLogs = () => {
  return (
    <>
      {" "}
      <>
        <SimpleGrid
          cols={1}
          spacing="md"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <Card
            withBorder
            radius="md"
            p="xl"
            style={{ padding: "9px !important" }}
          >
            <Image
              src={"https://i.ibb.co/dQxFmrH/Migrations.png"}
              fit="fill"
              radius="sm"
            />
            <div className="mantine-Badge-root mantine-rhsza5">
              <span className="mantine-h9iq4m mantine-Badge-inner">
                Data Logs
              </span>
            </div>
            {/* <Text
            variant="gradient"
            gradient={{ from: "gold", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            weight={650}
            style={{ marginTop: 20 }}
            size={25}
            className={classes.description}
            align="center"
          >
            Central Document Request-Types Logs
          </Text> */}

            {/* <Dragger {...props} >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger> */}
            <Table />
          </Card>

          {/* <Grid gutter="md">
            <Grid.Col>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid> */}
        </SimpleGrid>
      </>
    </>
  );
};

export default TableLogs;
