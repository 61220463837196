import { Badge, Divider, Loader, Paper, Select } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { Descriptions, Space } from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";

let data: any = [];
const Step2 = (props: any) => {
  let getLocalStorageItems: any = localStorage.getItem("Items");
  let parsedLocalStorageItems = JSON.parse(getLocalStorageItems);

  const [dataDepartment, setDataDepatment] = useState([
    {
      value: "Mkting Sales & Market Research",
      label: "Mkting Sales & Market Research",
    },
    {
      value: "Product Mgt & Brand Comms",
      label: "Product Mgt & Brand Comms",
    },
    {
      value: "New Issue",
      label: "New Issue",
    },
    {
      value: "COO's offfice",
      label: "COO's offfice",
    },
    {
      value: "Information Tech",
      label: "Information Tech.",
    },
    {
      value: "Compliance",
      label: "Compliance",
    },
    {
      value: "Legal",
      label: "Legal",
    },
    {
      value: "Reconciliation /CSCS Update",
      label: "Reconciliation /CSCS Update",
    },
    {
      value: "Human Capital",
      label: "Human Capital",
    },
    {
      value: "Admin & Rental Services",
      label: "Admin & Rental Services",
    },

    {
      value: "Mailing",
      label: "Mailing",
    },
    {
      value: "Customer Service/Care",
      label: "Customer Service/Care",
    },
    {
      value: "Dividend & Certificate Mngt",
      label: "Dividend & Certificate Mngt",
    },
    {
      value: "Stock Broker Liaison",
      label: "Stock Broker Liaison",
    },
    {
      value: "Verification",
      label: "Verification",
    },
    {
      value: "Call Centre Management",
      label: "Call Centre Management",
    },
    {
      value: "Mutual Funds & Bonds",
      label: "Mutual Funds & Bonds",
    },
    {
      value: "Company Secretarial Service",
      label: "Company Secretarial Service",
    },
    {
      value: "Document Imaging",
      label: "Document Imaging",
    },
    {
      value: "Probate Services",
      label: "Probate Services",
    },
    {
      value: "Finance & Accounts",
      label: "Finance & Accounts",
    },
    {
      value: "Know Your Customer",
      label: "Know Your Customer",
    },
    {
      value: "Treasury",
      label: "Treasury",
    },
    {
      value: "Internal Audit",
      label: "Internal Audit",
    },
    {
      value: "MD's Office",
      label: "MD's Office",
    },
  ]);

  return (
    <Paper
      shadow="md"
      radius="lg"
      p="lg"
      withBorder
      style={{ padding: "0px", margin: "30px" }}
    >
      <div className="removeMargin">
        <div className="firstHalf">
          <div style={{ padding: 60, bottom: 0 }}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              <Select
                label="Approver:"
                placeholder="Pick one"
                description="Please select the approver for this request"
                transition="pop-top-left"
                transitionDuration={80}
                required
                searchable
                clearable
                onChange={(value: any) => {
                  // let approve: any = localStorage.getItem("Approver");
                  // setApprover(value);
                  localStorage.setItem("Approver", JSON.stringify(value));
                }}
                transitionTimingFunction="ease"
                dropdownPosition="bottom"
                variant="default"
                nothingFound="Nothing found"
                rightSection={
                  props.value.length === 0 ? (
                    <Loader variant="dots" size={20} />
                  ) : null
                }
                data={props.value}
                className="mb-5"
              />
              {/* <br /> */}
              <Divider my="xs" label="&" labelPosition="center" />

              <Select
                label="Department:"
                placeholder="Pick one"
                variant="filled"
                description="Please select your department"
                transition="pop-top-left"
                transitionDuration={80}
                required
                searchable
                clearable
                nothingFound="Nothing found"
                data={dataDepartment}
                onChange={(value: any) => {
                  // let approve: any = localStorage.getItem("Approver");
                  // setApprover(value);
                  console.log(value);
                  localStorage.setItem("Department", JSON.stringify(value));
                }}
              />
            </Space>

            <br />
            <br />
          </div>
        </div>

        <div className="secondHalf">
          <div className="custom-shape-divider-bottom-1671865759">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default Step2;
