import { nanoid } from "nanoid";

export function userReducer(state: any = {}, action: any) {
  switch (action.type) {
    case "LOGGED_IN_USER":
      return action.payload;
    case "LOGGED_ITEMS":
      const { type, payload } = action;
      return action.payload;
    // case "LOGGED_STATUS":
    //   return action.payload;
    case "LOGOUT":
      return action.payload;
    default:
      return state;
  }
}
