import { Group, MultiSelect, NumberInput, Paper } from "@mantine/core";
import { Button, List, Select, message, Upload, Steps } from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddItemContext from "../../../AddItemsContext";
import moment from "moment";

let p: any;
let data: any = [];

const Step1 = (props: any) => {
  const dispatch = useDispatch();
  const [a, seta] = useState([]);
  const [t, setT] = useState([]);
  const [values, setValues] = useState(0);

  const { addToItem, items } = useContext(AddItemContext);

  let { user } = useSelector((state: any) => ({ ...state }));

  let { show } = useSelector((state: any) => ({ ...state }));

  const navigate = useNavigate();

  console.log(user.email);

  useEffect(() => {
    //  setLoading(true);

    //const mainFunction = () => {
    data = [];

    fetch("https://frisbackendurl.azurewebsites.net/consumbles")
      .then((res) => res.json())
      .then((res) => {
        //console.log(res.data);
        res.data.forEach((value: any, index: any) => {
          data.push({
            key: nanoid(),
            value: value.items,
            label: value.items,
            // id: value.id,
            quantity: 0,
            date: moment(new Date()).format("LL"),
            status: false,
          });
        });

        //setC(res.data);
        // console.log(moment(new Date()).format("LL"));
        // setOptions(res.data);
      })
      .finally(() => {
        //   setLoading(false);
      });
    //  };

    // return () => mainFunction();
  }, []);

  // console.log(data);

  return (
    <Paper
      shadow="md"
      radius="lg"
      p="lg"
      withBorder
      style={{ padding: "0px", margin: "30px" }}
    >
      <div className="removeMargin">
        <div className="firstHalf">
          <div
            style={{
              padding: 40,
              bottom: 0,
              height: "auto",
            }}
          >
            <MultiSelect
              data={data}
              searchable
              height="auto"
              onChange={(item: any) => {
                // console.log(item);
                let b: any = [];
                dispatch({
                  type: "LOGGED_STATUS",
                  payload: {
                    show: true,
                  },
                });
                item.forEach((items: any) => {
                  console.log(items);
                  b.push({
                    value: items,
                    quantity: 0,
                    date: moment(new Date()).format("LL"),
                    key: items,
                    // id: items.id,
                    label: items,
                    status: false,
                  });
                });

                seta(b);

                //console.log(b);

                // setC(b);
              }}
              label="Requisition Items"
              placeholder="Pick all that you like"
            />
            <br></br> <br />
            {a.length > 0 ? (
              <List
                size="large"
                header={<div>Request Items</div>}
                footer={
                  <div>
                    {" "}
                    {/* <div className="addButton">
                  <Button
                    type="primary"
                    onClick={(e) => {
                      p.forEach(
                        async (item: any) =>
                          await axios
                            .post("http://localhost:5000/requisitions", {
                              items: item.value,
                              quantity: item.quantity,
                              requester_email: user.email,
                            })
                            .then((res) => {
                              message.success("Saved !");
                              navigate("/notification/requestlog");
                            })
                      );
                    }}
                  >
                    {" "}
                    Add{" "}
                  </Button>
                </div> */}
                  </div>
                }
                bordered
                dataSource={a}
                renderItem={(item: any, index: any) => (
                  <>
                    <>
                      <List.Item
                        extra={
                          <>
                            <Group spacing={5}></Group>
                          </>
                        }
                      >
                        {index === 0 ? index + 1 : index + 1}. {item.value}
                        <div className="addButton">
                          <NumberInput
                            label="Quantity"
                            value={values}
                            // defaultValue={0}
                            // error={values === 0 ? null : "Required"}
                            onChange={async (val: any) => {
                              let indexs = _.findIndex(data, {
                                value: item.value,
                              });

                              data.splice(indexs, 1, {
                                value: item.value,
                                // id: item.id,
                                quantity: val || 0,
                                label: item.value,
                                key: item.value,
                                status: true,
                                date: moment(new Date()).format("LL"),
                              });

                              //setC(data)

                              p = _.filter(data, function (o) {
                                return o.status;
                              });

                              await localStorage.removeItem("Items");

                              localStorage.setItem("Items", JSON.stringify(p));

                              //  setT(p);

                              // console.log(t);
                              //addToItem({  });

                              // console.log(p);

                              addToItem({
                                item: p.value,
                                quantity: p.quantity,
                              });

                              //   p.forEach((item: any) => {
                              //     // dispatch({
                              //     //   type: "LOGGED_ITEMS",
                              //     //   payload: {
                              //     //     item: item.value,
                              //     //     quantity: item.quantity,
                              //     //   },
                              //     // });

                              //     addToItem({
                              //       item: item.value,
                              //       quantity: item.quantity,
                              //     });
                              //   });

                              // let f: any = localStorage.getItem("Items");

                              //JSON.parse(f);

                              //console.log(JSON.parse(f));

                              //  _.merge(a, data);
                            }}
                            // handlersRef={handlers}
                            max={10}
                            min={0}
                            // step={2}
                            styles={{
                              input: { width: 59, textAlign: "center" },
                            }}
                          />

                          {/* <Button onClick={() => console.log(a)}>Logs</Button> */}
                        </div>
                      </List.Item>
                    </>
                  </>
                )}
              />
            ) : null}
          </div>
        </div>

        <div className="secondHalf">
          <div className="custom-shape-divider-bottom-1671865759">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default Step1;
