import { Badge, Paper } from "@mantine/core";
import { createStyles, Table, ScrollArea, Button } from "@mantine/core";
import { message, Button as Buttons } from "antd";
import axios from "axios";
import { customAlphabet } from "nanoid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { socket } from "../../../App";
import _ from "lodash";
import moment from "moment";
import { IconSend } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

let t: any;

const Step3 = () => {
  let { user } = useSelector((state: any) => ({ ...state }));

  const nanoid = customAlphabet("1234567890ABCDC", 5);

  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const [parsedLocalStorageItems, setParsedLocalStorageItems] = useState([]);
  const [parsedLocalStorageApprover, setParsedLocalStorageApprover] =
    useState("");
  const [parsedLocalStorageDept, setParsedLocalStorageDepart] = useState("");
  const [controlNumber, setControlNumber] = useState("");

  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    setControlNumber(`FRIS-${nanoid(5)}-${moment().format().split("T")[0]}`);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const getLocalStorage: any = localStorage.getItem("Items");
    setParsedLocalStorageItems(JSON.parse(getLocalStorage));

    let getManagerDetail: any = localStorage.getItem("Approver");
    setParsedLocalStorageApprover(JSON.parse(getManagerDetail));

    let getDeptDetail: any = localStorage.getItem("Department");
    setParsedLocalStorageDepart(JSON.parse(getDeptDetail));
  }, []);

  const rows = parsedLocalStorageItems.map((row: any, index: any) => (
    <tr key={row.key}>
      <td>{index === 0 ? index + 1 : index + 1}</td>
      <td>{row.value}</td>
      <td>
        {" "}
        <Badge
          variant="gradient"
          gradient={{ from: "teal", to: "lime", deg: 105 }}
        >
          {row.quantity}
        </Badge>
      </td>
      <td>{parsedLocalStorageApprover}</td>
      <td>{parsedLocalStorageDept}</td>
      <td>{row.date}</td>
    </tr>
  ));

  // console.log(user.email);
  return (
    <>
      <Paper
        shadow="md"
        radius="lg"
        p="lg"
        withBorder
        style={{ padding: "0px", margin: "30px" }}
      >
        <div className="removeMargin">
          <div className="firstHalf">
            <div style={{ padding: 60, bottom: 0 }}>
              <br />
              <ScrollArea
                sx={{ height: 200 }}
                onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
              >
                <Table
                  sx={{ minWidth: 600 }}
                  striped
                  highlightOnHover
                  withBorder
                  // withColumnBorders=
                  className="rounded-md"
                >
                  <thead
                    className={cx(classes.header, {
                      [classes.scrolled]: scrolled,
                    })}
                  >
                    <tr>
                      <th>S/N</th>
                      <th>Items</th>
                      <th>Quantity</th>
                      <th>Approver</th>
                      <th>Department</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              </ScrollArea>
              <br />
              <Button
                //  disabled={user.isadmin}
                loading={loadings}
                rightIcon={<IconSend size={20} />}
                className="w-10/12 rounded hover:rounded ml-20"
                onClick={async () => {
                  setLoadings(true);
                  let number_items = _.size(parsedLocalStorageItems);

                  await axios
                    .post(
                      //"https://firstregistrarsnigeria.com/frisportals/requisitions",
                      "https://frisbackendurl.azurewebsites.net/requisitions",
                      // "http://localhost:5000/requisitions",
                      {
                        items: parsedLocalStorageItems,
                        requester_email: user.email,
                        manager: parsedLocalStorageApprover,
                        control_number: controlNumber,
                        requester_name: user.name,
                        token: user.token,
                        department: parsedLocalStorageDept,
                        count_of_items: number_items,
                      }
                    )
                    .then((items: any) => {
                      let userEmail = user.email;
                      // console.log(items);

                      message.success(
                        `Request submitted with control_number# ${controlNumber}`
                      );
                      navigate("/users/notification/pendinglogs");
                      localStorage.removeItem("Items");
                      localStorage.removeItem("Approver");
                      localStorage.removeItem("Approver");
                    })
                    .catch((err) => {
                      setLoadings(false);

                      message.error(err);
                    });
                }}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="secondHalf">
            <div className="custom-shape-divider-bottom-1671865759">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                  opacity=".25"
                  className="shape-fill"
                ></path>
                <path
                  d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                  opacity=".5"
                  className="shape-fill"
                ></path>
                <path
                  d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                  className="shape-fill"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default Step3;
