import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RedirectRoute from "../utils/RedirectRoute";

import Requisition from "../requisition/Layouts";
import LoadingtoRedirect from "./LoadingToRedirect";

const AdminProtectedRoute = ({ children: [], ...rest }) => {
  const { user } = useSelector((state: any) => ({ ...state }));

  //Object.keys(user).length === 0 &&

  //console.log(user.role);

  return user.role == "admin" ? <Outlet /> : <LoadingtoRedirect />;
};

export default AdminProtectedRoute;
