import { useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Breadcrumbs,
  Anchor,
  Title,
  Divider,
  Menu,
  Badge,
  Indicator,
  Card,
} from "@mantine/core";
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconUpload,
  IconBell,
  IconScanEye,
  IconHome,
  IconBooks,
  IconSettings,
  IconClipboardText,
  IconGitPullRequest,
  IconPencilPlus,
  IconBellRinging,
  IconSearch,
  IconPhoto,
  IconArrowsLeftRight,
  IconTrash,
  IconMessageCircle,
  IconAlertOctagon,
  IconCheck,
} from "@tabler/icons";
import { UserButton } from "../../../utils/UserButton";
import { LinksGroup, NavbarLinksGroup } from "../../../utils/LinksGroup";
// import TabsDashboard from "./TabsDashboard";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
//import SelectUI from "./SelectUI";
import { socket } from "../../../App";
import _ from "lodash";
//import { HeroHeader } from "./HeroHeader";
import { HeroHeader } from "./HeroHeader";
//import { GridLayout } from "./GridLayout";
import ApprovalLogGrid from "./ApprovalLogGrid";

// import CertificateUpload from "./CertificateUpload";

// import Bar from "../progressBar/Bar";
// import Containers from "../progressBar/Container";
// import { useNProgress } from "@tanem/react-nprogress";

// import LoadingBar from "react-top-loading-bar";
// import { useSelector } from "react-redux";

const mockdata = [
  { label: "Dashboard", icon: IconGauge, linked: "/dashboard" },

  {
    label: "Requisitions",
    icon: IconGitPullRequest,
    linked: "/requisition",
    // initiallyOpened: true,
    // links: [
    //   { label: "Document Table", link: "/doctable" },
    //   { label: "Transfer Module.", link: "/transfer" },
    // ],
  },

  {
    label: "Approval / Declined Actions",
    icon: IconCheck,
    linked: "/approval",
    // initiallyOpened: true,
    // links: [
    //   { label: "Document Table", link: "/doctable" },
    //   { label: "Transfer Module.", link: "/transfer" },
    // ],
  },
  {
    label: "Add Item(s)",
    icon: IconPencilPlus,
    linked: "/requisition",
  },
  {
    label: "Notifications",
    icon: IconBellRinging,
    links: [
      { label: "Request Logs Status", link: "/notification/requestlog" },
      { label: "Approval Logs Status", link: "/" },
      { label: "Declined Logs Status", link: "/" },
      { label: "Issued Logs Status", link: "/" },
    ],
  },
  { label: "Settings", icon: IconSettings },
  { label: "Reports", icon: IconClipboardText },
  //   { label: "Settings", icon: IconAdjustments },
  //   {
  //     label: "Security",
  //     icon: IconLock,
  //     links: [
  //       { label: "Enable 2FA", link: "/" },
  //       { label: "Change password", link: "/" },
  //       { label: "Recovery codes", link: "/" },
  //     ],
  //   },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: "260px",
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

// const Progress: React.FC<{ isAnimating: boolean }> = ({ isAnimating }) => {
//   const { animationDuration, isFinished, progress } = useNProgress({
//     isAnimating,
//   });

//   return (
//     <Containers animationDuration={animationDuration} isFinished={isFinished}>
//       <Bar animationDuration={animationDuration} progress={progress} />
//       {/*
//       This example doesn't use a spinner component so the UI stays
//       tidy. You're free to render whatever is appropriate for your
//       use-case.
//       */}
//     </Containers>
//   );
// };

const items = [
  { title: <IconHome />, href: "/", end: "false" },
  { title: "Correspondence", href: "", end: "true" },
].map((item, index) => (
  <Anchor component={Link} to={item.href} key={index}>
    {item.title}
  </Anchor>
));

export default function Layout() {
  let { user } = useSelector((state: any) => ({ ...state }));
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const location = useLocation();

  const [feeds, setFeeds] = useState([]);
  const [isNewFeed, setIsNewFeed] = useState(false);

  useEffect(() => {
    socket.emit("initial_data");
    socket.on("get_data", getData);
    // socket.on("change_data", changeData);
    return () => {
      socket.off("get_data");
      socket.off("change_data");
    };
  }, []);

  const getData = (feeds: any) => {
    let filtered_array: any = _.filter(feeds, function (o: any) {
      if (o.requester_email == "adebay9.abiodun@firstregistrarsnigeria.com") {
        return o;
      } else if (o.approver_email == "YAYA.LAWAL@firstregistrarsnigeria.com") {
        return o;
      }
    });

    console.log(filtered_array);

    if (
      filtered_array.length &&
      filtered_array.some((feed: any) => feed.read === false)
    ) {
      setIsNewFeed(true);
    } else {
      setIsNewFeed(false);
    }
    setFeeds(filtered_array);
  };

  // console.log(feeds);

  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const links = mockdata.map((item) => (
    <>
      <LinksGroup {...item} label={item.label} to={item.links} />
    </>
  ));

  //   const data = useSelector((state: any) => {
  //     return { ...state };
  //   });

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.blue[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          height="auto"
          width={{ sm: 260 }}
          p="md"
          hidden={!opened}
          className={classes.navbar}
        >
          <Navbar.Section grow className={classes.links} component={ScrollArea}>
            <div className={classes.linksInner}>{links}</div>
          </Navbar.Section>
        </Navbar>
      }
      footer={
        <Footer height={50} p="md">
          <Group className={classes.links} position="center">
            <Text align="center">© 2022</Text>
            <Text align="center">FRISOPS</Text>
          </Group>

          {/* <Group spacing="xs" position="right" noWrap>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandTwitter size={12} stroke={1.5} />
            </ActionIcon>
          </Group> */}
        </Footer>
      }
      header={
        <>
          {/* <LoadingBar
            height={5}
            color="#2C5178"
            waitingTime={2000}
            progress={data.updates.values}
            onLoaderFinished={() => setProgress(0)}
          /> */}
          <Header height={70} p="md">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <Image
                src="https://i.ibb.co/ZgNdCXJ/logo.png"
                width={130}
                style={{ padding: 40 }}
              />

              <Group>
                {" "}
                <Divider
                  orientation="vertical"
                  style={{ marginLeft: 20 }}
                  color="blue"
                />
                <Title
                  order={2}
                  style={{ marginLeft: "0px" }}
                  variant="gradient"
                  gradient={{ from: "blue", to: "gold", deg: 45 }}
                >
                  {" "}
                  Requistion Portal{" "}
                </Title>{" "}
                <Divider orientation="vertical" color="blue" />
              </Group>
            </div>
            <div style={{ marginTop: -35 }}>
              <Group position="right">
                <Indicator withBorder processing color="red" size={9}>
                  <Menu shadow="md" width={350} offset={3} withArrow>
                    <Menu.Target>
                      <ActionIcon variant="transparent" color="blue">
                        <IconBell size={22} />
                      </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Label>Notifications</Menu.Label>

                      {feeds.length ? (
                        feeds.map((feed: any) => {
                          return (
                            <>
                              <Menu.Item
                                icon={
                                  <IconAlertOctagon size={14} color={"red"} />
                                }
                              >
                                <>
                                  This control number -{" "}
                                  <strong>{feed.control_number} </strong>is
                                  being created by{" "}
                                  {feed.requester_email
                                    .split("@")[0]
                                    .toUpperCase()}{" "}
                                  {""}
                                  and needed the approval from -{" "}
                                  {feed.approver_email.split("@")[0]}
                                </>
                              </Menu.Item>
                            </>
                          );
                        })
                      ) : (
                        <Menu.Item key="nothing">
                          <p>No feeds to show!</p>
                        </Menu.Item>
                      )}

                      <Menu.Divider />

                      <Menu.Label>Danger zone</Menu.Label>
                    </Menu.Dropdown>
                  </Menu>
                </Indicator>

                <Avatar
                  radius="xl"
                  size="md"
                  variant="outline"
                  color="grape"
                  src={
                    user !== undefined
                      ? "data:image/png;base64," + user.pics
                      : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                  }
                  // src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                />
              </Group>
            </div>
          </Header>
        </>
      }
    >
      <Container my="xs">
        <Card shadow="md" radius="md">
          {/* <TabsDashboard /> */}
          <HeroHeader />
          <br />
          <ApprovalLogGrid />
        </Card>

        {/* <GridLayout title={""} completed={0} total={0} description={""} /> */}
      </Container>
    </AppShell>
  );
}
