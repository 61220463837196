import {
  ActionIcon,
  Divider,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Popover,
  TextInput,
  Text,
  Title,
  Button as Buttons,
  SimpleGrid,
  Select as Selects,
  Stack,
} from "@mantine/core";
import {
  Button,
  List,
  Select,
  message,
  Upload,
  Steps,
  Table,
  Popconfirm,
} from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddItemContext from "../../../AddItemsContext";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import { IconSquareCheck, IconSquareX } from "@tabler/icons";
import { useForm } from "@mantine/form";

let p: any;
let data: any = [];

interface DataType {
  key: string;
  items: string;
  status: number;
  createdAt: any;
}

const AddItems = (props: any) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [t, setT] = useState([]);
  const [item, setItems] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [counter, setCounter] = useState(0);
  const [values, setValues] = useState(0);

  const { addToItem, items } = useContext(AddItemContext);

  let { user } = useSelector((state: any) => ({ ...state }));

  let { show } = useSelector((state: any) => ({ ...state }));

  const navigate = useNavigate();

  let { control_id } = useParams();

  const onSelectChange = (newSelectedRowKeys: React.Key[], index: any) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const loadDataFromDB = () => {
    data = [];

    fetch(`https://frisbackendurl.azurewebsites.net/requests/${control_id}`)
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value: any, index: any) => {
          // console.log(counter);
          data.push({
            key: value.uuid,
            items: value.items,
            quantity: value.quantity,
            control_number: value.control_number,
            status: value.status,
            button_ui: value.button_ui,
            counts_of_items: value.count_of_items,
            id: value.id,
          });
          setValues(Number(value.count_of_items));
        });
        setDataSource(data);
      })
      .finally(() => {
        //   setLoading(false);
      });
    //  };
  };

  const confirm = async (uuid: any) => {
    await axios
      .put(
        `https://firstregistrarsnigeria.com/frisportals/requisitions/${uuid}`,
        {
          status: "APPROVED",
        }
      )
      .then(() => {
        setCounter((prevCount) => prevCount + 1);
        loadDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const saveLogs = async () => {
    console.log("Hello");
    await axios
      .put(
        `https://firstregistrarsnigeria.com/frisportal/requestlog/${control_id}`,
        {
          status: "TREATED",
        }
      )
      .then((item) => {
        //  setCounter((prevCount) => prevCount + 1);
        //  loadDataFromDB();
        console.log(item);
        message.success({
          content: "Request had been updated Successfully !",
          duration: 7,
        });
        navigate("/admin/approval/");
      });
  };

  useEffect(() => {
    loadDataFromDB();
  }, []);

  // const arrayFunction = (element) =>{

  // }

  // useEffect(() => {
  //   let a = _.every(dataSource, (element: any) => {
  //     console.log(element.button_ui);
  //   });

  //   //console.log(a);
  // });

  useEffect(() => {
    buttonFunction();
  }, []);

  const buttonFunction = () => {
    if (Number(values) && counter == Number(values)) {
      setButtonDisabled(false);
    } else if (
      _.every(dataSource, (element: any) => element.button_ui === true)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  // const form = useForm({
  //   initialValues: {
  //     item: item,
  //     quantity: 0,
  //     subject: "",
  //     message: "",
  //   },
  //   validate: {
  //     item: (value) => value.trim().length < 2,
  //     //email: (value) => !/^\S+@\S+$/.test(value),
  //     subject: (value) => value.trim().length === 0,
  //   },
  // });

  const form = useForm({
    initialValues: {
      item: "",
      quantity: 0,
      termsOfService: false,
    },

    validate: {
      item: (value) => value.trim().length < 2,
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    //console.log(form.values.item);

    try {
      await axios
        .post("https://frisbackendurl.azurewebsites.net/consumables/add", {
          items: form.values.item,
          stock_in: form.values.quantity,
        })
        .then((data) => {
          setLoading(true);
          console.log(data);
          message.success("Added Successfully !");
          navigate("/admin/consumables");
        });
    } catch (error: any) {
      message.error(
        "An error occurred because the current item submitted had been duplicated. Please enter new items that are not on the consumables list."
      );
    }
  };

  return (
    <Paper
      shadow="md"
      radius="lg"
      p="lg"
      withBorder
      style={{ padding: "0px", margin: "30px" }}
    >
      <div className="removeMargin">
        <div className="firstHalf">
          <div
            style={{
              padding: 40,
              height: "auto",
            }}
          >
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Stack>
                <Title
                  order={2}
                  size="h1"
                  sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                  })}
                  weight={900}
                  align="center"
                >
                  Add Items
                </Title>

                <Text color="dimmed" ta="center" style={{ bottom: 20 }}>
                  Utilizing the form below, you can add{" "}
                  <strong>one item </strong>
                  at a time.
                </Text>

                <br />

                <TextInput
                  label="Items"
                  placeholder="Add like Milk, Sugar ..."
                  name="item"
                  variant="filled"
                  required
                  //onChange={(e) => setItems(e.target.value)}
                  {...form.getInputProps("item")}
                />

                <NumberInput
                  label="Quantity"
                  //description="Add quantity "
                  placeholder="0, 20, 50..."
                  max={100000}
                  min={0}
                  required
                  {...form.getInputProps("quantity")}
                />

                <SimpleGrid
                  cols={1}
                  //mt="xl"
                  breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                >
                  {/* <NumberInput
                  // defaultValue={18}
                  style={{ marginBottom: 30, padding: 0 }}
                  placeholder="select the quantity"
                  label="Quantity"
                  withAsterisk
                  variant="filled"
                /> */}

                  <Selects
                    style={{ marginBottom: 30 }}
                    label="Category"
                    variant="filled"
                    placeholder="Pick one"
                    data={[
                      { value: "food/beverages", label: "Food/Beverages" },
                      { value: "tolietries", label: "Tolietries" },
                      { value: "stationery", label: "Stationery" },
                      { value: "deodorant", label: "Deodorant" },
                      { value: "Others", label: "Others" },
                    ]}
                    //onChange={(data) => console.log(data)}
                  />
                </SimpleGrid>

                <Group position="center" mt="xl">
                  <Buttons type="submit" size="md" loading={loading}>
                    Submit
                  </Buttons>
                </Group>
              </Stack>
            </form>
          </div>
        </div>

        <div className="secondHalf">
          <div className="custom-shape-divider-bottom-1671865759">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fills"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default AddItems;
