import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBxce4xSZeD_1w2xb458qmWhD5l7yxu3kk",
  authDomain: "helpdesk-apps.firebaseapp.com",
  projectId: "helpdesk-apps",
  storageBucket: "helpdesk-apps.appspot.com",
  messagingSenderId: "1091641099731",
  appId: "1:1091641099731:web:9318c4eeef5ddc35465b26",
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const micrsoftAuthProvider = new firebase.auth.OAuthProvider("microsoft.com");

export { auth, db, micrsoftAuthProvider };
