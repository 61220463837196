import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Grid,
  SimpleGrid,
  Skeleton,
  Card,
  Anchor,
  Breadcrumbs,
  Indicator,
  Box,
  Progress,
  ThemeIcon,
  Loader,
  Center,
  Badge,
  NumberInputHandlers,
  MultiSelect,
  NumberInput,
  Button as Buttons,
} from "@mantine/core";
import { Button, List, Popconfirm, Select, Table, Input } from "antd";
import { useScrollIntoView, useWindowScroll } from "@mantine/hooks";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { nanoid } from "nanoid";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { IconSettings, IconSquareCheck, IconSquareX } from "@tabler/icons";

const { Dragger } = Upload;
const { Search } = Input;

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  description: {
    maxWidth: 650,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,

    "&:hover": {
      transform: "scale(1.11)",
      boxShadow: theme.shadows.md,
    },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: "260px",
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface DataType {
  key: string;
  items: string;
  status: number;
  createdAt: any;
}

let getLocalStorageItems: any = localStorage.getItem("Items");

let data: any = [];

const PendingAdminLogs = () => {
  const [scroll, scrollTo] = useWindowScroll();
  const [display, setDisplay] = useState(false);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [a, seta] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [shows, setShows] = useState(false);
  const [loading, setLoading] = useState(false);
  const [c, setC] = useState([]);
  const [searchValues, setSearchValues] = useState("");
  const [values, setValues] = useState(0);
  const handlers: any = useRef<NumberInputHandlers>(null);
  const previousValue: any = useRef(null);

  let { user } = useSelector((state: any) => ({ ...state }));

  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "id",
      key: "id",
      responsive: ["lg"],
      render: (value: any, item: any, index: any) =>
        index === 0 ? index + 1 : index + 1,
    },
    {
      title: "Control-ID",
      dataIndex: "control_number",
      key: "control_number",
      render: (control) => (
        <span className="fw-bolder">
          <Link to={"/global/admin/actions/" + control}>
            {" "}
            <strong> {control} </strong>{" "}
          </Link>
        </span>
      ),
    },
    {
      title: "Items-Counts",
      dataIndex: "countRequests",
      key: "countRequests",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <Badge variant="gradient" gradient={{ from: "indigo", to: "cyan" }}>
          {value}
        </Badge>
      ),
    },

    {
      title: "Approval Name",
      dataIndex: "approval_name",
      key: "approval_name",
    },

    {
      title: "Requester Name",
      dataIndex: "requester_name",
      key: "requester_name",
      filteredValue: [searchValues],
      onFilter(value: any, record: any) {
        return (
          String(record.requester_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.control_number)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },

    // {
    //   title: "Status",
    //   dataIndex: "manager_email",
    //   key: "manager_email",
    // },

    // {
    //   title: "Requester Name",
    //   dataIndex: "manager",
    //   key: "manager",
    //   responsive: ["lg"],
    //   ellipsis: true,
    // },

    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => {
        return <Box> {moment(date).format("MMMM Do YYYY, h:mm:ss a")} </Box>;
      },
    },

    // {
    //   title: "Action(s)",
    //   dataIndex: "uuid",
    //   key: "uuid",
    //   //sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    //   render: (data, a) => {
    //     return (
    //       <Group position="center" spacing="sm">
    //         <Popconfirm
    //           title="Confirmation"
    //           //  description="Are you sure to delete this task?"
    //           //  onConfirm={confirm}
    //           onConfirm={() => confirm(data)}
    //           okText="Yes"
    //           cancelText="No"
    //         >
    //           <ActionIcon
    //             variant="light"
    //             component="button"
    //             // onClick={() => {
    //             //   console.log(data);
    //             // }}
    //           >
    //             <IconSquareCheck size={24} color={"lime"} />
    //           </ActionIcon>
    //         </Popconfirm>
    //         <ActionIcon variant="light">
    //           <IconSquareX size={24} color={"red"} />
    //         </ActionIcon>
    //       </Group>
    //     );
    //   },
    // },
  ];

  // let control_id = window.location.href;

  // console.log(data);

  const LoadsDataFromDB = () => {
    data = [];
    setLoading(true);
    //http://localhost:5000/requestlog/${user.name}
    fetch(`https://frisbackendurl.azurewebsites.net/requestlog`)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.data);
        // setOptions(res.data);
        setLoading(true);

        res.data.forEach((value: any, index: any) => {
          data.push({
            key: nanoid(),
            control_number: value.control_number,
            countRequests: value.countRequests,
            status: value.status,
            requester_name: value.requester_name,
            approval_name: value.approval_name,
            createdAt: value.createdAt,
          });
        });
        setDataSource(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    LoadsDataFromDB();
  }, []);

  const confirm = async (uuid: any) => {
    await axios
      .put(
        // `https://firstregistrarsnigeria.com/frisportals/requisitions/${uuid}`,
        `https://frisbackendurl.azurewebsites.net/requisitions/${uuid}`,
        {
          status: "APPROVED",
        }
      )
      .then(() => {
        LoadsDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".csv",
    action: "https://firstregistrarsnigeria.com/frisportals/uploadfiles",
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        const parent: any = document.querySelector(".ant-upload-list");

        setDisplay(true);

        const newEl = document.createElement("p");

        newEl.style.cssText = "text-align:center";
        newEl.textContent = "Attachment List";

        parent.insertBefore(newEl, parent.children[0]);
        window.scroll(0, 500);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      setDisplay(false);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <SimpleGrid
        cols={1}
        spacing="md"
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        <Card
          withBorder
          radius="md"
          p="xl"
          style={{ padding: "9px !important" }}
        >
          {/* <Image
            src={"https://i.ibb.co/M6SSP1d/Requisition.png"}
            fit="fill"
            radius="sm"
          /> */}
          <div className="mantine-Badge-root mantine-rhsza5">
            <span className="mantine-h9iq4m mantine-Badge-inner">
              Manger's pending Logs
            </span>
          </div>
          {/* <Text
            variant="gradient"
            gradient={{ from: "gold", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            weight={650}
            style={{ marginTop: 20 }}
            size={25}
            className={classes.description}
            align="center"
          >
            Central Document Request-Types Logs
          </Text> */}
          <Text size="sm" mt="sm" color="dimmed" style={{ padding: 20 }}>
            Below are manager's pending logs
          </Text>
          <div style={{ padding: 30 }}>
            <div style={{ marginBottom: 10 }}>
              <Search
                placeholder="Input your search text..."
                onSearch={(value) => {
                  setSearchValues(value);
                }}
                onChange={(e) => {
                  setSearchValues(e.target.value);
                }}
                enterButton
              />
            </div>
            <Table
              bordered
              //rowSelection={{}}
              className=""
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              // onChange={onChange}
            />
          </div>
        </Card>

        {/* <Grid gutter="md">
            <Grid.Col>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid> */}
      </SimpleGrid>
    </>
  );
};

export default PendingAdminLogs;
