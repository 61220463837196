import {
  Title,
  Text,
  Container,
  Button,
  Overlay,
  createStyles,
  Group,
  Image,
  ActionIcon,
  Loader,
} from "@mantine/core";
import {
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandYoutube,
} from "@tabler/icons";
import { message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: "211px",
    paddingBottom: "140px",
    backgroundImage:
      "url(https://i.ibb.co/Y3mYyPj/luca-bravo-i-GQKv-Ai-Ip-PI-unsplash.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",

    [theme.fn.smallerThan("xs")]: {
      paddingTop: 80,
      paddingBottom: 50,
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  inners: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  title: {
    fontWeight: 10,
    fontSize: 40,
    letterSpacing: -1,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    color: theme.white,
    marginBottom: theme.spacing.xs,
    textAlign: "center",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: 28,
      textAlign: "left",
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][4],
  },

  description: {
    color: theme.colors.gray[0],
    textAlign: "center",

    [theme.fn.smallerThan("xs")]: {
      fontSize: theme.fontSizes.md,
      textAlign: "left",
    },
  },

  controls: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,
    display: "flex",
    justifyContent: "center",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  control: {
    height: 42,
    fontSize: theme.fontSizes.md,

    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },

  footer: {
    marginTop: "20vh",
    //height: "200px",

    borderTop: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[4]
    }`,
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },

  secondaryControl: {
    color: theme.white,
    backgroundColor: "rgba(255, 255, 255, .4)",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, .45) !important",
    },
  },
}));

export default function ConfirmJWT() {
  const { classes, cx } = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  let token: any = useParams();
  //let control: any = useParams();

  //console.log("control", control);
  // console.log("token", token);

  const confirmJWT = async () => {
    await axios
      .post(
        `https://frisbackendurl.azurewebsites.net/confirmapprove`,
        {
          //token: token,
          control: token.control,
        },
        {
          headers: { token: token.token },
        }
      )
      .then((a) => {
        // setCounter((prevCount) => prevCount + 1);
        // loadDataFromDB();
        setIsLoading(true);
        //console.log(a);
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
        message.error({
          content:
            "Unauthorized Access or either the Token had expired or this link had previously been used !",
          duration: 950,
        });
      });
  };

  useEffect(() => {
    confirmJWT();
  }, []);

  return (
    <div>
      <div className={classes.wrapper}>
        <Overlay color="#000" opacity={0.65} zIndex={1} />

        <div className={classes.inner}>
          <Title className={classes.title}>
            An Automated repsonse hub with{" "}
            <Text component="span" inherit className={classes.highlight}>
              <strong>built-in AI </strong>
            </Text>
          </Title>

          <Container size={640}>
            <Text size="lg" className={classes.description}>
              {" "}
              Please wait while the background process is running. Once done,
              you will receive feedback. Otherwise, an{" "}
              <strong>
                {" "}
                <span style={{ color: "red" }}>error message</span>
              </strong>{" "}
              will be returned.
            </Text>
          </Container>

          <div className={classes.controls}>
            {/* <Button className={classes.control} variant="white" size="lg">
              Get started
            </Button> */}
            {!isLoading ? (
              <Loader color="orange" size="xl" variant="bars" />
            ) : (
              <Button
                className={cx(classes.control, classes.secondaryControl)}
                size="lg"
                style={{ cursor: "none" }}
              >
                APPROVED !
              </Button>
            )}
            ;
            {/* <Button
              className={cx(classes.control, classes.secondaryControl)}
              size="lg"
            >
              Live demo
            </Button> */}
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Container className={classes.inners}>
          {/* <MantineLogo size={28} /> */}

          <Image
            src="https://i.ibb.co/ZgNdCXJ/logo.png"
            width={130}
            style={{ padding: 5 }}
          />
          <Group spacing={0} className={classes.links} position="right" noWrap>
            <ActionIcon size="lg">
              <IconBrandTwitter size="1.05rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon size="lg">
              <IconBrandYoutube size="1.05rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon size="lg">
              <IconBrandInstagram size="1.05rem" stroke={1.5} />
            </ActionIcon>
          </Group>
        </Container>
      </div>
    </div>
  );
}
