import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Grid,
  SimpleGrid,
  Skeleton,
  Card,
  Anchor,
  Breadcrumbs,
  Indicator,
  Box,
  Progress,
  ThemeIcon,
  Loader,
  Center,
  Badge,
  NumberInputHandlers,
  MultiSelect,
  NumberInput,
  Paper,
  Button as Buttons,
  Stepper,
} from "@mantine/core";
import {
  IconArrowRight,
  IconArrowLeft,
  IconSquareArrowRight,
  IconSquareArrowLeft,
} from "@tabler/icons";
import { Button, List, Select, message, Upload, Steps } from "antd";
import { useScrollIntoView, useWindowScroll } from "@mantine/hooks";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { nanoid } from "nanoid";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const { Dragger } = Upload;

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  description: {
    maxWidth: 650,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,

    "&:hover": {
      transform: "scale(1.11)",
      boxShadow: theme.shadows.md,
    },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: "260px",
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

let p: any;
let data: any = [];
let dataHod: any = [];

const Requisition = () => {
  const [scroll, scrollTo] = useWindowScroll();
  const [display, setDisplay] = useState(false);
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const [a, seta] = useState([]);
  const [shows, setShows] = useState(true);
  const [c, setC] = useState([]);
  const [values, setValues] = useState(0);
  const handlers: any = useRef<NumberInputHandlers>(null);
  const previousValue: any = useRef(null);

  // let { show } = useSelector((state: any) => ({ ...state }));
  let { user } = useSelector((state: any) => ({ ...state }));

  let { show } = useSelector((state: any) => ({ ...state }));

  const navigate = useNavigate();

  console.log(show);

  // let control_id = window.location.href;

  // console.log(data);

  useEffect(() => {
    dataHod = [];

    fetch("https://frisbackendurl.azurewebsites.net/frishod")
      .then((res) => res.json())
      .then((res) => {
        //console.log("done !");
        res.data.forEach((value: any, index: any) => {
          //console.log(value.disabled);
          dataHod.push({
            key: nanoid(),
            value: value.fullnames,
            label:
              value.disabled === false
                ? `${value.fullnames}`
                : `${value.fullnames} - ON LEAVE`,
            email: value.email,
            disabled: value.disabled,
          });
        });
      })
      .finally(() => {
        //   setLoading(false);
      });
  }, []);

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".csv",
    action: "https://firstregistrarsnigeria.com/frisportals/uploadfiles",
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        const parent: any = document.querySelector(".ant-upload-list");

        setDisplay(true);

        const newEl = document.createElement("p");

        newEl.style.cssText = "text-align:center";
        newEl.textContent = "Attachment List";

        parent.insertBefore(newEl, parent.children[0]);
        window.scroll(0, 500);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      setDisplay(false);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const steps = [
    {
      title: "Requisition",
      content: <Step1 />,
    },
    {
      title: "Approver/Depart.",
      content: <Step2 value={dataHod} />,
    },
    {
      title: "Close-Out",
      content: <Step3 />,
    },
  ];

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <SimpleGrid
        cols={1}
        spacing="md"
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        <Card
          withBorder
          radius="md"
          p="xl"
          style={{ padding: "9px !important" }}
        >
          <br />

          <div
            className="mantine-Badge-root mantine-rhsza5"
            style={{ marginTop: 20 }}
          >
            <span className="mantine-h9iq4m mantine-Badge-inner">
              Requisition Modules
            </span>
          </div>
          <br />
          {/* <Text
            variant="gradient"
            gradient={{ from: "gold", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            weight={650}
            style={{ marginTop: 20 }}
            size={25}
            className={classes.description}
            align="center"
          >
            Central Document Request-Types Logs
          </Text> */}
          <Text size="sm" mt="sm" color="dimmed" style={{ padding: 20 }}>
            Enter the required quantity after selecting the required items and
            approver from the drop-down menu. Once finished, press the submit
            button.
          </Text>

          <Steps current={current} items={items} style={{ padding: 20 }} />

          <div className="steps-content" style={{ position: "relative" }}>
            {steps[current].content}
          </div>
          <div className="steps-action">
            {current < steps.length - 1 && (
              <Buttons
                disabled={show ? false : true}
                onClick={() => {
                  next();
                }}
                rightIcon={<IconArrowRight />}
              >
                Next
              </Buttons>
            )}
            {/* {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )} */}
            {current > 0 && (
              <Buttons
                color="red"
                style={{ margin: "0 8px", bottom: "-6px" }}
                onClick={() => {
                  prev();
                  // localStorage.removeItem("Approval");
                  // localStorage.removeItem("Items");
                }}
                leftIcon={<IconArrowRight />}
              >
                Previous
              </Buttons>
            )}
          </div>
        </Card>

        {/* <Grid gutter="md">
            <Grid.Col>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid> */}
      </SimpleGrid>
    </>
  );
};

export default Requisition;
