import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Grid,
  SimpleGrid,
  Skeleton,
  Card,
  Anchor,
  Breadcrumbs,
  Indicator,
  Box,
  Progress,
  ThemeIcon,
  Loader,
  Center,
  Badge,
  NumberInputHandlers,
  MultiSelect,
  NumberInput,
  Select,
  Paper,
  Button as Buttons,
  Divider as Dividers,
  Space,
} from "@mantine/core";
import {
  Button,
  List,
  Popconfirm,
  Table,
  Avatar as Avatars,
  Input,
  DatePicker,
  Divider,
} from "antd";
import { useScrollIntoView, useWindowScroll } from "@mantine/hooks";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { nanoid } from "nanoid";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { IconSettings, IconSquareCheck, IconSquareX } from "@tabler/icons";
import momentBusiness from "moment-business-days";
import { useForm } from "@mantine/form";

const { Dragger } = Upload;
const { Search } = Input;
const { RangePicker } = DatePicker;

let dateYear: any = new Date();

const newYearDay = `01-01-${dateYear.getFullYear()}`;
const laborDay = `05-01-${dateYear.getFullYear()}`;
const democracyDay = `06-12-${dateYear.getFullYear()}`;
const independenceDay = `10-01-${dateYear.getFullYear()}`;
const christmasDay = `12-25-${dateYear.getFullYear()}`;
const boxingDay = `12-26-${dateYear.getFullYear()}`;

momentBusiness.updateLocale("us", {
  holidays: [
    newYearDay,
    laborDay,
    democracyDay,
    independenceDay,
    christmasDay,
    boxingDay,
  ],
  holidayFormat: "MM-DD-YYYY",
  forcedBusinessDaysFormat: "MM-DD-YYYY",
  workingWeekdays: [1, 2, 3, 4, 5],
});

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  description: {
    maxWidth: 650,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,

    "&:hover": {
      transform: "scale(1.11)",
      boxShadow: theme.shadows.md,
    },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: "260px",
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface DataType {
  key: string;
  items: string;
  status: number;
  updatedAt: any;
}

let getLocalStorageItems: any = localStorage.getItem("Items");

let data: any = [];
let getUser: any = [];

const TransferModule = () => {
  const [scroll, scrollTo] = useWindowScroll();
  const [display, setDisplay] = useState(false);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [a, seta] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [shows, setShows] = useState(false);
  const [c, setC] = useState([]);
  const [searchValues, setSearchValues] = useState("");
  const [userprofile, setUserprofile] = useState<any>([]);
  const [numberDays, setNumberDays] = useState(0);
  const [dateReturn, setDateReturn] = useState<any>("");
  const [roleChange, setRoleChange] = useState<any>("");
  const [actingUsername, setActingUsername] = useState("");
  const [getEmail, setGetEmail] = useState("");
  const handlers: any = useRef<NumberInputHandlers>(null);
  const previousValue: any = useRef(null);

  const navigate = useNavigate();

  let { user } = useSelector((state: any) => ({ ...state }));

  const form = useForm({
    initialValues: {
      email: "",
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "id",
      key: "id",
      responsive: ["lg"],
      render: (value: any, item: any, index: any) =>
        index === 0 ? index + 1 : index + 1,
    },
    {
      title: "Control-ID",
      dataIndex: "control_number",
      key: "control_number",
      render: (control) => (
        <span className="fw-bolder">
          {/* <Link to={"/manager/actions/" + control}> */}{" "}
          <strong> {control} </strong> {/* </Link> */}
        </span>
      ),
    },
    {
      title: "Items-Counts",
      dataIndex: "countRequests",
      key: "countRequests",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <Badge variant="gradient" gradient={{ from: "indigo", to: "cyan" }}>
          {value}
        </Badge>
      ),
    },

    {
      title: "Approver",
      dataIndex: "approval_name",
      key: "approval_name",
    },

    {
      title: "Requester",
      dataIndex: "requester_name",
      key: "requester_name",
      filteredValue: [searchValues],
      onFilter(value: any, record: any) {
        return (
          String(record.requester_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record.control_number)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },

    {
      title: "Approved Rate (%)",
      dataIndex: "progress_approve",
      key: "progress_approve",
      width: "5%",
      render: (progress) => {
        return (
          <Progress
            color="green"
            value={progress < 30 ? 50 : progress}
            label={progress + "%"}
            size="xl"
            radius="xl"
            striped
            animate
          />
        );
      },
    },

    {
      title: "Rejected Rate (%)",
      dataIndex: "progress_decline",
      key: "progress_decline",
      render: (progress) => {
        return (
          <Progress
            color="red"
            value={progress < 30 ? 50 : progress}
            label={progress + "%"}
            size="xl"
            radius="xl"
            striped
            animate
          />
        );
      },
    },

    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      render: (date) => {
        return <Box> {moment(date).format("MMMM Do YYYY, h:mm:ss a")} </Box>;
      },
    },
  ];

  // let control_id = window.location.href;

  // console.log(data);

  const LoadsDataFromDB = () => {
    data = [];
    fetch("https://frisbackendurl.azurewebsites.net/all/users")
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.data);
        // setOptions(res.data);

        // console.log(res.data);

        res.data.forEach((value: any, index: any) => {
          data.push({
            key: nanoid(),
            name: value.name,
            photo_url: value.photo_url,
            role: value.role,
            //  requester_name: value.requester_name,
            email: value.email,
            value: value.email,
            label: value.name,
            // updatedAt: value.updatedAt,
          });
        });
        setDataSource(data);
      })
      .finally(() => {
        //   setLoading(false);
      });
  };

  useEffect(() => {
    LoadsDataFromDB();
  }, []);

  const confirm = async (uuid: any) => {
    await axios
      .put(
        `https://firstregistrarsnigeria.com/frisportals/requisitions/${uuid}`,
        {
          status: "APPROVED",
        }
      )
      .then(() => {
        LoadsDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".csv",
    action: "https://firstregistrarsnigeria.com/frisportals/uploadfiles",
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        const parent: any = document.querySelector(".ant-upload-list");

        setDisplay(true);

        const newEl = document.createElement("p");

        newEl.style.cssText = "text-align:center";
        newEl.textContent = "Attachment List";

        parent.insertBefore(newEl, parent.children[0]);
        window.scroll(0, 500);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      setDisplay(false);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleSubmit = async (values: typeof form.values) => {
    console.log("hello");

    // await axios
    //   .post("http://localhost:5000/consumables/add", {
    //     items: form.values.item,
    //     stock_in: form.values.quantity,
    //   })
    //   .then((data) => {
    //     setLoading(true);
    //     console.log(data);
    //     message.success("Done !");
    //     navigate("/admin/consumables");
    //   });
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SimpleGrid
          cols={1}
          spacing="md"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <Card
            //withBorder
            radius="md"
            p="xl"
            style={{ padding: "12px !important" }}
          >
            {/* <Image
            src={"https://i.ibb.co/M6SSP1d/Requisition.png"}
            fit="fill"
            radius="sm"
          /> */}
            <div className="mantine-Badge-root mantine-rhsza5">
              <span className="mantine-h9iq4m mantine-Badge-inner">
                Acting Officers Logs
              </span>
            </div>
            {/* <Text
            variant="gradient"
            gradient={{ from: "gold", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            weight={650}
            style={{ marginTop: 20 }}
            size={25}
            className={classes.description}
            align="center"
          >
            Central Document Request-Types Logs
          </Text> */}
            <Text size="sm" mt="sm" color="dimmed" style={{ padding: 20 }}>
              Please provide the information for your acting officer.
            </Text>
            <Paper
              shadow="md"
              radius="lg"
              p="lg"
              withBorder
              style={{ padding: "0px", margin: "5px" }}
            >
              <div className="removeMargin">
                <div className="firstHalf">
                  <div
                    style={{
                      padding: 40,
                      bottom: 0,
                      height: "auto",
                    }}
                  >
                    <Select
                      label="Your acting officer: "
                      placeholder="Pick one"
                      description="Please choose one acting officer while on your vacation."
                      searchable
                      clearable
                      // onChange={(item:any) => {
                      //     console.log(item)
                      // }}
                      nothingFound="No options"
                      data={dataSource}
                      onChange={(email: any) => {
                        //  console.log(item);
                        getUser = [];

                        fetch(
                          `https://frisbackendurl.azurewebsites.net/all/user/profile/${email}`
                        )
                          .then((res) => res.json())
                          .then((res) => {
                            // console.log(res.data[0].name);
                            //console.log(res.data.name);\\\\
                            setActingUsername(res.data[0].name);
                            setUserprofile(res.data);

                            getUser.push({
                              name: res.data.fullnames,
                              photo_url: res.data.photo_url,
                              email: res.data.email,
                              role: res.data.role,
                              uuid: res.data.uuid,
                            });
                          });
                        // setUserprofile(getUser);
                        setGetEmail(email);
                      }}
                    />
                  </div>
                  {/* <div>{userprofile.email}</div> */}
                  {/* 
                {getUser.length > 0 ? (
                  <List
                    header={<div>Header</div>}
                    footer={<div>Footer</div>}
                    bordered
                    dataSource={getUser}
                    renderItem={(item: any) => (
                      <List.Item>{item.email}</List.Item>
                    )}
                  />
                ) : null} */}

                  {userprofile.length > 0 ? (
                    <>
                      <Dividers my="sm" style={{ margin: 27 }} />
                      <div style={{ margin: "50px" }}>
                        {/* <List
                      header={<div>Header</div>}
                      footer={<div>Footer</div>}
                      bordered
                      dataSource={userprofile}
                      renderItem={(item: any) => (
                        <List.Item>{item.name}</List.Item>
                      )}
                    /> */}

                        <List
                          className="demo-loadmore-list"
                          //  loading={initLoading}
                          itemLayout="horizontal"
                          // loadMore={loadMore}
                          dataSource={userprofile}
                          renderItem={(item: any) => (
                            <>
                              <List.Item
                              // actions={[
                              //   <a key="list-loadmore-edit">edit</a>,
                              //   <a key="list-loadmore-more">more</a>,
                              // ]}
                              >
                                {/* <Skeleton
                            avatar
                            title={false}
                            loading={item.loading}
                            active
                          > */}
                                <List.Item.Meta
                                  avatar={
                                    <Avatars
                                      size={{ xl: 100 }}
                                      src={
                                        "data:image/png;base64," +
                                        item.photo_url
                                      }
                                    />
                                  }
                                  title={
                                    <Text>
                                      <strong>{item.name}</strong>
                                    </Text>
                                  }
                                  description={
                                    <>
                                      <strong>Role: </strong> {item.role} <br />{" "}
                                      <strong>e-mail:</strong> {item.email}{" "}
                                    </>
                                  }
                                />
                                <div></div>
                                <div style={{ display: "flex" }}>
                                  <Space w="xl" />
                                  <Select
                                    label="Role:"
                                    defaultValue="user"
                                    description="Kindly update this user's role"
                                    style={{
                                      margin: "-20px",
                                      // top: "-20px",
                                      bottom: "20px",
                                    }}
                                    onChange={(role: any) => {
                                      setRoleChange(role);
                                    }}
                                    placeholder="Pick one"
                                    searchable
                                    // onSearchChange={onSearchChange}
                                    //  searchValue={searchValue}
                                    nothingFound="No options"
                                    data={["user", "manager"]}
                                  />
                                </div>
                                {/* </Skeleton> */}
                              </List.Item>
                              <br /> <br />
                              <div style={{ top: "10px" }}>
                                <div>
                                  <Text c="dimmed" ta="center">
                                    <strong> Please</strong> take note that your{" "}
                                    <strong>
                                      acting officer will be activated for the
                                      duration specified.
                                    </strong>{" "}
                                    While you're away on vacation, your acting
                                    officer will be able to carry out tasks on
                                    your behalf. Additionally, the procedure is
                                    automated, and once your vacation is over,{" "}
                                    <strong>
                                      you will immediately be activated again.
                                    </strong>
                                  </Text>
                                  <Space h="xl" />
                                  <Space h="xl" />
                                  <Grid justify="center" gutter="xl">
                                    <Grid.Col
                                      span={5}
                                      style={{ minHeight: 80 }}
                                    >
                                      <Text
                                        ta="justify"
                                        fw={500}
                                        style={{ bottom: "2px" }}
                                      >
                                        Vacation Date:
                                      </Text>
                                      <Text
                                        ta="justify"
                                        c="dimmed"
                                        style={{ bottom: "2px" }}
                                      >
                                        Please, the duration of your vacation
                                      </Text>

                                      <RangePicker
                                        // style={{ top: "20px" }}
                                        onChange={(date: any) => {
                                          if (date === null) {
                                            return null;
                                          }
                                          let startDate: any = moment(
                                            date[0].toString()
                                          ).format("MM-DD-YYYY");

                                          let diff = momentBusiness(
                                            startDate
                                          ).businessDiff(
                                            moment(date[1].toString(), true)
                                          );

                                          setDateReturn(date[1].toString());
                                          //  console.log(dateReturn);

                                          // console.log(diff);
                                          setNumberDays(diff);
                                        }}
                                      />
                                    </Grid.Col>
                                    <Grid.Col
                                      span={5}
                                      style={{ minHeight: 120 }}
                                    >
                                      Number_of_Days:{" "}
                                      <Badge p={10}>
                                        {" "}
                                        {numberDays > 0
                                          ? numberDays
                                          : null}{" "}
                                        Day(s)
                                      </Badge>
                                      <Space h="sm" />
                                      Expected_Date_of_Return:{" "}
                                      <Badge>
                                        {" "}
                                        {dateReturn !== ""
                                          ? moment(dateReturn).format(
                                              "MMMM Do YYYY"
                                            )
                                          : null}{" "}
                                      </Badge>
                                    </Grid.Col>
                                    {/* <Grid.Col span={3}>3</Grid.Col> */}
                                  </Grid>

                                  <Grid justify="center" gutter="xl">
                                    <Space h="xl" />
                                    <Grid.Col span={1}>
                                      {dateReturn !== "" &&
                                      roleChange !== "" ? (
                                        <Buttons
                                          //  type="submit"
                                          disabled={user.delegate_role}
                                          onClick={async () => {
                                            await axios
                                              .post(
                                                "https://frisbackendurl.azurewebsites.net/change/user/role",
                                                {
                                                  // items:
                                                  dateReturn,
                                                  roleChanged: roleChange,
                                                  actingEmail: getEmail,
                                                  managerEmail: user.email,
                                                  actingUsername,
                                                  managerName: user.name,
                                                }
                                              )
                                              .then(() => {
                                                message.loading(
                                                  "Action in progress..",
                                                  3,
                                                  () => {
                                                    message.success(
                                                      "Completed !"
                                                    );
                                                  }
                                                );
                                                navigate("/manager/dashboard");
                                              });
                                          }}
                                        >
                                          Submit
                                        </Buttons>
                                      ) : null}
                                    </Grid.Col>
                                  </Grid>
                                </div>
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </>
                  ) : null}

                  {/* <div> {_.isEmpty({})}</div> */}
                </div>

                <br />

                <div className="secondHalf">
                  <div className="custom-shape-divider-bottom-1671865759">
                    <svg
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1200 120"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                        opacity=".25"
                        className="shape-fill"
                      ></path>
                      <path
                        d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                        opacity=".5"
                        className="shape-fill"
                      ></path>
                      <path
                        d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                        className="shape-fill"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </Paper>
          </Card>

          {/* <Grid gutter="md">
            <Grid.Col>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid> */}
        </SimpleGrid>
      </form>
    </>
  );
};

export default TransferModule;
