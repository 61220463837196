import { useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Breadcrumbs,
  Anchor,
  Title,
  Divider,
  Card,
  Menu,
  Badge,
} from "@mantine/core";
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconUpload,
  IconPencilPlus,
  IconBell,
  IconScanEye,
  IconClipboardText,
  IconSettings,
  IconBellRinging,
  IconGitPullRequest,
  IconHome,
  IconFileLike,
  IconBooks,
  IconCheck,
  IconStack2,
  IconStackPush,
  IconUsers,
  IconMessageCircle,
  IconLogout,
} from "@tabler/icons";
import { UserButton } from "../../../utils/UserButton";
import { LinksGroup, NavbarLinksGroup } from "../../../utils/LinksGroup";
// import TabsDashboard from "./TabsDashboard";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import StatisticsView from "./StatisticsView";
import { HeroHeader } from "./HeroHeader";
import { GridLayout } from "./GridLayout";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import { IconUserShare } from "@tabler/icons-react";
import { useIdle } from "@mantine/hooks";

//import MigratationFile from "./MigratationFile";
// import CertificateUpload from "./CertificateUpload";

// import Bar from "../progressBar/Bar";
// import Containers from "../progressBar/Container";
// import { useNProgress } from "@tanem/react-nprogress";

// import LoadingBar from "react-top-loading-bar";
// import { useSelector } from "react-redux";

const mockdata = [
  { label: "Dashboard", icon: IconGauge, linked: "/manager/dashboard" },

  {
    label: "Notifications",
    icon: IconBellRinging,
    initiallyOpened: true,
    links: [
      { label: "Pending Logs", link: "/manager/pendinglogs" },
      { label: "Treated Logs", link: "/manager/treatedlog" },
      // { label: "Declined Logs", link: "/" },
      // { label: "Issued Logs", link: "/" },
    ],
  },
  { label: "Delegate User", icon: IconUserShare, linked: "/manager/transfer" },
  { label: "Settings", icon: IconSettings, linked: "/manager/settings" },
];
const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  // footer: {
  //   marginLeft: "260px",
  //   marginRight: -theme.spacing.md,
  //   borderTop: `1px solid ${
  //     theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
  //   }`,
  // },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.lime[4]
    }`,
  },

  avatars: {
    border: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
}));

// const Progress: React.FC<{ isAnimating: boolean }> = ({ isAnimating }) => {
//   const { animationDuration, isFinished, progress } = useNProgress({
//     isAnimating,
//   });

//   return (
//     <Containers animationDuration={animationDuration} isFinished={isFinished}>
//       <Bar animationDuration={animationDuration} progress={progress} />
//       {/*
//       This example doesn't use a spinner component so the UI stays
//       tidy. You're free to render whatever is appropriate for your
//       use-case.
//       */}
//     </Containers>
//   );
// };

const items = [
  { title: <IconHome />, href: "/", end: "false" },
  { title: "Correspondence", href: "", end: "true" },
].map((item, index) => (
  <Anchor component={Link} to={item.href} key={index}>
    {item.title}
  </Anchor>
));

export default function MainDashboard() {
  // Let user  = null

  let { user } = useSelector((state: any) => ({ ...state }));

  let { show } = useSelector((state: any) => ({ ...state }));

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const links = mockdata.map((item) => (
    <>
      <LinksGroup {...item} label={item.label} to={item.links} />
    </>
  ));

  const Logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGOUT",
      payload: null,
    });

    localStorage.removeItem("User");

    navigate("/");
  };

  let userName = user.name;

  let userDetail = userName.split(" ");

  const idle = useIdle(1000000);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.blue[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          height="auto"
          width={{ sm: 260 }}
          p="md"
          hidden={!opened}
          className={classes.navbar}
        >
          <Navbar.Section grow className={classes.links} component={ScrollArea}>
            <div className={classes.linksInner}>{links}</div>
          </Navbar.Section>

          <Navbar.Section className={classes.footer}>
            <UserButton
              image={
                user !== undefined
                  ? "data:image/png;base64," + user.pics
                  : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
              }
              name="Approval's Role"
              email={
                user.email !== undefined
                  ? user.email.substring(0, 22).concat("...")
                  : ""
              }
            />
          </Navbar.Section>
        </Navbar>
      }
      footer={
        <Footer height={50} p="md">
          <Group className={classes.links} position="center">
            <Text align="center">© {new Date().getFullYear()}. </Text>
            <Text align="center">
              {" "}
              <>
                {" "}
                <strong> FRISPORTAL.</strong>
              </>
            </Text>
          </Group>

          {/* <Group spacing="xs" position="right" noWrap>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandTwitter size={12} stroke={1.5} />
            </ActionIcon>
          </Group> */}
        </Footer>
      }
      header={
        <>
          {/* <LoadingBar
            height={5}
            color="#2C5178"
            waitingTime={2000}
            progress={data.updates.values}
            onLoaderFinished={() => setProgress(0)}
          /> */}
          <Header height={70} p="md">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <Image
                src="https://i.ibb.co/ZgNdCXJ/logo.png"
                width={200}
                style={{ padding: 40 }}
              />

              {/* <Group>
                {" "}
                <Divider
                  orientation="vertical"
                  style={{ marginLeft: 20 }}
                  color="blue"
                />
                <Title
                  order={3}
                  style={{ marginLeft: "0px" }}
                  variant="gradient"
                  gradient={{ from: "blue", to: "gold", deg: 45 }}
                >
                  {" "}
                  Requisition Portal{" "}
                </Title>{" "}
                <Divider orientation="vertical" color="blue" />
              </Group> */}
            </div>
            <div style={{ marginTop: -35 }}>
              <Group position="right">
                <ActionIcon variant="transparent" color="blue">
                  <IconBell size={22} />
                </ActionIcon>

                <Menu
                  width={300}
                  shadow="md"
                  transition="pop-bottom-right"
                  transitionDuration={150}
                  withArrow
                  trigger="hover"
                  openDelay={100}
                  closeDelay={400}
                >
                  <Menu.Target>
                    <Avatar
                      radius="xl"
                      size="md"
                      style={{
                        boxShadow: `0 0 0 2px #c49b36`,
                        cursor: "pointer",
                      }}
                      variant="outline"
                      color="grape"
                      src={
                        user !== undefined
                          ? "data:image/png;base64," + user.pics
                          : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                      }
                      //"https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                    >
                      {userDetail[0].charAt(0) + "" + userDetail[1].charAt(0)}
                    </Avatar>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Card
                      withBorder
                      p="xl"
                      radius="md"
                      //className={}
                    >
                      <Card.Section
                        sx={{
                          backgroundImage: `url(https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80)`,
                          height: 140,
                        }}
                      />

                      <Avatar
                        src={
                          user !== undefined
                            ? "data:image/png;base64," + user.pics
                            : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                        }
                        size={80}
                        radius={80}
                        mx="auto"
                        mt={-30}
                        className={classes.avatars}
                      >
                        {userDetail[0].charAt(0) + "" + userDetail[1].charAt(0)}
                      </Avatar>

                      <Text align="center" size="lg" weight={500} mt="sm">
                        {user.name}
                      </Text>
                      <Text align="center" size="sm" color="dimmed">
                        {" "}
                        😃 Approval's Role
                      </Text>
                      <Menu.Label>{new Date().toDateString()} </Menu.Label>

                      <Menu.Divider />
                      <Menu.Label>Profile</Menu.Label>
                      <Menu.Item
                        icon={<IconSettings size={14} />}
                        onClick={() => console.log("log")}
                      >
                        Settings
                      </Menu.Item>
                      <Menu.Item icon={<IconMessageCircle size={14} />}>
                        Notification
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Label>Switch-off</Menu.Label>

                      <Menu.Item
                        color="red"
                        icon={<IconLogout size={14} />}
                        onClick={Logout}
                      >
                        Sign-out
                      </Menu.Item>
                    </Card>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </div>
          </Header>
        </>
      }
    >
      <Container>
        {idle ? <>{navigate("/")}</> : null}
        <Card shadow="md" radius="md">
          {/* <TabsDashboard /> */}

          <main className="profile-page">
            <section className="relative block h-500-px">
              <div
                className="absolute top-0 w-full h-full bg-center bg-cover"
                style={{
                  backgroundImage:
                    'url("https://source.unsplash.com/wzVQp_NRIHg")',
                }}
              >
                <span
                  id="blackOverlay"
                  className="w-full h-full absolute opacity-50 bg-black"
                />
              </div>
              <div
                className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                style={{ transform: "translateZ(0px)" }}
              >
                <svg
                  className="absolute bottom-0 overflow-hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x={0}
                  y={0}
                >
                  <polygon
                    className="text-blueGray-200 fill-current"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="relative py-16 bg-blueGray-200">
              <div className="container mx-auto px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                  <div className="px-6">
                    <div className="flex flex-wrap justify-center">
                      <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                        <div className="relative">
                          <img
                            alt="..."
                            src={
                              user !== undefined
                                ? "data:image/png;base64," + user.pics
                                : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                            }
                            className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                            style={{
                              boxShadow: "#af8403 0px 0px 0px 5px ",
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                        <div className="py-6 px-3 mt-32 sm:mt-0">
                          {/* <button
                            className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                            type="button"
                          >
                            Connect
                          </button> */}
                          <Badge
                            variant="filled"
                            color="pink"
                            radius="md"
                            size="md"
                          >
                            {new Date().toDateString()}.{" "}
                          </Badge>
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4 lg:order-1">
                        <div className="flex justify-center py-4 lg:pt-4 pt-8">
                          <div className="mr-4 p-3 text-center">
                            <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                              22
                            </span>
                            <span className="text-sm text-blueGray-400">
                              Friends
                            </span>
                          </div>
                          <div className="mr-4 p-3 text-center">
                            <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                              10
                            </span>
                            <span className="text-sm text-blueGray-400">
                              Photos
                            </span>
                          </div>
                          <div className="lg:mr-4 p-3 text-center">
                            <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                              89
                            </span>
                            <span className="text-sm text-blueGray-400">
                              Comments
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-12">
                      <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                        {user.name}
                      </h3>
                      <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                        <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400" />
                        Lagos, Nigeria.
                      </div>
                      <div className="mb-2 text-blueGray-600 mt-15">
                        <i className="fas fa-user mr-2 text-lg text-blueGray-400" />
                        Approval's Role.
                      </div>
                      <div className="mb-2 text-blueGray-600">
                        <i className="fas fa-building mr-2 text-lg text-blueGray-400" />
                        First Registrars and Investor Services.
                      </div>
                    </div>
                    <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                      <div className="flex flex-wrap justify-center">
                        <div className="w-full lg:w-9/12 px-4">
                          <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                            You are welcome back. The data generated above,
                            however, is logged from the Microsoft Office 356.
                            Please log into office 356 account and make the
                            necessary changes if the records need to be
                            modified.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="relative bg-blueGray-200 pt-8 pb-6 mt-8">
                <div className="container mx-auto px-4">
                  <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-6/12 px-4 mx-auto text-center">
                      <div className="text-sm text-blueGray-500 font-semibold py-1"></div>
                    </div>
                  </div>
                </div>
              </footer>
            </section>
          </main>
        </Card>
      </Container>
    </AppShell>
  );
}
