import {
  ActionIcon,
  Divider,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Popover,
  TextInput,
  Text,
  Card,
  Title,
  Button as Buttons,
  SimpleGrid,
  Select as Selects,
  createStyles,
  Menu,
  UnstyledButton,
  Image,
  Box,
} from "@mantine/core";
import jsreport from "@jsreport/browser-client";
import {
  Button,
  List,
  Select,
  message,
  Upload,
  Steps,
  Table,
  Cascader,
  Input,
  Space,
  Popconfirm,
  DatePicker,
} from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import _, { set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddItemContext from "../../../AddItemsContext";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import {
  IconChartBar,
  IconChevronDown,
  IconSquareCheck,
  IconChevronsRight,
  IconSquareX,
  IconUsers,
  IconX,
  IconFileSpreadsheet,
  IconChevronsUp,
  IconArrowNarrowUp,
  IconArrowDownLeft,
  IconArrowUpRight,
} from "@tabler/icons";
import { useForm } from "@mantine/form";
import { Grid } from "@mantine/core";
import { Tabs } from "@mantine/core";
import {
  IconPhoto,
  IconMessageCircle,
  IconSettings,
  IconBasketFilled,
  IconTruckDelivery,
  IconPrinter,
} from "@tabler/icons-react";
import { useReactToPrint } from "react-to-print";
// import type { DatePickerProps } from "antd";
//const client = require("@jsreport/browser-client")("http://localhost:5488/");
//import jsreport from "@jsreport/browser-client";
//const myClass = await import("./MyClass.mjs");
//import * as jsreport from "@jsreport/browser-client";

//let jsreport.serverURL = "http://localhost:5488/";

import { SettingOutlined } from "@ant-design/icons";
//import jsreportInstance from "@jsreport/browser-client";
import { CSVLink, CSVDownload } from "react-csv";

const { Option } = Select;

const { Search } = Input;
const { RangePicker } = DatePicker;

let p;
let data = [];
let dataReport = [];

// interface DataType {
//   key: string;
//   items: string;
//   status: number;
//   createdAt: any;
// }

const useStyles = createStyles((theme, { opened }) => ({
  control: {
    width: 200,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,
    border: `${16} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: "background-color 150ms ease",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

const datas = [
  { label: "Filter Items supplied by Vendor Name" },
  { label: "Filter Items supplied by Item Name" },
  { label: "Filter Items supplied by Date Range" },
];

const dateFields = [
  { label: "Today" },
  { label: "Filter by Vendor Name" },
  { label: "Filter by Date" },
];

const reoderFeilds = [{ label: "Re-Order Level" }];

const requisitionFields = [
  { label: "Get All Items Request(s)" },
  { label: "Get Staff Requisitions by Item" },
  { label: "Get Requisitions by Staff Name" },
];

const stockFields = [
  { label: "Get All Stock Balance Analysis" },
  { label: "Filter by Item for Stock Balances" },
  { label: "Filter Now for In and Out Stock" },
  { label: "Filter by Date-Range for In and Out Stock" },
  { label: "Filter by item for In and Out Stock" },
];

//http://localhost:5488/

//console.log(client);

//const ComponentToPrint = React.forwardRef((props, ref) => {
const AdminReport = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [dataStockInOut, setDataStockInOut] = useState({});
  const [dataStockByDate, setDataStockByDate] = useState({});
  const [dataRequisition, setDataRequisition] = useState([]);

  const [dataStock, setDataStock] = useState({});
  const [dataGetReorder, setDataGetReorder] = useState([]);
  const [dataBalance, setDataBalance] = useState({});
  const [dataItems, setDataItems] = useState({});
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [shows, setShow] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showTableByItem, setShowTableByItem] = useState(false);
  const [showTableByDate, setShowTableByDate] = useState(false);
  const [showTableByNow, setShowTableByNow] = useState(false);
  const [showTableByStockItem, setShowTableByStockItem] = useState(false);
  const [showTableByAllStock, setShowTableByAllStock] = useState(false);
  const [showTableByAllRequisition, setShowTableByAllRequisition] =
    useState(false);
  const [showTableByItemMadeStaff, setShowTableByItemMadeStaff] =
    useState(false);
  const [showTableByStaffRequest, setShowTableByStaffRequest] = useState(false);
  useState(false);
  const [showTableByVendorRequestItem, setShowTableByVendorRequestItem] =
    useState(false);
  const [showTableByVendorName, setShowTableByVendorName] = useState(false);
  const [showTableByVendorDate, setShowTableByVendorDate] = useState(false);
  const [disabledDate, setDisabledDate] = useState(false);
  const [t, setT] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [searchButton, setSearchButton] = useState(false);
  const [counter, setCounter] = useState(0);
  const [values, setValues] = useState(0);

  //  const { addToItem, items } = useContext(AddItemContext);

  const [printHeaderVisible, setprintHeaderVisible] = useState(false);
  const componentRef = useRef();

  const [opened, setOpened] = useState(false);
  const [openedDate, setOpenedDate] = useState(false);
  const { classes } = useStyles({ opened });
  const [selected, setSelected] = useState(datas[0]);
  const [selectedDate, setSelectedDate] = useState(dateFields[0]);
  const [selectedStock, setSelectedStock] = useState(stockFields[0]);
  const [selectedReoder, setSelectedReorder] = useState(reoderFeilds[0]);
  const [selectedRequisition, setSelectedRequisition] = useState(
    requisitionFields[0]
  );
  const items = datas.map((item) => (
    <Menu.Item
      // icon={}
      onClick={() => setSelected(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  const itemRequisition = requisitionFields.map((item) => (
    <Menu.Item
      // icon={}
      onClick={() => setSelectedRequisition(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  const itemDate = dateFields.map((item) => (
    <Menu.Item
      // icon={}
      onClick={() => setSelectedDate(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  const itemStock = stockFields.map((item) => (
    <Menu.Item
      // icon={}
      onClick={() => setSelectedStock(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  const itemReorder = reoderFeilds.map((item) => (
    <Menu.Item
      // icon={}
      onClick={() => setSelectedReorder(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  let { user } = useSelector((state) => ({ ...state }));

  let { show } = useSelector((state) => ({ ...state }));

  const navigate = useNavigate();

  let { control_id } = useParams();

  const onchange = (e) => {
    console.log(e);
    if (e === "Date") {
      setShow(!false);
    }
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const selectBefore = (
    <Select defaultValue="http://" onChange={onchange}>
      <Option value="All Categeries">All Categeries</Option>
      <Option value="Items">Items</Option>
      <Option value="Date">Date</Option>
    </Select>
  );
  const selectAfter = (
    <Select defaultValue=".com">
      <Option value=".com">.com</Option>
      <Option value=".jp">.jp</Option>
      <Option value=".cn">.cn</Option>
      <Option value=".org">.org</Option>
    </Select>
  );

  const columnsReorder = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      responsive: ["lg"],
      render: (value, item, index) => (index === 0 ? index + 1 : index + 1),
    },
    {
      title: "Consumables Items",
      dataIndex: "items",
      key: "items",
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Reorder",
      dataIndex: "reorder",
      key: "reorder",
    },
  ];

  const columnsStockBalance = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      responsive: ["lg"],
      render: (value, item, index) => (index === 0 ? index + 1 : index + 1),
    },
    {
      title: "Consumables Items",
      dataIndex: "items",
      key: "items",
    },

    {
      title: "Stock_in",
      dataIndex: "stock_in",
      key: "stock_in",
    },

    {
      title: "Stock_Out",
      dataIndex: "stock_out",
      key: "stock_out",
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },

    {
      title: "Status",
      dataIndex: "status_qty",
      key: "status_qty",
      render: (value, item) =>
        value === false ? (
          <IconArrowUpRight color="green" />
        ) : value === true ? (
          <IconArrowDownLeft color="red" />
        ) : null,
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
  ];

  const columnsStockBalanceNow = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      responsive: ["lg"],
      render: (value, item, index) => (index === 0 ? index + 1 : index + 1),
    },
    {
      title: "Consumables Items",
      dataIndex: "items",
      key: "items",
    },

    {
      title: "Stock_in",
      dataIndex: "stock_in",
      key: "stock_in",
    },

    {
      title: "Stock_Out",
      dataIndex: "stock_out",
      key: "stock_out",
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },

    {
      title: "Reorder",
      dataIndex: "reorder",
      key: "reorder",
    },
  ];

  const columnsStockBalanceByItemName = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      responsive: ["lg"],
      render: (value, item, index) => (index === 0 ? index + 1 : index + 1),
    },
    {
      title: "Consumables Items",
      dataIndex: "items",
      key: "items",
    },

    {
      title: "Stock_in",
      dataIndex: "stock_in",
      key: "stock_in",
    },

    {
      title: "Stock_Out",
      dataIndex: "stock_out",
      key: "stock_out",
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
  ];

  const columnsRequisitionAll = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      responsive: ["lg"],
      render: (value, item, index) => (index === 0 ? index + 1 : index + 1),
    },
    {
      title: "Consumables Items",
      dataIndex: "items",
      key: "items",
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },

    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
    },

    {
      title: "Requester Name",
      dataIndex: "requester_name",
      key: "requester_name",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const columnsVendorDetails = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      responsive: ["lg"],
      render: (value, item, index) => (index === 0 ? index + 1 : index + 1),
    },
    {
      title: "Consumables Items",
      dataIndex: "items",
      key: "items",
    },

    {
      title: "Quantity Supplied",
      dataIndex: "quantity",
      key: "quantity",
    },

    {
      title: "Vendors Name",
      dataIndex: "vendorsname",
      key: "vendorsname",
    },

    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },

    {
      title: "Total Price",
      dataIndex: "totalprice",
      key: "totalprice",
    },

    {
      title: "Invoice Number",
      dataIndex: "invoicenumber",
      key: "invoicenumber",
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => {
        return <>{moment(date).format("MMMM Do YYYY")} </>;
      },
    },
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    onBeforeGetContent: () => setprintHeaderVisible(true),
  });

  const onSelectChange = (newSelectedRowKeys, index) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const loadDataFromDB = () => {
    data = [];

    fetch(`https://frisbackendurl.azurewebsites.net/requests/${control_id}`)
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value, index) => {
          // console.log(counter);
          data.push({
            key: value.uuid,
            items: value.items,
            quantity: value.quantity,
            control_number: value.control_number,
            status: value.status,
            button_ui: value.button_ui,
            counts_of_items: value.count_of_items,
            id: value.id,
          });
          setValues(Number(value.count_of_items));
        });
        setDataSource(data);
      })
      .finally(() => {
        //   setLoading(false);
      });
    //  };
  };

  const confirm = async (uuid) => {
    await axios
      .put(`https://frisbackendurl.azurewebsites.net/requisitions/${uuid}`, {
        status: "APPROVED",
      })
      .then(() => {
        setCounter((prevCount) => prevCount + 1);
        loadDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const saveLogs = async () => {
    console.log("Hello");
    await axios
      .put(
        `https://frisbackendurl.azurewebsites.net/requestlog/${control_id}`,
        {
          status: "TREATED",
        }
      )
      .then((item) => {
        //  setCounter((prevCount) => prevCount + 1);
        //  loadDataFromDB();
        console.log(item);
        message.success({
          content: "Request had been updated Successfully !",
          duration: 7,
        });
        navigate("/admin/approval/");
      });
  };

  useEffect(() => {
    loadDataFromDB();
  }, []);

  // const arrayFunction = (element) =>{

  // }

  // useEffect(() => {
  //   let a = _.every(dataSource, (element: any) => {
  //     console.log(element.button_ui);
  //   });

  //   //console.log(a);
  // });

  useEffect(() => {
    buttonFunction();
  }, []);

  const buttonFunction = () => {
    if (Number(values) && counter == Number(values)) {
      setButtonDisabled(false);
    } else if (_.every(dataSource, (element) => element.button_ui === true)) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validate: {
      name: (value) => value.trim().length < 2,
      email: (value) => !/^\S+@\S+$/.test(value),
      subject: (value) => value.trim().length === 0,
    },
  });

  if (selected.label === "German") {
    console.log("German !");
  } else if (selected.label === "English") {
    console.log("Biodun");
  }
  // console.log(jsreport:any );

  //jsreportInstance._normalizeUrl("http://localhost:5488/");

  //console.log(jsreport);

  const submitFunction = async () => {
    jsreport.serverUrl = "https://jsreport1.azurewebsites.net/";

    dataReport = [];

    // await fetch(`http://localhost:5000/consumbles`).then((res) => res.json()).then((res) => {
    //   // res.reorder.forEach((value, index) => {
    // })

    setSearchButton(true);
    setDataSource([]);

    await fetch(`https://frisbackendurl.azurewebsites.net/reorderlevel`)
      .then((res) => res.json())
      .then((res) => {
        setShowTable(true);
        //   dataReport.push({ reorder: res.reorder });
        // console.log(res.reorder);
        res.data.forEach((value, index) => {
          //   console.log(value);
          dataReport.push({
            key: value.uuid,
            // reorder: value.reorder,
            balance: value.balance,
            items: value.items,
            reorder: value.reorder,
            updatedAt: value.updatedAt,
            //   //   // totalprice: value.totalprice,
            //   //   //  button_ui: value.button_ui,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
          //   //   setValues(Number(value.count_of_items));
        });
        setDataGetReorder(dataReport);
        // setDataSource(res.reorder);
        setDataBalance(res.balance);
        setDataItems(res.items);
        message.loading("Please wait....", 6);
      })
      .finally(() => {
        //   setLoading(false);
        setTimeout(() => {
          setSearchButton(false);
        }, 5800);

        // setTimeout(() => {
        //   setShowTable(false);
        // }, 7800);
      });

    // console.log(dataSource);

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "Report",
          shortid: "-L5FPWu", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            reorder: dataSource,
            balance: dataBalance,
            items: dataItems,
            data: dataGetReorder,
          },
          //  data: { balance: dataBalance },

          content: "Re-Order Level",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "myreport" });
    }, 2000);

    // const client = require("@jsreport/browser-client")(
    //   "http://localhost:5488/"
    // );
  };

  const getAllStocks = async () => {
    jsreport.serverUrl = "https://jsreportmain.azurewebsites.net/";

    dataReport = [];

    setSearchButton(true);
    setShowTableByAllStock(true);

    await fetch(`https://frisbackendurl.azurewebsites.net/getstocksallbalance`)
      .then((res) => res.json())
      .then((res) => {
        //   dataReport.push({ reorder: res.reorder });
        // console.log(res.reorder);
        // res.reorder.forEach((value, index) => {
        //   console.log(value);
        //   // dataReport.push({
        //   //   // key: value.uuid,
        //   //   reorder: value,
        //   //   // balance: res.balance,
        //   //   // items: value.items,
        //   //   // price: value.totalprice,
        //   //   // vendorsname: value.vendorsname,
        //   //   // totalprice: value.totalprice,
        //   //   //  button_ui: value.button_ui,
        //   //   //  counts_of_items: value.count_of_items,
        //   //   //  id: value.id,
        //   // });
        //   //   setValues(Number(value.count_of_items));
        // });
        // setDataSource(res.reorder);
        setDataBalance(res.balance);
        setDataItems(res.items);
        // message.loading("Please wait....");
        message.loading("Please wait...", 10);

        console.log(dataBalance);
      })
      .finally(() => {
        //   setLoading(false);
      });

    await fetch(`https://frisbackendurl.azurewebsites.net/consumbles`)
      .then((res) => res.json())
      .then((res) => {
        //   dataReport.push({ reorder: res.reorder });
        // console.log(res.reorder);
        setShowTableByAllStock(true);
        res.data.forEach((value, index) => {
          //   console.log(value);
          dataReport.push({
            key: value.uuid,

            //quantity: value.inputtedquantity,
            items: value.items,
            reorder: value.reorder,
            stock_in: value.stock_in,
            stock_out: value.stock_out,
            balance: value.balance,
            updatedAt: value.updatedAt,
            //   //   // totalprice: value.totalprice,
            //   //   //  button_ui: value.button_ui,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
          //   //   setValues(Number(value.count_of_items));
        });
        setDataSource(dataReport);

        // console.l
        // setDataBalance(res.balance);
        // setDataItems(res.items);
      })
      .finally(() => {
        //   setLoading(false);

        setTimeout(() => {
          setSearchButton(false);
        }, 10000);
      });

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "PwnXSCA", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            balance: dataBalance,
            items: dataItems,
            data: dataSource,
          },
          //  data: { balance: dataBalance },

          content: "ALL STOCK ANALYSIS",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "myreport" });
    }, 2000);
  };

  const getStockByItem = async (value) => {
    jsreport.serverUrl = "https://jsreportmain.azurewebsites.net/";
    dataReport = [];
    setSearchButton(true);
    setShowTableByStockItem(true);

    await fetch(
      `https://frisbackendurl.azurewebsites.net/getstocksbyitems/${value}`
    )
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value, index) => {
          // dataReport = [];
          setShowTableByStockItem(true);

          console.log(value);

          dataReport.push({
            key: value.uuid,
            reorder: value.reorder,
            items: value.items,
            stock_in: value.stock_in,
            stock_out: value.stock_out,
            balance: value.balance,
            invoicenumber: value.invoicenumber,
            updatedAt: value.updatedAt,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
        });
        // setSearchButton(true);
        setDataSource(dataReport);
        message.loading("Please wait ... ");
        // console.log(res.data);
      })
      .finally(() => {
        //   setLoading(false);
        setTimeout(async () => {
          setSearchButton(false);

          const report = await jsreport.render({
            template: {
              name: "myTemplate",
              shortid: "yKGLLaT", //"H4IfdeX", ///"rkJTnK2ce",
              //  content: "Hello",
              //recipe: "phantom-pdf",
            },
            data: {
              data: {
                //  reorder: dataSource,
                // balance: dataBalance,
                //items: dataItems,
                data: dataSource,
              },
              //  data: { balance: dataBalance },

              content: "STOCK ANALYSIS",
            },
          });
          console.log(report);
          //  await report.download("myreport.pdf");

          await report.openInWindow({ title: "Requisition Report" });
        }, 2000);
      });
  };

  const getRequisitionByItem = async (data) => {
    jsreport.serverUrl = "http://localhost:5499/?id=12345";
    dataReport = [];
    setSearchButton(true);

    await fetch(
      `https://frisbackendurl.azurewebsites.net/getrequisitionbyitems/${data}`
    )
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value, index) => {
          // dataReport = [];

          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            items: value.items,
            manager: value.manager,
            status: value.status,
            requester: value.requester_name,
            remark: value.remarks,
            quantity: value.quantity,
            admin_status: value.admin_status,
            updatedAt: value.updatedAt,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
        });
        // setSearchButton(true);
        message.loading("Please wait ... ");
        console.log(res.data);
        setDataRequisition(dataReport);
      })
      .finally(() => {
        //   setLoading(false);
        setTimeout(async () => {
          setSearchButton(false);

          const report = await jsreport.render({
            template: {
              name: "myTemplate",
              shortid: "rkJTnK2ce", //"H4IfdeX", ///"rkJTnK2ce",
              //  content: "Hello",
              //recipe: "phantom-pdf",
            },
            data: {
              data: {
                //  reorder: dataSource,
                // balance: dataBalance,
                //items: dataItems,
                data: dataRequisition,
              },
              //  data: { balance: dataBalance },

              content: "REQUISITION REPORT",
            },
          });
          console.log(report);
          //  await report.download("myreport.pdf");

          await report.openInWindow({ title: "Requisition Report" });
        }, 2000);
      });
  };

  const geItemByVendorName = async (data) => {
    jsreport.serverUrl = "https://samplereport.azurewebsites.net";
    dataReport = [];
    setSearchButton(true);
    setDataSource([]);

    setShowTableByVendorName(true);

    await fetch(
      `https://frisbackendurl.azurewebsites.net/vendorsuppliedbyvendorname/${data}`
    )
      .then((res) => res.json())
      .then((res) => {
        setShowTableByVendorName(true);
        res.data.forEach((value, index) => {
          console.log(value);

          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            vendorsname: value.vendorsname,
            items: value.items,
            quantity: value.inputtedquantity,
            totalprice: value.totalprice,
            balance: value.balance,
            invoicenumber: value.invoicenumber,
            updatedAt: value.updatedAt,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
        });
        // setSearchButton(true);
        message.loading("Please wait ... ", 7);
        console.log(res.data);
        setDataRequisition(dataReport);
      })
      .finally(() => {
        setTimeout(() => {
          setSearchButton(false);
        }, 10000);
      });
    setTimeout(async () => {
      try {
        const report = await jsreport.render({
          template: {
            name: "myTemplate",
            shortid: "rkJTnK2ce", //"H4IfdeX", ///"rkJTnK2ce",
            //  content: "Hello",
            //recipe: "phantom-pdf",
          },
          data: {
            data: {
              //  reorder: dataSource,
              // balance: dataBalance,
              //items: dataItems,
              data: dataRequisition,
            },
            //  data: { balance: dataBalance },

            content: `GET REPORT ON SUPLLIER - ${moment(new Date()).format(
              "DD-MM-YYYY"
            )}`,
          },
        });
        console.log(report);
        //  await report.download("myreport.pdf");

        await report.openInWindow({
          title: `GET REPORT ON SUPLLIER -${moment(new Date()).format(
            "DD-MM-YYYY"
          )}`,
        });
      } catch (error) {
        setTimeout(() => {
          message.error("Try again later...", error);
        }, 2000);
      }
    }, 2000);
  };

  const geItemByItemName = async (data) => {
    jsreport.serverUrl = "https://samplereport.azurewebsites.net/?id=12345";
    dataReport = [];
    setSearchButton(true);
    setShowTableByVendorRequestItem(true);
    setDataSource([]);

    await fetch(
      `https://frisbackendurl.azurewebsites.net/vendorsuppliedbyitemname/${data}`
    )
      .then((res) => res.json())
      .then((res) => {
        setShowTableByVendorRequestItem(true);
        res.data.forEach((value, index) => {
          // dataReport = [];

          console.log(value);

          dataReport.push({
            key: value.uuid,
            vendorsname: value.vendorsname,
            items: value.items,
            quantity: value.inputtedquantity,
            totalprice: value.totalprice,
            balance: value.balance,
            invoicenumber: value.invoicenumber,
            updatedAt: value.updatedAt,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
        });
        // setSearchButton(true);
        message.loading("Please wait ... ", 8);
        console.log(res.data);
        setDataRequisition(dataReport);
      })
      .finally(() => {
        //   setLoading(false);
        setTimeout(() => {
          setSearchButton(false);
        }, 10000);
      });

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "24dlbho", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            // balance: dataBalance,
            //items: dataItems,
            data: dataRequisition,
          },
          //  data: { balance: dataBalance },

          content: `GET REPORT ON SUPLLIER - ${moment(new Date()).format(
            "DD-MM-YYYY"
          )}`,
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "Requisition Report" });
    }, 2000);
  };

  const getAllRequestionMade = async () => {
    jsreport.serverUrl = "https://samplereport.azurewebsites.net/?id=12345";

    dataReport = [];
    setSearchButton(true);
    setShowTableByAllRequisition(true);

    await fetch(`https://frisbackendurl.azurewebsites.net/requesteditemsmade`)
      .then((res) => res.json())
      .then((res) => {
        //   dataReport.push({ reorder: res.reorder });
        // console.log(res.reorder);
        setShowTableByAllRequisition(true);
        res.data.forEach((value, index) => {
          //   console.log(value);
          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            manager: value.manager,
            items: value.items,
            requester_name: value.requester_name,
            status: value.status,
            admin_status: value.admin_status,
            pickedStatus: value.pickedStatus,
            quantity: value.quantity,
            updatedAt: value.updatedAt,
          });
          //   //   setValues(Number(value.count_of_items));
        });
        setDataSource(dataReport);
        message.loading("Please wait ... ", 7);
        // console.l
        // setDataBalance(res.balance);
        // setDataItems(res.items);
      })
      .finally(() => {
        setTimeout(() => {
          setSearchButton(false);
        }, 10000);
      });

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "SKowzo_", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            // balance: dataBalance,
            // items: dataItems,
            data: dataSource,
          },
          //  data: { balance: dataBalance },

          content: "REQUISITION ANALYSIS",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "myreport" });
    }, 2000);
  };

  const getAllRequestionByItem = async (data) => {
    jsreport.serverUrl = "https://samplereport.azurewebsites.net/?id=12345";

    dataReport = [];
    setSearchButton(true);
    setShowTableByItemMadeStaff(true);

    await fetch(
      `https://firstregistrarsnigeria.com/frisportals/requesteditembyname/${data}`
    )
      .then((res) => res.json())
      .then((res) => {
        //   dataReport.push({ reorder: res.reorder });
        // console.log(res.reorder);
        setShowTableByItemMadeStaff(true);
        res.data.forEach((value, index) => {
          //   console.log(value);
          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            manager: value.manager,
            items: value.items,
            requester_name: value.requester_name,
            status: value.status,
            admin_status: value.admin_status,
            pickedStatus: value.pickedStatus,
            quantity: value.quantity,
            updatedAt: value.updatedAt,
          });
          //   //   setValues(Number(value.count_of_items));
        });
        setDataSource(dataReport);
        message.loading("Please wait ... ", 8);
        // console.l
        // setDataBalance(res.balance);
        // setDataItems(res.items);
      })
      .finally(() => {
        setTimeout(() => {
          setSearchButton(false);
        }, 10000);
      });

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "H4rT9ob", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            // balance: dataBalance,
            // items: dataItems,
            data: dataSource,
          },
          //  data: { balance: dataBalance },

          content: "REQUISITION ANALYSIS",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "myreport" });
    }, 2000);
  };

  const getAllRequestionByStaff = async (data) => {
    jsreport.serverUrl = "https://jsreportmain.azurewebsites.net/";

    dataReport = [];
    setSearchButton(true);

    //setShowTableByStaff(true);
    setShowTableByStaffRequest(true);

    await fetch(
      `https://firstregistrarsnigeria.com/frisportals/requesteditembyrequester/${data}`
    )
      .then((res) => res.json())
      .then((res) => {
        //   dataReport.push({ reorder: res.reorder });
        // console.log();
        // setShowTableByStaff(true);
        setShowTableByStaffRequest(true);
        res.data.forEach((value, index) => {
          //  console.log(value);
          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            manager: value.manager,
            items: value.items,
            requester_name: value.requester_name,
            status: value.status,
            admin_status: value.admin_status,
            pickedStatus: value.pickedStatus,
            quantity: value.quantity,
            updatedAt: value.updatedAt,
          });
          //   //   setValues(Number(value.count_of_items));
        });
        setDataSource(dataReport);
        message.loading("Please wait ... ", 8);
        // console.l
        // setDataBalance(res.balance);
        // setDataItems(res.items);
      })
      .finally(() => {
        setTimeout(() => {
          setSearchButton(false);
        }, 10000);
      });

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "rkJTnK2ce", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            // balance: dataBalance,
            // items: dataItems,
            data: dataSource,
          },
          //  data: { balance: dataBalance },

          content: "REQUISITION ANALYSIS",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "myreport" });
    }, 2000);
  };

  const geItemByDateRange = async (date) => {
    jsreport.serverUrl = "https://samplereport.azurewebsites.net/?id=12345";
    dataReport = [];
    // setSearchButton(true);
    setDataSource([]);

    setShowTableByVendorDate(true);

    if (date === null) {
      return null;
    }
    let startDate = moment(date[0].toString()).format("DD-MM-YYYY");

    let endDate = moment(date[1].toString()).format("DD-MM-YYYY");
    setDisabledDate(true);
    // console.log(date[1].toString());
    //  console.log(startDate);

    setDateStart(startDate);
    setDateEnd(moment(date[1].toString()).format("DD-MM-YYYY"));

    await fetch(
      `https://frisbackendurl.azurewebsites.net/vendorsuppliedbydate/?start=${date[0].toString()}&end=${date[1].toString()}`
    )
      .then((res) => res.json())
      .then((res) => {
        setShowTableByVendorDate(true);
        res.data.forEach((value, index) => {
          // dataReport = [];

          console.log(value);

          dataReport.push({
            key: value.uuid,
            vendorsname: value.vendorsname,
            items: value.items,
            quantity: value.inputtedquantity,
            totalprice: value.totalprice,
            balance: value.balance,
            invoicenumber: value.invoicenumber,
            updatedAt: value.updatedAt,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
        });
        // setSearchButton(true);
        message.loading("Please wait ... ", 7);
        console.log(res.data);
        setDataRequisition(dataReport);
      })
      .finally(() => {
        //   setLoading(false);
        setTimeout(() => {
          setDisabledDate(false);
        }, 10000);
      });
    setTimeout(async () => {
      // setSearchButton(false);

      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "UXXRW1o", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            // balance: dataBalance,
            //items: dataItems,
            data: dataRequisition,
          },
          //  data: { balance: dataBalance },

          content: "REQUISITION REPORT",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "Requisition Report" });
    }, 2000);
  };

  const getStockInOutByItem = async (value) => {
    dataReport = [];
    //setDataStockInOut({});
    jsreport.serverUrl = "https://jsreport1.azurewebsites.net/";

    await fetch(
      `https://frisbackendurl.azurewebsites.net/getstocksbalancebyitem/${value}`
    )
      .then((res) => res.json())
      .then((res) => {
        // setSearchButton(true);
        //  dataReport = [];
        res.data.forEach((value, index) => {
          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            balance: res.balance,
            items: value.items,
            stock_in: value.stock_in,
            stock_out: value.stock_out,
            balance: value.balance,
            requester: value.requester,
            inputtedquantity: value.inputtedquantity,
            status_qty: value.status_qty,
            updatedAt: moment(value.updatedAt).startOf("day").fromNow(),
          });
        });
        setDataStockInOut(dataReport);

        message.loading("Please wait ... ");
        // console.log(dataStockInOut);
      })
      .finally(() => {
        //   setLoading(false);
        setTimeout(() => {
          setSearchButton(false);
        }, 3000);
      });

    // setTimeout(async () => {
    //   const report = await jsreport.render({
    //     template: {
    //       name: "myTemplate",
    //       shortid: "rkJTnK2ce", //"H4IfdeX", ///"rkJTnK2ce",
    //       //  content: "Hello",
    //       //recipe: "phantom-pdf",
    //     },
    //     data: {
    //       data: {
    //         //  reorder: dataSource,
    //         // balance: dataBalance,
    //         // items: dataItems,
    //         data: dataStockInOut,
    //       },
    //       //  data: { balance: dataBalance },

    //       content: "Filter By Item for Stock In and Stock Out",
    //     },
    //   });
    //   console.log(report);
    //   //  await report.download("myreport.pdf");

    //   await report.openInWindow({ title: "myreport" });
    // }, 2000);
  };

  const getStockByDateRange = async (date) => {
    jsreport.serverUrl = "http://localhost:5499/?id=12345";
    dataReport = [];
    if (date === null) {
      return null;
    }
    let startDate = moment(date[0].toString()).format("DD-MM-YYYY");

    let endDate = moment(date[1].toString()).format("DD-MM-YYYY");
    // setDisabledDate(true);
    // console.log(date[1].toString());
    //  console.log(startDate);

    setDateStart(startDate);
    setDateEnd(moment(date[1].toString()).format("DD-MM-YYYY"));

    // await axios.get(
    //   `http://localhost:5000/getstocksbydate/?start=${startDate}&end=${endDate}`
    // ).then(res)

    await fetch(
      `https://frisbackendurl.azurewebsites.net/?start=${date[0].toString()}&end=${date[1].toString()}`
    )
      .then((res) => res.json())
      .then((res) => {
        //  console.log(res.data);
        res.data.forEach((value, index) => {
          // dataReport = [];

          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            balance: res.balance,
            items: value.items,
            stock_in: value.stock_in,
            stock_out: value.stock_out,
            status_qty: value.status_qty,
            balance: value.balance,
            requester: value.requester,
            updatedAt: value.updatedAt,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
        });
        setDataStockByDate(dataReport);
      })
      .finally(() => {});

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "B_4Mpxh", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            data: dataStockByDate,
          },
          //  data: { balance: dataBalance },

          content: "ALL CONSUMABLE",
        },
      });
      //console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "myreport" });
    }, 4000);
  };

  //getStockBalanceAtNow

  const getStockBalanceAtNow = async () => {
    jsreport.serverUrl = "https://jsreportmain.azurewebsites.net/";

    dataReport = [];
    setSearchButton(true);
    setShowTableByNow(true);

    await fetch(
      `https://frisbackendurl.azurewebsites.net/getstocksbalancebydate`
    )
      .then((res) => res.json())
      .then((res) => {
        //   dataReport.push({ reorder: res.reorder });
        // console.log(res.reorder);

        setShowTableByNow(true);
        res.data.forEach((value, index) => {
          //   console.log(value);
          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            manager: value.manager,
            items: value.items,
            requester_name: value.requester_name,
            status: value.status,
            reorder: value.reorder,
            status_qty: value.status_qty,
            inputtedquantity: value.inputtedquantity,
            stock_in: value.stock_in,
            stock_out: value.stock_out,
            balance: value.balance,
            updatedAt: value.updatedAt,
          });
          //   //   setValues(Number(value.count_of_items));
        });
        setDataSource(dataReport);
        message.loading("Please wait ... ", 5);
        // console.l
        // setDataBalance(res.balance);
        // setDataItems(res.items);
      })
      .finally(() => {
        setSearchButton(false);
      });

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "Ms9zjfm", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            // balance: dataBalance,
            // items: dataItems,
            data: dataSource,
          },
          //  data: { balance: dataBalance },

          content: "STOCK ANALYSIS",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "myreport" });
    }, 2000);
  };

  const getStockBalanceWithDateRange = async (date) => {
    jsreport.serverUrl = "https://jsreportmain.azurewebsites.net/";
    dataReport = [];
    // setSearchButton(true);

    setShowTableByDate(true);
    setShowTableByItem(false);

    if (date === null) {
      return null;
    }
    let startDate = moment(date[0].toString()).format("DD-MM-YYYY");

    let endDate = moment(date[1].toString()).format("DD-MM-YYYY");
    setDisabledDate(true);
    // console.log(date[1].toString());
    //  console.log(startDate);

    setDateStart(startDate);
    setDateEnd(moment(date[1].toString()).format("DD-MM-YYYY"));

    await fetch(
      `https://frisbackendurl.azurewebsites.net/getstocksbalancebydaterange/?start=${date[0].toString()}&end=${date[1].toString()}`
    )
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value, index) => {
          // dataReport = [];

          console.log(value);
          //  setShowTableByItem(true);
          setShowTableByDate(true);

          dataReport.push({
            key: value.uuid,
            vendorsname: value.vendorsname,
            items: value.items,
            quantity: value.inputtedquantity,
            status_qty: value.status_qty,
            stock_in: value.stock_in,
            balance: value.balance,
            stock_out: value.stock_out,
            updatedAt: moment(value.updatedAt).startOf("day").fromNow(),
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
        });
        // setSearchButton(true);
        message.loading("Please wait ... ", 8);
        console.log(res.data);
        setDataRequisition(dataReport);
      })
      .finally(() => {
        setTimeout(() => {
          setDisabledDate(false);
        }, 10000);
      });
    setTimeout(async () => {
      // setSearchButton(false);

      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "2RYbrOe", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            // balance: dataBalance,
            //items: dataItems,
            data: dataRequisition,
          },
          //  data: { balance: dataBalance },

          content: "STOCK ANALYSIS",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "Requisition Report" });
    }, 2000);
  };

  const getStocksBalanceByItem = async (value) => {
    dataReport = [];
    //setDataStockInOut({});
    jsreport.serverUrl = "https://jsreport1.azurewebsites.net/";

    setSearchButton(true);

    await fetch(
      `https://frisbackendurl.azurewebsites.net/getstocksbalancebyitembyinput/${value}`
    )
      .then((res) => res.json())
      .then((res) => {
        // setSearchButton(true);
        //  dataReport = [];
        setShowTableByItem(true);
        res.data.forEach((value, index) => {
          dataReport.push({
            key: value.uuid,
            //   //  reorder: value,
            balance: value.balance,
            items: value.items,
            stock_in: value.stock_in,
            stock_out: value.stock_out,
            balance: value.balance,
            requester: value.requester,
            inputtedquantity: value.inputtedquantity,
            status_qty: value.status_qty,
            updatedAt: moment(value.updatedAt).startOf("day").fromNow(),
            //   //   // totalprice: value.totalprice,
            //   //   //  button_ui: value.button_ui,
            //   //   //  counts_of_items: value.count_of_items,
            //   //   //  id: value.id,
          });
        });
        setDataStockInOut(dataReport);

        message.loading("Please wait ... ");
        // console.log(dataStockInOut);
      })
      .finally(() => {
        setSearchButton(false);
        //   setLoading(false);
        setTimeout(() => {
          setSearchButton(false);
        }, 2000);
      });

    setTimeout(async () => {
      const report = await jsreport.render({
        template: {
          name: "myTemplate",
          shortid: "c-fC2hC", //"H4IfdeX", ///"rkJTnK2ce",
          //  content: "Hello",
          //recipe: "phantom-pdf",
        },
        data: {
          data: {
            //  reorder: dataSource,
            // balance: dataBalance,
            // items: dataItems,
            data: dataStockInOut,
          },
          //  data: { balance: dataBalance },

          content: "Filter By Item for Stock In and Stock Out",
        },
      });
      console.log(report);
      //  await report.download("myreport.pdf");

      await report.openInWindow({ title: "myreport" });
    }, 2000);
  };

  return (
    <Paper
      shadow="md"
      radius="lg"
      p="lg"
      withBorder
      style={{ padding: "0px", margin: "30px" }}
    >
      <div className="removeMargin">
        <div className="firstHalf">
          <div
            style={{
              padding: 40,
              height: "auto",
            }}
          >
            <div>Report</div>
            <br />
            {/* {shows ? (
              <DatePicker onChange={onChange} />
            ) : (
              <Search
                addonBefore={selectBefore}
                //addonAfter={selectAfter}
                onSearch={(value: any) => {
                  console.log(value);
                }}
                defaultValue="mysite"
                // onChange={onchange}
              />
            )} */}

            <Tabs
              // color="orange"
              color="orange"
              radius="md"
              //   orientation="vertical"
              defaultValue="gallery"
            >
              <Tabs.List grow position="center">
                <Tabs.Tab value="gallery" icon={<IconChartBar size="1.0rem" />}>
                  Vendor's Reports
                </Tabs.Tab>
                <Tabs.Tab value="messages" icon={<IconUsers size="1.0rem" />}>
                  Requisition Reports
                </Tabs.Tab>
                <Tabs.Tab
                  value="settings"
                  icon={<IconBasketFilled size="1.0rem" />}
                >
                  Stocks Analysis Reports
                </Tabs.Tab>
                <Tabs.Tab
                  value="reorder"
                  icon={<IconTruckDelivery size="1.0rem" />}
                >
                  Re-order Reports
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="gallery" pl="xs">
                <br /> <br />
                <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
                  <Grid.Col span="auto">
                    <Menu
                      onOpen={() => setOpened(true)}
                      onClose={() => {
                        setOpened(false);
                        // setShowTableByAllRequisition(false);
                        setShowTableByVendorName(false);
                        setShowTableByVendorRequestItem(false);
                        setShowTableByVendorDate(false);
                        // setShowTableByItemMadeStaff(false);
                      }}
                      radius="md"
                      width="target"
                      withinPortal
                    >
                      <Menu.Target>
                        <Button
                          className={classes.control}
                          size="xl"
                          type="dashed"
                          style={{ width: 330, height: 45 }}

                          // onChange={(e) => {

                          // }}
                          //compact
                        >
                          <Group spacing="xs">
                            {/* <Image
                                src={selected.image}
                                width={22}
                                height={22}
                              /> */}
                            <span className={classes.label}>
                              {selected.label !== undefined
                                ? selected.label
                                : ""}
                            </span>
                          </Group>
                          <IconChevronDown
                            size="1rem"
                            className={classes.icon}
                            stroke={1.5}
                          />
                        </Button>
                      </Menu.Target>
                      <Menu.Dropdown>{items}</Menu.Dropdown>
                    </Menu>{" "}
                  </Grid.Col>
                  <Grid.Col md={12} lg={6}>
                    {selected.label ===
                    "Filter Items supplied by Vendor Name" ? (
                      // <Button
                      //   size="large"
                      //   type="primary"
                      //   onClick={submitFunction}
                      // >
                      //   {" "}
                      //   Submit{" "}
                      // </Button>

                      <Search
                        placeholder="Search by vendor name"
                        allowClear
                        enterButton="Search"
                        size="large"
                        loading={searchButton}
                        onSearch={geItemByVendorName}
                      />
                    ) : selected.label ===
                      "Filter Items supplied by Item Name" ? (
                      <Search
                        placeholder="Search by item name"
                        allowClear
                        enterButton="Search"
                        loading={searchButton}
                        size="large"
                        onSearch={geItemByItemName}
                      />
                    ) : selected.label ===
                      "Filter Items supplied by Date Range" ? (
                      <RangePicker
                        size="large"
                        format="DD-MM-YYYY"
                        disabled={disabledDate}
                        onChange={geItemByDateRange}
                      />
                    ) : null}
                  </Grid.Col>
                  <Grid.Col span={12}>
                    {showTableByVendorName ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Vendor's Details</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => setShowTableByVendorName(false)}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                            <Menu.Item>Download zip</Menu.Item>
                            <Menu.Item>Preview all</Menu.Item>
                            <Menu.Item color="red">Delete all</Menu.Item>
                          </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                      style={{ marginTop: 10 }}
                      // color="red"
                      //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                    > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataRequisition}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsVendorDetails}
                          dataSource={dataRequisition}
                          bordered
                        />
                      </Card>
                    ) : showTableByVendorRequestItem ? (
                      <>
                        {" "}
                        <Card
                          withBorder
                          shadow="sm"
                          radius="md"
                          style={{ transition: `width 2s` }}
                        >
                          <Card.Section withBorder inheritPadding py="xs">
                            <Group position="apart">
                              <Text weight={500}>Stocks</Text>
                              <Menu
                                withinPortal
                                position="bottom-end"
                                shadow="sm"
                              >
                                {/* <Menu.Target> */}
                                <ActionIcon
                                  onClick={() =>
                                    setShowTableByVendorRequestItem(false)
                                  }
                                >
                                  <IconX size={16} />
                                </ActionIcon>
                                {/* </Menu.Target> */}

                                {/* <Menu.Dropdown>
                              <Menu.Item>Download zip</Menu.Item>
                              <Menu.Item>Preview all</Menu.Item>
                              <Menu.Item color="red">Delete all</Menu.Item>
                            </Menu.Dropdown> */}
                              </Menu>
                            </Group>
                          </Card.Section>
                          {/* <Button
                        style={{ marginTop: 10 }}
                        // color="red"
                        //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                      > */}
                          <br />
                          <CSVLink
                            filename={"StockBalance.csv"}
                            // data={dataRequisition}
                            style={{ marginTop: 15, top: 20 }}
                            className="button-34"
                            // className="btn btn-primary"
                            onClick={() => {
                              message.success("The file is downloading");
                            }}
                          >
                            Export to CSV
                          </CSVLink>{" "}
                          {/* Download AS CSV */}
                          {/* </Button> */}
                          <Table
                            style={{ marginTop: 25 }}
                            columns={columnsVendorDetails}
                            dataSource={dataRequisition}
                            bordered
                          />
                        </Card>{" "}
                      </>
                    ) : showTableByVendorDate ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Vendors Details</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => setShowTableByNow(false)}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                              <Menu.Item>Download zip</Menu.Item>
                              <Menu.Item>Preview all</Menu.Item>
                              <Menu.Item color="red">Delete all</Menu.Item>
                            </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                        style={{ marginTop: 10 }}
                        // color="red"
                        //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                      > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataRequisition}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>{" "}
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsVendorDetails}
                          dataSource={dataRequisition}
                          bordered
                        />
                      </Card>
                    ) : null}
                  </Grid.Col>
                </Grid>
              </Tabs.Panel>

              <Tabs.Panel value="messages" pl="xs">
                <br /> <br />
                <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
                  <Grid.Col span="auto">
                    <Menu
                      onOpen={() => setOpened(true)}
                      onClose={() => {
                        setOpened(false);
                        setShowTableByAllRequisition(false);
                        setShowTableByItemMadeStaff(false);
                      }}
                      radius="md"
                      width="target"
                      withinPortal
                    >
                      <Menu.Target>
                        <Button
                          className={classes.control}
                          size="xl"
                          type="dashed"
                          style={{ width: 330, height: 45 }}
                          // onChange={(e) => {

                          // }}
                          //compact
                        >
                          <Group spacing="xs">
                            {/* <Image
                                src={selected.image}
                                width={22}
                                height={22}
                              /> */}
                            <span className={classes.label}>
                              {selectedRequisition.label !== undefined
                                ? selectedRequisition.label
                                : ""}
                            </span>
                          </Group>
                          <IconChevronsUp
                            size="1rem"
                            className={classes.icon}
                            color="#4096ff"
                            stroke={1.5}
                          />
                        </Button>
                      </Menu.Target>
                      <Menu.Dropdown>{itemRequisition}</Menu.Dropdown>
                    </Menu>{" "}
                  </Grid.Col>
                  {/* Requsuition */}
                  <Grid.Col md={12} lg={6}>
                    {selectedRequisition.label ===
                    "Get All Items Request(s)" ? (
                      <Button
                        size="large"
                        type="primary"
                        onClick={getAllRequestionMade}
                        loading={searchButton}
                      >
                        {" "}
                        Submit{" "}
                      </Button>
                    ) : selectedRequisition.label ===
                      "Get Staff Requisitions by Item" ? (
                      <Search
                        placeholder="Search by item"
                        allowClear
                        enterButton="Search"
                        size="large"
                        loading={searchButton}
                        onSearch={getAllRequestionByItem}
                      />
                    ) : selectedRequisition.label ===
                      "Get Requisitions by Staff Name" ? (
                      <Search
                        placeholder="Search by Staff Name"
                        allowClear
                        enterButton="Search"
                        size="large"
                        loading={searchButton}
                        onSearch={getAllRequestionByStaff}
                      />
                    ) : selectedStock.label ===
                      "Filter by Date-Range for In and Out Stock" ? (
                      // <Button size="large" type="primary">
                      //   Submit
                      // </Button>
                      <RangePicker
                        size="large"
                        format="DD-MM-YYYY"
                        disabled={disabledDate}
                        onChange={getStockByDateRange}
                      />
                    ) : selectedStock.label ===
                      "Filter by item for In and Out Stock" ? (
                      <Search
                        placeholder="input item"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={getStockInOutByItem}
                      />
                    ) : null}
                  </Grid.Col>
                  <Grid.Col span={12}>
                    {showTableByAllRequisition ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Table Format</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => {
                                  setShowTableByAllRequisition(false);
                                  setShowTableByItemMadeStaff(false);
                                }}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataSource !== undefined ? dataSource : {}}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsRequisitionAll}
                          dataSource={dataSource}
                          bordered
                        />
                      </Card>
                    ) : showTableByItemMadeStaff ? (
                      <>
                        {" "}
                        <Card
                          withBorder
                          shadow="sm"
                          radius="md"
                          style={{ transition: `width 2s` }}
                        >
                          <Card.Section withBorder inheritPadding py="xs">
                            <Group position="apart">
                              <Text weight={500}>Stocks</Text>
                              <Menu
                                withinPortal
                                position="bottom-end"
                                shadow="sm"
                              >
                                {/* <Menu.Target> */}
                                <ActionIcon
                                  onClick={() => setShowTableByNow(false)}
                                >
                                  <IconX size={16} />
                                </ActionIcon>
                                {/* </Menu.Target> */}

                                {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                              </Menu>
                            </Group>
                          </Card.Section>
                          {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                          <br />
                          <CSVLink
                            filename={"StockBalance.csv"}
                            data={dataSource}
                            style={{ marginTop: 15, top: 20 }}
                            className="button-34"
                            // className="btn btn-primary"
                            onClick={() => {
                              message.success("The file is downloading");
                            }}
                          >
                            Export to CSV
                          </CSVLink>{" "}
                          {/* Download AS CSV */}
                          {/* </Button> */}
                          <Table
                            style={{ marginTop: 25 }}
                            columns={columnsRequisitionAll}
                            dataSource={dataSource}
                            bordered
                          />
                        </Card>{" "}
                      </>
                    ) : showTableByStaffRequest ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Stocks</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() =>
                                  setShowTableByStaffRequest(false)
                                }
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataSource}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>{" "}
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsRequisitionAll}
                          dataSource={dataSource}
                          bordered
                        />
                      </Card>
                    ) : showTableByStockItem ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Stocks</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => setShowTableByStockItem(false)}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataSource}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>{" "}
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsStockBalanceByItemName}
                          dataSource={dataSource}
                          bordered
                        />
                      </Card>
                    ) : showTableByAllStock ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Stocks</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => setShowTableByAllStock(false)}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataSource}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>{" "}
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsStockBalanceByItemName}
                          dataSource={dataSource}
                          bordered
                        />
                      </Card>
                    ) : null}
                  </Grid.Col>
                </Grid>
              </Tabs.Panel>

              <Tabs.Panel value="settings" pl="xs">
                <br /> <br />
                <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
                  <Grid.Col span="auto">
                    <Menu
                      onOpen={() => {
                        setOpened(true);
                      }}
                      onClose={() => {
                        setOpened(false);
                        setShowTableByItem(false);
                        setShowTableByDate(false);
                        setShowTableByNow(false);
                        setShowTableByStockItem(false);
                        setShowTableByAllStock(false);
                      }}
                      radius="md"
                      width="target"
                      withinPortal
                    >
                      <Menu.Target>
                        <Button
                          className={classes.control}
                          size="xl"
                          type="dashed"
                          style={{ width: 330, height: 45 }}
                          // onChange={(e) => {

                          // }}
                          //compact
                        >
                          <Group spacing="xs">
                            {/* <Image
                                src={selected.image}
                                width={22}
                                height={22}
                              /> */}
                            <span className={classes.label}>
                              {selectedStock.label !== undefined
                                ? selectedStock.label
                                : ""}
                            </span>
                          </Group>
                          <IconChevronsUp
                            size="1rem"
                            className={classes.icon}
                            color="#4096ff"
                            stroke={1.5}
                          />
                        </Button>
                      </Menu.Target>
                      <Menu.Dropdown>{itemStock}</Menu.Dropdown>
                    </Menu>{" "}
                  </Grid.Col>
                  <Grid.Col md={12} lg={6}>
                    {selectedStock.label ===
                    "Get All Stock Balance Analysis" ? (
                      <Button
                        size="large"
                        loading={searchButton}
                        type="primary"
                        onClick={getAllStocks}
                      >
                        {" "}
                        Submit{" "}
                      </Button>
                    ) : selectedStock.label ===
                      "Filter by Item for Stock Balances" ? (
                      <Search
                        placeholder="input search text"
                        allowClear
                        enterButton="Search"
                        size="large"
                        loading={searchButton}
                        onSearch={getStockByItem}
                      />
                    ) : selectedStock.label ===
                      "Filter Now for In and Out Stock" ? (
                      <Button
                        size="large"
                        type="primary"
                        loading={searchButton}
                        onClick={getStockBalanceAtNow}
                      >
                        Submit
                      </Button>
                    ) : selectedStock.label ===
                      "Filter by Date-Range for In and Out Stock" ? (
                      // <Button size="large" type="primary">
                      //   Submit
                      // </Button>
                      <RangePicker
                        size="large"
                        format="DD-MM-YYYY"
                        disabled={disabledDate}
                        onChange={getStockBalanceWithDateRange}
                      />
                    ) : selectedStock.label ===
                      "Filter by item for In and Out Stock" ? (
                      <Search
                        placeholder="Search with Items..."
                        allowClear
                        enterButton="Search"
                        size="large"
                        loading={searchButton}
                        onSearch={getStocksBalanceByItem}
                      />
                    ) : null}
                  </Grid.Col>
                  <Grid.Col span={12}>
                    {showTableByItem ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Table Format</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => setShowTableByItem(false)}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />
                        {/* <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataStockInOut}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink> */}
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsStockBalance}
                          dataSource={dataStockInOut}
                          bordered
                        />
                      </Card>
                    ) : showTableByDate ? (
                      <>
                        {" "}
                        <Card
                          withBorder
                          shadow="sm"
                          radius="md"
                          style={{ transition: `width 2s` }}
                        >
                          <Card.Section withBorder inheritPadding py="xs">
                            <Group position="apart">
                              <Text weight={500}>Stocks</Text>
                              <Menu
                                withinPortal
                                position="bottom-end"
                                shadow="sm"
                              >
                                {/* <Menu.Target> */}
                                <ActionIcon
                                  onClick={() => setShowTableByDate(false)}
                                >
                                  <IconX size={16} />
                                </ActionIcon>
                                {/* </Menu.Target> */}

                                {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                              </Menu>
                            </Group>
                          </Card.Section>
                          {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                          <br />
                          <CSVLink
                            filename={"StockBalance.csv"}
                            data={dataRequisition}
                            style={{ marginTop: 15, top: 20 }}
                            className="button-34"
                            // className="btn btn-primary"
                            onClick={() => {
                              message.success("The file is downloading");
                            }}
                          >
                            Export to CSV
                          </CSVLink>{" "}
                          {/* Download AS CSV */}
                          {/* </Button> */}
                          <Table
                            style={{ marginTop: 25 }}
                            columns={columnsStockBalance}
                            dataSource={dataRequisition}
                            bordered
                          />
                        </Card>{" "}
                      </>
                    ) : showTableByNow ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Stocks</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => setShowTableByNow(false)}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataSource}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>{" "}
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsStockBalanceNow}
                          dataSource={dataSource}
                          bordered
                        />
                      </Card>
                    ) : showTableByStockItem ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Stocks</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => setShowTableByStockItem(false)}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataSource}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>{" "}
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsStockBalanceByItemName}
                          dataSource={dataSource}
                          bordered
                        />
                      </Card>
                    ) : showTableByAllStock ? (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Stocks</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon
                                onClick={() => setShowTableByAllStock(false)}
                              >
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />
                        <CSVLink
                          filename={"StockBalance.csv"}
                          data={dataSource}
                          style={{ marginTop: 15, top: 20 }}
                          className="button-34"
                          // className="btn btn-primary"
                          onClick={() => {
                            message.success("The file is downloading");
                          }}
                        >
                          Export to CSV
                        </CSVLink>{" "}
                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <Table
                          style={{ marginTop: 25 }}
                          columns={columnsStockBalanceByItemName}
                          dataSource={dataSource}
                          bordered
                        />
                      </Card>
                    ) : null}
                  </Grid.Col>
                </Grid>
              </Tabs.Panel>

              <Tabs.Panel value="reorder" pl="xs">
                <br /> <br />
                <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
                  <Grid.Col span="auto">
                    <Menu
                      onOpen={() => setOpened(true)}
                      onClose={() => setOpened(false)}
                      radius="md"
                      width="target"
                      withinPortal
                    >
                      <Menu.Target>
                        <Button
                          className={classes.control}
                          size="xl"
                          style={{ width: 330, height: 45 }}
                          type="dashed"

                          // onChange={(e) => {

                          // }}
                          //compact
                        >
                          <Group spacing="xs">
                            {/* <Image
                                src={selected.image}
                                width={22}
                                height={22}
                              /> */}
                            <span className={classes.label}>
                              {selectedReoder.label !== undefined
                                ? selectedReoder.label
                                : ""}
                            </span>
                          </Group>
                          <IconChevronsRight
                            size="1rem"
                            color="#4096ff"
                            className={classes.icon}
                            stroke={1.5}
                          />
                        </Button>
                      </Menu.Target>
                      <Menu.Dropdown>{itemReorder}</Menu.Dropdown>
                    </Menu>{" "}
                  </Grid.Col>
                  <Grid.Col span={6}>
                    {selectedReoder.label === "Re-Order Level" ? (
                      <>
                        <Button
                          size="large"
                          type="primary"
                          loading={searchButton}
                          onClick={submitFunction}
                        >
                          {" "}
                          Submit{" "}
                        </Button>
                      </>
                    ) : selectedReoder.label === "Filter by Vendor Name" ? (
                      <Search
                        placeholder="input search text"
                        allowClear
                        enterButton="Search"
                        size="large"
                        //onSearch={onSearch}
                      />
                    ) : selectedReoder.label === "Filter by Date" ? (
                      <Menu
                        onOpen={() => setOpenedDate(true)}
                        onClose={() => setOpenedDate(false)}
                        radius="md"
                        width="target"
                        withinPortal
                      >
                        <Menu.Target>
                          <Button
                            className={classes.control}
                            // onChange={(e) => {

                            size="large"

                            // }}
                            //compact
                          >
                            <Group spacing="xs">
                              {/* <Image
                                src={selected.image}
                                width={22}
                                height={22}
                              /> */}
                              <span className={classes.label}>
                                {selectedDate.label !== undefined
                                  ? selectedDate.label
                                  : ""}
                              </span>
                            </Group>
                            <IconChevronDown
                              size="1rem"
                              className={classes.icon}
                              stroke={1.5}
                            />
                          </Button>
                        </Menu.Target>
                        <Menu.Dropdown>{itemDate}</Menu.Dropdown>
                      </Menu>
                    ) : null}
                  </Grid.Col>
                  <Grid.Col span={12}>
                    {" "}
                    {showTable && (
                      <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        style={{ transition: `width 2s` }}
                      >
                        <Card.Section withBorder inheritPadding py="xs">
                          <Group position="apart">
                            <Text weight={500}>Table Format</Text>
                            <Menu
                              withinPortal
                              position="bottom-end"
                              shadow="sm"
                            >
                              {/* <Menu.Target> */}
                              <ActionIcon onClick={() => setShowTable(false)}>
                                <IconX size={16} />
                              </ActionIcon>
                              {/* </Menu.Target> */}

                              {/* <Menu.Dropdown>
                                <Menu.Item>Download zip</Menu.Item>
                                <Menu.Item>Preview all</Menu.Item>
                                <Menu.Item color="red">Delete all</Menu.Item>
                              </Menu.Dropdown> */}
                            </Menu>
                          </Group>
                        </Card.Section>
                        {/* <Button
                          style={{ marginTop: 10 }}
                          // color="red"
                          //  leftIcon={<IconFileSpreadsheet size="1rem" />}
                        > */}
                        <br />

                        <Space size="large" className="flex justify-between">
                          <CSVLink
                            filename={"Re-Order.csv"}
                            data={dataGetReorder}
                            style={{ marginTop: 15, top: 20 }}
                            className="button-34"
                            // className="btn btn-primary"
                            onClick={() => {
                              message.success("The file is downloading");
                            }}
                          >
                            Export to CSV
                          </CSVLink>
                          <Button
                            icon={<IconPrinter size="large" />}
                            onClick={handlePrint}
                          />
                        </Space>

                        {/* Download AS CSV */}
                        {/* </Button> */}
                        <div ref={componentRef}>
                          <Space className="pl-2 flex justify-between">
                            <Image
                              src="https://i.ibb.co/ZgNdCXJ/logo.png"
                              width={90}
                              className="mt-3"
                            />
                            <Text className="text-gray-400 mt-3">
                              {moment().format("MMMM Do YYYY, h:mm:ss a")}
                            </Text>
                          </Space>

                          <Table
                            style={{ marginTop: 25 }}
                            columns={columnsReorder}
                            dataSource={dataGetReorder}
                            bordered
                            //  ref={componentRef}
                          />
                        </div>
                      </Card>
                    )}
                  </Grid.Col>
                </Grid>
              </Tabs.Panel>
            </Tabs>
          </div>
        </div>

        <div className="secondHalf">
          <div className="custom-shape-divider-bottom-1671865759">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fills"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </Paper>
  );
});

export default AdminReport;
