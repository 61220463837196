import { Children, createContext, useState } from "react";

const AddItemContext = createContext();

export function AddItemProvider({ children }) {
  const [items, setItem] = useState([]);

  const addToItem = (item, quantity) => {
    setItem((preState) => [...preState, { item, quantity }]);
    console.log(items);
  };
  return (
    <AddItemContext.Provider value={{ items, addToItem }}>
      {children}
    </AddItemContext.Provider>
  );
}

export default AddItemContext;
