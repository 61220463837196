import { nanoid } from "nanoid";

export function showReducer(state: any = false, action: any) {
  switch (action.type) {
    case "LOGGED_STATUS":
      return action.payload;
    default:
      return state;
  }
}

// const initialState = {
//   user: {},
//   show: false,
// };

// export function userReducer(state: any = initialState, action: any) {
//   switch (action.type) {
//     case "LOGGED_IN_USER":
//       return {
//         ...state,
//         user: action.payload,
//       };
//     case "LOGGED_STATUS":
//       return {
//         ...state,
//         user: action.payload,
//       };
//     case "LOGGED_STATUS":
//       return {
//         ...state,
//         show: action.payload,
//       };
//     case "LOGGED_STATUS":
//       return {
//         ...state,
//         user: action.payload,
//       };
//     case "LOGOUT":
//       return {
//         ...state,
//         user: action.payload,
//       };
//     default:
//       return state;
//   }
// }
