import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { showReducer } from "./showReducer";

const rootReducer = combineReducers({
  user: userReducer,
  show: showReducer,
});

export default rootReducer;
