import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useDisclosure } from "@mantine/hooks";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Grid,
  SimpleGrid,
  Skeleton,
  Card,
  Anchor,
  Breadcrumbs,
  Indicator,
  Box,
  Progress,
  ThemeIcon,
  Loader,
  Center,
  Badge,
  NumberInputHandlers,
  MultiSelect,
  NumberInput,
  Button as Buttons,
  Drawer,
  Input as Inputs,
  Divider,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button, List, Popconfirm, Select, Table, Input } from "antd";
import { useScrollIntoView, useWindowScroll } from "@mantine/hooks";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { nanoid } from "nanoid";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import CountUp from "react-countup";
import {
  IconSettings,
  IconSquareCheck,
  IconSquareX,
  IconSum,
  IconTrash,
} from "@tabler/icons";

const { Dragger } = Upload;
const { Search } = Input;

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  description: {
    maxWidth: 650,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,

    // "&:hover": {
    //   transform: "scale(0.11)",
    //   boxShadow: theme.shadows.md,
    // },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  item: {
    "& + &": {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  title: {
    lineHeight: 1,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface DataType {
  key: string;
  items: string;
  status: number;
  createdAt: any;
}

let getLocalStorageItems: any = localStorage.getItem("Items");

let data: any = [];
let datas: any = [];

const ConsumblesLogs = () => {
  const [scroll, scrollTo] = useWindowScroll();
  const [display, setDisplay] = useState(false);
  const [openeds, handler] = useDisclosure(false);
  const [opens, setOpen] = useState(false);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [a, seta] = useState([]);
  const [opened, setOpened] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [shows, setShows] = useState(false);
  const [c, setC] = useState([]);
  const [searchValues, setSearchValues] = useState("");
  const [values, setValues] = useState(0);
  //const [items, setItems] = useState(null);
  const handlers: any = useRef<NumberInputHandlers>(null);
  const previousValue: any = useRef(null);

  let { user } = useSelector((state: any) => ({ ...state }));

  const form = useForm<{ quantity: number | undefined }>({
    initialValues: { quantity: undefined },
    validate: (values) => ({
      //name: values.name.length < 2 ? "Too short name" : null,
      quantity: values.quantity === undefined ? "Quantity is required" : null,
    }),
  });
  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      responsive: ["lg"],
      render: (value: any, item: any, index: any) =>
        index === 0 ? index + 1 : index + 1,
    },
    {
      title: "Consumables Items",
      dataIndex: "items",
      key: "items",
      render: (items: any, record: any) => {
        // console.log(record);

        return (
          <span className="fw-bolder">
            <Link to={"/admin/consumables/add/" + record.uuid}>
              {" "}
              {/* <Buttons
              variant="subtle"
              onClick={async () => {
                // await handler.open();
                datas = [];
                setOpened(true);
                // if (datas === null) {
                await loadDataFromDB(record.uuid);
                // }
              }}
            > */}
              <strong> {items} </strong>
              {/* </Buttons> */}
            </Link>
          </span>
        );
      },
    },
    {
      title: "Stocks (IN)",
      dataIndex: "stock_in",
      key: "stock_in",
      filteredValue: [searchValues],
      onFilter(value: any, record: any) {
        return (
          String(record.items).toLowerCase().includes(value.toLowerCase()) ||
          String(record.createdAt).toLowerCase().includes(value.toLowerCase())
        );
      },
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Stocks (OUT)",
      dataIndex: "stock_out",
      key: "stock_out",
    },

    {
      title: "Stocks (Balance)",
      dataIndex: "balance",
      key: "balance",
    },

    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => {
        return <Box> {moment(date).format("MMMM Do YYYY")} </Box>;
      },
    },

    {
      title: "Action(s)",
      dataIndex: "",
      key: "uuid",
      render: (items: any, record: any) => {
        // console.log(record);
        return (
          <ActionIcon onClick={() => deleteItems(items.uuid)}>
            <IconTrash color="red" />
          </ActionIcon>
        );
      },
    },
  ];

  // let control_id = window.location.href;

  // console.log(data);
  //datas = [];

  const loadDataFromDB = async (uuid: any) => {
    // await handler.close();
    datas = [];

    // setOpen(false);

    try {
      datas = [];
      //await handler.close();
      await fetch(`https://frisbackendurl.azurewebsites.net/consumbles/${uuid}`)
        .then((res) => res.json())
        .then((res) => {
          res.data.forEach(async (value: any, index: any) => {
            //await handler.close();
            setOpen(true);
            await datas.push({
              key: value.uuid,
              items: value.items,
              stock_in: value.stock_in,
              id: value.uuid,
            });
            setDataDetail([]);
            setDataDetail(datas);
            setValues(Number(value.count_of_items));
          });

          //setDataSource(datas);
        })
        .finally(() => {
          //   setLoading(false);
          handler.close();
        });
    } catch (error) {}
  };

  const LoadsDataFromDB = () => {
    setLoading(true);
    data = [];
    fetch(`https://frisbackendurl.azurewebsites.net/consumbles`)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.data);
        // setOptions(res.data);
        setLoading(true);

        res.data.forEach((value: any, index: any) => {
          data.push({
            key: nanoid(),
            items: value.items,
            stock_in: value.stock_in,
            stock_out: value.stock_out,
            balance: value.balance,
            uuid: value.uuid,
            createdAt: value.createdAt,
          });
        });
        setDataSource(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteItems = async (uuid: any) => {
    // await handler.close();

    console.log(uuid);
    datas = [];

    // setOpen(false);

    try {
      datas = [];
      //await handler.close();

      await axios
        .delete(
          `https://frisbackendurl.azurewebsites.net/consumables/delete/${uuid}`
          // {
          //   status: "APPROVED",
          // }
        )
        .then((data: any) => {
          console.log(data);
          LoadsDataFromDB();
          message.success("Item deleted successfully");
        });

      //  await fetch(`http://localhost:5000/consumbles/delete/${uuid}`)
      //    .then((res) => res.json())
      //    .then((res) => {
      //      res.data.forEach(async (value: any, index: any) => {
      //        //await handler.close();
      //        setOpen(true);
      //        await datas.push({
      //          key: value.uuid,
      //          items: value.items,
      //          stock_in: value.stock_in,
      //          id: value.uuid,
      //        });
      //        setDataDetail([]);
      //        setDataDetail(datas);
      //        setValues(Number(value.count_of_items));
      //      });

      //      //setDataSource(datas);
      //    })
      //    .finally(() => {
      //      //   setLoading(false);
      //      handler.close();
      //    });
    } catch (error) {}

    //  };
  };

  useEffect(() => {
    LoadsDataFromDB();
  }, []);

  const confirm = async (uuid: any) => {
    await axios
      .put(`https://frisbackendurl.azurewebsites.net/requisitions/${uuid}`, {
        status: "APPROVED",
      })
      .then(() => {
        LoadsDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const items = dataDetail.map((item: any, index: any) => (
    <form
      onSubmit={form.onSubmit(async (values) => {
        //  console.log(values.quantity, item.id);
        setLoadings(true);
        await axios
          .put(
            `https://frisbackendurl.azurewebsites.net/consumables/edit/${item.id}`,
            {
              quantity: values.quantity,
            }
          )
          .then(() => {
            // setLoadings(true);
            loadDataFromDB(item.id);
            LoadsDataFromDB();
            form.reset();
            message.success({
              content: "Operation is Successfully !",
              duration: 7,
            });
          })
          .catch(() => {
            setLoadings(false);
          });

        setLoadings(false);

        form.reset();
      })}
    >
      <Group position="apart" className={classes.item} noWrap spacing="md">
        <div>
          <Text>
            {index === 0 ? index + 1 : index + 1}. {item.items}
          </Text>
        </div>

        <Badge color="orange" radius="xs" size="lg">
          <CountUp start={0} end={item.stock_in}></CountUp>
        </Badge>

        <NumberInput
          size="xs"
          defaultValue={0}
          placeholder=""
          description="Add the quantity values."
          label="Quantity"
          withAsterisk
          {...form.getInputProps("quantity")}
        />
        <Buttons loading={loadings} type="submit">
          Submit
        </Buttons>
        <LoadingOverlay
          // visible={openeds}
          transitionDuration={0}
          visible={false}
          overlayBlur={2}
        />
      </Group>
    </form>
  ));

  return (
    <>
      <SimpleGrid
        cols={1}
        spacing="md"
        breakpoints={[{ maxWidth: "lg", cols: 1 }]}
      >
        <Drawer
          opened={opened}
          overlayColor={
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2]
          }
          overlayOpacity={0.55}
          overlayBlur={3}
          position="right"
          onClose={() => setOpened(false)}
          //  title="Udpate Quantity"
          padding="xl"
          size="50rem"
        >
          <Divider my="xl" label="Update Quantity" labelPosition="center" />

          <Card
            withBorder
            shadow="md"
            radius="md"
            p="xl"
            className={classes.card}
            style={{ marginTop: 50 }}
          >
            {items}
          </Card>
          <div className="services section"></div>
        </Drawer>
        <Card
          withBorder
          radius="md"
          p="xl"
          style={{ padding: "9px !important" }}
        >
          {/* <Image
            src={"https://i.ibb.co/M6SSP1d/Requisition.png"}
            fit="fill"
            radius="sm"
          /> */}
          <div
            className="mantine-Badge-root mantine-rhsza5"
            style={{ marginTop: 9 }}
          >
            <span className="mantine-h9iq4m mantine-Badge-inner">
              Consumables Logs
            </span>
          </div>
          {/* <Text
            variant="gradient"
            gradient={{ from: "gold", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            weight={650}
            style={{ marginTop: 20 }}
            size={25}
            className={classes.description}
            align="center"
          >
            Central Document Request-Types Logs
          </Text> */}
          <Text size="sm" mt="sm" color="dimmed" style={{ padding: 20 }}>
            The logs that your line manager has taken action on are those that
            are listed below.
          </Text>
          <div style={{ padding: 30 }}>
            <div style={{ marginBottom: 10 }}>
              <Search
                placeholder="Input your search text..."
                onSearch={(value) => {
                  setSearchValues(value);
                }}
                onChange={(e) => {
                  setSearchValues(e.target.value);
                }}
                enterButton
              />
            </div>
            <Table
              bordered
              //rowSelection={{}}
              // className="table align-middle table-row-dashed fs-6 gy-4 mb-0"
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              // onChange={onChange}
            />
          </div>
        </Card>

        {/* <Grid gutter="md">
            <Grid.Col>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid> */}
      </SimpleGrid>
    </>
  );
};

export default ConsumblesLogs;
