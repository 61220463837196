import React, { useState } from "react";
import {
  Button,
  Center,
  createStyles,
  Group,
  Paper,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import {
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
  IconArrowUpRight,
  IconArrowDownRight,
  IconFileLike,
  IconBan,
  IconGitPullRequest,
  IconArrowAutofitLeft,
} from "@tabler/icons";
import axios from "axios";
import { message, Popconfirm } from "antd";

const data = [
  {
    title: "Request",
    icon: "request",
    value: "13,456",
    diff: 34,
  },
  {
    title: "Approved",
    icon: "approval",
    value: "4,145",
    diff: -13,
  },
  {
    title: "Declined",
    icon: "reject",
    value: "745",
    diff: 18,
  },
  {
    title: "Updates",
    icon: "user",
    value: "188",
    diff: -30,
  },
];

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  value: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

const icons: any = {
  user: IconUserPlus,
  discount: IconDiscount2,
  receipt: IconReceipt2,
  coin: IconCoin,
  reject: IconBan,
  approval: IconFileLike,
  request: IconGitPullRequest,
};

// interface StatsGridProps {
//   data: {
//     title: string;
//     icon: keyof typeof icons;
//     value: string;
//     diff: number;
//   }[];
// }

const StatisticsView = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const rolloutBack = async () => {
    setLoading(true);
    await axios
      .delete("https://frisbackendurl.azurewebsites.net/rollback")
      .then(() => {
        message.success("Successfully Deleted");
      })
      .catch((err) => {});
    setLoading(false);
  };

  const stats = data.map((stat: any) => {
    const Icon = icons[stat.icon];
    const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Paper withBorder p="md" radius="md" key={stat.title}>
        <Group position="apart">
          <Text size="xs" color="dimmed" className={classes.title}>
            {stat.title}
          </Text>
          <Icon className={classes.icon} size={22} stroke={1.5} />
        </Group>

        <Group align="flex-end" spacing="xs" mt={25}>
          <Text className={classes.value}>{stat.value}</Text>
          <Text
            color={stat.diff > 0 ? "teal" : "red"}
            size="sm"
            weight={500}
            className={classes.diff}
          >
            <span>{stat.diff}%</span>
            <DiffIcon size={16} stroke={1.5} />
          </Text>
        </Group>

        <Text size="xs" color="dimmed" mt={7}>
          Compared to previous month
        </Text>
      </Paper>
    );
  });
  return (
    <div className={classes.root}>
      <SimpleGrid
        cols={1}
        breakpoints={[
          { maxWidth: "lg", cols: 2 },
          { maxWidth: "xs", cols: 1 },
        ]}
      >
        <Title> Rollback</Title>
        <Text color="dimmed">
          Please consider rolling back the record, which would mean clearing the
          database.{" "}
        </Text>
        <Popconfirm
          title="Are you sure you want to take this action? "
          onConfirm={rolloutBack}
          onCancel={handleCancel}

          // onOpenChange={() => console.log("open change")}
        >
          <Button
            color="orange"
            size="sm"
            style={{ width: 250 }}
            //loading={loading}
            leftIcon={<IconArrowAutofitLeft size={18} />}
            onClick={showPopconfirm}
          >
            Rollback
          </Button>
        </Popconfirm>
      </SimpleGrid>
    </div>
  );
};

export default StatisticsView;
