import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Grid,
  SimpleGrid,
  Skeleton,
  Card,
  Anchor,
  Breadcrumbs,
  Indicator,
  Box,
  Progress,
  ThemeIcon,
  Loader,
  Center,
  Badge,
  NumberInputHandlers,
  MultiSelect,
  NumberInput,
} from "@mantine/core";
import { Button, List, Select, Table } from "antd";
import { useScrollIntoView, useWindowScroll } from "@mantine/hooks";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { nanoid } from "nanoid";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";

const { Dragger } = Upload;

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  description: {
    maxWidth: 650,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,

    "&:hover": {
      transform: "scale(1.11)",
      boxShadow: theme.shadows.md,
    },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: "260px",
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface DataType {
  key: string;
  items: string;
  status: number;
  createdAt: any;
}

const columns: ColumnsType<DataType> = [
  {
    title: "S/N",
    dataIndex: "id",
    key: "id",
    responsive: ["lg"],
  },
  {
    title: "Control-ID",
    dataIndex: "control_number",
    key: "control_number",
    responsive: ["lg"],
  },
  {
    title: "Items",
    dataIndex: "items",
    key: "items",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },

  {
    title: "Requester Email",
    dataIndex: "requester_email",
    key: "requester_email",
    responsive: ["lg"],
    ellipsis: true,
  },

  {
    title: "CreatedAt",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    render: (date) => {
      return <Box> {moment(date).format("MMMM Do YYYY, h:mm:ss a")} </Box>;
    },
  },
];

let getLocalStorageItems: any = localStorage.getItem("Items");

console.log(getLocalStorageItems);

const RequestLogTable = () => {
  const [scroll, scrollTo] = useWindowScroll();
  const [display, setDisplay] = useState(false);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [a, seta] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [shows, setShows] = useState(false);
  const [c, setC] = useState([]);
  const [values, setValues] = useState(0);
  const handlers: any = useRef<NumberInputHandlers>(null);
  const previousValue: any = useRef(null);

  let { user } = useSelector((state: any) => ({ ...state }));

  // let control_id = window.location.href;

  // console.log(data);

  useEffect(() => {
    //  setLoading(true);

    fetch("http://localhost:5000/requestlog")
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.data);
        // setOptions(res.data);
        setDataSource(res.data);
      })
      .finally(() => {
        //   setLoading(false);
      });
    //  };
  }, []);

  console.log(dataSource);

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".csv",
    action: "http://localhost:5000/uploadfiles",
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        const parent: any = document.querySelector(".ant-upload-list");

        setDisplay(true);

        const newEl = document.createElement("p");

        newEl.style.cssText = "text-align:center";
        newEl.textContent = "Attachment List";

        parent.insertBefore(newEl, parent.children[0]);
        window.scroll(0, 500);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      setDisplay(false);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <SimpleGrid
        cols={1}
        spacing="md"
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        <Card
          withBorder
          radius="md"
          p="xl"
          style={{ padding: "9px !important" }}
        >
          <Image
            src={"https://i.ibb.co/M6SSP1d/Requisition.png"}
            fit="fill"
            radius="sm"
          />
          <div className="mantine-Badge-root mantine-rhsza5">
            <span className="mantine-h9iq4m mantine-Badge-inner">
              Request Logs
            </span>
          </div>
          {/* <Text
            variant="gradient"
            gradient={{ from: "gold", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            weight={650}
            style={{ marginTop: 20 }}
            size={25}
            className={classes.description}
            align="center"
          >
            Central Document Request-Types Logs
          </Text> */}
          <Text size="sm" mt="sm" color="dimmed" style={{ padding: 20 }}>
            Please choose the needed item from the drop-down menu and enter the
            necessary quantity.
          </Text>
          <div style={{ padding: 30 }}>
            <Table
              bordered
              //rowSelection={{}}
              className="table align-middle table-row-dashed fs-6 gy-4 mb-0"
              dataSource={dataSource}
              columns={columns}
              // onChange={onChange}
            />
          </div>
        </Card>

        {/* <Grid gutter="md">
            <Grid.Col>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid> */}
      </SimpleGrid>
    </>
  );
};

export default RequestLogTable;
