import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Image, Grid, Text, Badge, Button, Group } from "@mantine/core";
import { Link, Navigate, useNavigate } from "react-router-dom";

const LoadingtoRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [count, setCount] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount);
    }, 1000);

    count === 0 && navigate("/");

    return () => clearInterval(interval);
  }, [count]);
  return (
    <Grid>
      <Grid.Col span="auto"></Grid.Col>
      <Grid.Col span={10}>
        <Card
          p="xl"
          shadow="lg"
          style={{ marginTop: "20px", padding: "50px" }}
          radius="md"
          withBorder
        >
          <Card.Section>
            <div className="container mt-5 p-5 text-center">
              <p>
                Redirecting you to the Dashboard in {count} seconds;{" "}
                <p>
                  {" "}
                  Otherwise, unauthorized attempts will be routed to the Login
                  page.
                </p>
              </p>
            </div>
          </Card.Section>
        </Card>
      </Grid.Col>
      <Grid.Col span="auto"></Grid.Col>
    </Grid>
  );
};

export default LoadingtoRedirect;
