import { useRef, useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  createStyles,
  ActionIcon,
  Tooltip,
  Menu,
  Button,
} from "@mantine/core";
import {
  TablerIcon,
  IconCalendarStats,
  IconChevronLeft,
  IconChevronRight,
  IconSettings,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconArrowsLeftRight,
  IconTrash,
} from "@tabler/icons";
import { Link } from "react-router-dom";
// import LoadingBar from "react-top-loading-bar";
// import { useDispatch } from "react-redux";

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 15,
    marginLeft: 5,
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    // borderLeft: `1px solid ${
    //   theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    // }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.orange[3],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
    borderRadius: 10,
  },

  links: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: "5px",
    // paddingLeft: 20,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    // borderLeft: `1px solid ${
    //   theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    // }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: "transform 200ms ease",
  },
}));

interface LinksGroupProps {
  icon?: any;
  label: string;
  component?: any;
  initiallyOpened?: boolean;
  links?: { label: string; link: string }[];
  to?: any;
  linked?: any;
  progress?: any;
}

export function LinksGroups({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  linked,
  progress,
}: LinksGroupProps) {
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);

  const ref = useRef(null);
  // const dispatch = useDispatch();
  //  const [progresss, setProgresss] = useState(0);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;
  const items = (hasLinks ? links : []).map((link) => (
    <Text
      component={Link}
      to={link.link}
      className={classes.link}
      key={link.label}
      //   onClick={(event) => {
      //     dispatch({
      //       type: "LOGGED_UPDATES",
      //       payload: {
      //         values: 100,
      //       },
      //     });
      //   }}
    >
      {link.label}
    </Text>
  ));

  return (
    <>
      {label === "Notifications" ? null : (
        <UnstyledButton
          onClick={() => setOpened((o) => !o)}
          className={classes.control}
        >
          <Group position="apart" spacing={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                label={label}
                position="right"
                // transitionProps={{ duration: 0 }}
              >
                <ThemeIcon variant="light" size={30}>
                  <ActionIcon
                    sx={{
                      "&[data-disabled]": { opacity: 0.4 },
                      "&[data-loading]": { backgroundColor: "red" },
                    }}
                    component={Link}
                    to={linked}
                    variant="default"
                  >
                    <Icon
                      size={18}
                      className={
                        label === "Notifications" ? "labelstyle" : null
                      }
                    />
                  </ActionIcon>
                </ThemeIcon>
              </Tooltip>
              {/* <Box ml="md" component={Link} to={linked} className={classes.links}>
              {label}
            </Box> */}
            </Box>

            {hasLinks && <></>}
          </Group>
        </UnstyledButton>
      )}
      {hasLinks ? (
        <Menu
          shadow="md"
          width={200}
          position="right-start"
          offset={20}
          withArrow
          arrowPosition="center"
          trigger="hover"
          openDelay={100}
          closeDelay={400}
        >
          <Menu.Target>
            <Group position="apart" spacing={0} style={{ margin: 15 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ThemeIcon variant="light" size={30}>
                  <ActionIcon
                    sx={{
                      "&[data-disabled]": { opacity: 0.4 },
                      "&[data-loading]": { backgroundColor: "red" },
                    }}
                    component={Link}
                    to={linked}
                    variant="default"
                  >
                    <Icon size={18} />
                  </ActionIcon>
                </ThemeIcon>
              </Box>
            </Group>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item>{items}</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : null}
    </>
  );
}

//<Collapse in={opened}>{items}</Collapse>

const mockdata = {
  label: "Releases",
  icon: IconCalendarStats,
  links: [
    { label: "Upcoming releases", link: "/cerficate" },
    { label: "Previous releases", link: "/" },
    { label: "Releases schedule", link: "/" },
  ],
};

export function NavbarLinksGroup() {
  return (
    <Box
      sx={(theme) => ({
        minHeight: 220,
        padding: theme.spacing.md,
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
      })}
    >
      <LinksGroups {...mockdata} />
    </Box>
  );
}
