import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { MultiSelect } from "@mantine/core";
import { Button, List, Select } from "antd";
import { useId } from "@mantine/hooks";
import { nanoid } from "nanoid";
import axios from "axios";
import {
  NumberInput,
  Group,
  ActionIcon,
  NumberInputHandlers,
} from "@mantine/core";

let data: any = [];

const SelectUI = () => {
  const [a, seta] = useState([]);
  const [shows, setShows] = useState(false);
  const [c, setC] = useState([]);
  const [values, setValues] = useState(0);
  const handlers: any = useRef<NumberInputHandlers>(null);
  const previousValue: any = useRef(null);

  useEffect(() => {
    //  setLoading(true);
    fetch("http://localhost:5000/consumbles")
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.data);
        res.data.forEach((value: any, index: any) => {
          data.push({
            key: nanoid(),
            value: value.items,
            label: value.items,
            quantity: 0,
            status: false,
          });
        });
        //setC(res.data);

        // setOptions(res.data);
      })
      .finally(() => {
        //   setLoading(false);
      });
  }, []);

  //const filteredOptions = options.filter((o) => !selectedItems.includes(o));

  // console.log(previousValue.current);

  useEffect(() => {
    //setValues((val) => val);
    //  console.log(values, "djdjd");
  }, [values]);

  // console.log(handlers);
  return (
    <>
      <MultiSelect
        data={data}
        searchable
        onChange={(item: any) => {
          let b: any = [];
          item.forEach((items: any) => {
            b.push({
              value: items,
              quantity: 0,
              key: items,
              label: items,
              status: false,
            });
          });

          seta(b);

          // setC(b);
        }}
        label="Your favorite frameworks/libraries"
        placeholder="Pick all that you like"
      />

      <br />
      <br></br>
      {a.length > 0 ? (
        <List
          size="large"
          header={<div>Request Items</div>}
          footer={
            <div>
              {" "}
              <div className="addButton">
                <Button
                  type="primary"
                  onClick={(e) => {
                    console.log(data);
                    // a.forEach((item: any) => {
                    //   axios
                    //     .post("http://localhost:5000/requistions", {
                    //       items: item,
                    //       manager: "ADEBAYO",
                    //       quantity: 1,
                    //       requester_email: "abioadex2000@gmail.com",
                    //     })
                    //     .then((res) => console.log(res.data));
                    // });
                  }}
                >
                  {" "}
                  Add{" "}
                </Button>
              </div>
            </div>
          }
          bordered
          dataSource={a}
          renderItem={(item: any, index: any) => (
            <>
              <>
                <List.Item
                  extra={
                    <>
                      <Group spacing={5}>
                        {/* <ActionIcon
                      size={20}
                      variant="default"
                      onClick={() => handlers.current.decrement()}
                    >
                      –
                    </ActionIcon> */}

                        {/* <NumberInput
                          label="Quantity"
                          value={values}
                          onChange={(val: any) => {
                            var indexs = _.findIndex(item, { key: item.key });
                            console.log(indexs);
                          }}
                          // handlersRef={handlers}
                          max={10}
                          min={0}
                          // step={2}
                          styles={{ input: { width: 59, textAlign: "center" } }}
                        /> */}

                        {/* <ActionIcon
                      size={20}
                      variant="default"
                      onClick={() => handlers.current.increment()}
                    >
                      +
                    </ActionIcon> */}
                        {/* <Button
                          onClick={() => {
                            // let objIndex: any = c.findIndex(
                            //   (obj: any) => obj.key === obj.value
                            // );

                            // var index = _.findIndex(c);

                            console.log(index);
                          }}
                        >
                          SAVE
                        </Button> */}
                      </Group>
                    </>
                  }
                >
                  {item.value}
                  <div className="addButton">
                    <NumberInput
                      label="Quantity"
                      value={values}
                      // defaultValue={0}
                      onChange={(val: any) => {
                        let indexs = _.findIndex(data, { value: item.value });

                        data.splice(indexs, 1, {
                          value: item.value,
                          quantity: val || 0,
                          label: item.value,
                          key: item.value,
                          status: true,
                        });

                        //setC(data)

                        let p = _.filter(data, function (o) {
                          return o.status;
                        });

                        console.log(p);

                        //  _.merge(a, data);
                      }}
                      // handlersRef={handlers}
                      max={10}
                      min={0}
                      // step={2}
                      styles={{ input: { width: 59, textAlign: "center" } }}
                    />

                    {/* <Button onClick={() => console.log(a)}>Logs</Button> */}
                  </div>
                </List.Item>
              </>
            </>
          )}
        />
      ) : null}
    </>
  );
};

export default SelectUI;
