import {
  ActionIcon,
  Divider,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Popover,
  TextInput,
  Text,
  Grid,
  Title,
  Tooltip,
  Button as Buttons,
  Textarea,
} from "@mantine/core";
import {
  Button,
  List,
  Select,
  message,
  Upload,
  Steps,
  Table,
  Popconfirm,
  InputNumber,
  Input,
  Form,
  Typography,
} from "antd";

import { Badge } from "@mantine/core";
import { nanoid } from "nanoid";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddItemContext from "../../../../../AddItemsContext";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import {
  IconDeviceFloppy,
  IconEdit,
  IconSquareCheck,
  IconSquareX,
  IconThumbDown,
  IconThumbUp,
  IconChevronsLeft,
  IconTrash,
} from "@tabler/icons";

let data: any = [];

interface Item {
  key: string;
  name: string;
  button_ui: any;
  age: number;
  address: string;
}

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
  description: string;
}

const EditedTable = (props: any) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [t, setT] = useState([]);
  const [value, setValue] = useState(0);
  const [hodqty, setHodQty] = useState(0);
  const [uuids, setUuids] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonQty, setButtonQty] = useState(false);
  const [counter, setCounter] = useState(0);
  const [values, setValues] = useState(0);

  const { addToItem, items } = useContext(AddItemContext);

  let { user } = useSelector((state: any) => ({ ...state }));

  let { show } = useSelector((state: any) => ({ ...state }));

  const navigate = useNavigate();

  let { control_id } = useParams();

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: Item;
    index: number;
    children: React.ReactNode;
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ hod_qty: hodqty, ...record });
    setEditingKey(record.key);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (value: any, item: any, index: any) =>
        index === 0 ? index + 1 : index + 1,
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",

      // render: (text) => <a>{text}</a>,
    },
    {
      title: "User's Request Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "10%",
      render: (value) => (
        <Badge color="orange" radius="xs">
          {" "}
          {value}
        </Badge>
      ),
    },

    {
      title: "Manager's Approved Quantity",
      dataIndex: "manager_qty",
      key: "manager_qty",
      width: "5%",
      render: (value) => (
        <Badge color="" radius="xs">
          {" "}
          {value}
        </Badge>
      ),
    },
    {
      title: "Admin's Approved Quantity",
      dataIndex: "admin_qty",
      key: "admin_qty",
      width: "5%",
      render: (value) => (
        <Badge color="lime" radius="xs">
          {" "}
          {value}
        </Badge>
      ),
    },
    {
      title: "Expiration Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "5%",
      render: (value) => (
        <Badge color="gray" variant="filled" radius="lg">
          {" "}
          {moment().add(2, "day").format("DD-MM-YYYY")}
        </Badge>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "5%",
      render: (value) => (
        <Badge
          variant="gradient"
          gradient={{ from: "teal", to: "lime", deg: 105 }}
        >
          {value}
        </Badge>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], index: any) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    //selectedRowKeys const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // console.log("Bidun", selectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const loadDataFromDB = () => {
    data = [];
    setLoading(true);

    fetch(`https://frisbackendurl.azurewebsites.net/released/${control_id}`)
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value: any, index: any) => {
          // console.log(counter);
          setLoading(true);
          data.push({
            key: value.uuid,
            items: value.items,
            quantity: value.user_quantity,
            control_number: value.control_number,
            status: value.admin_status,
            remarks: value.remarks,
            admin_qty: value.admin_qty,
            manager_qty: value.manager_qty,
            hod_qty: value.manager_qty,
            counts_of_items: value.count_of_items,
            id: value.id,
            updatedAt: value.updatedAt,
          });
          setValues(Number(value.count_of_items));
        });
        setDataSource(data);
      })
      .finally(() => {
        //   setLoading(false);
        setLoading(false);
      });
    //  };
  };

  const confirm = async (uuid: any) => {
    // console.log(hodqty);
    try {
      await axios
        .put(
          `https://firstregistrarsnigeria.com/frisportals/requisitions/${uuid}`,
          {
            status: "APPROVED",
          }
        )
        .then(() => {
          setCounter((prevCount) => prevCount + 1);
          loadDataFromDB();
          message.success({
            content: "Operation is Successfully !",
            duration: 7,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDeclined = async (uuid: any) => {
    // console.log(uuid);
    try {
      await axios
        .put(
          `https://firstregistrarsnigeria.com/frisportals/requisitionsdeleted/${uuid}`,
          {
            status: "REJECTED",
          }
        )
        .then(() => {
          setCounter((prevCount) => prevCount + 1);
          loadDataFromDB();
          message.success({
            content: "Operation is Successfully !",
            duration: 7,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const changeQty = async (uuid: any, val: any) => {
    console.log(uuid);
    await axios
      .put(
        `https://firstregistrarsnigeria.com/frisportals/requisitionsqty/${uuid}`,
        {
          quantity: val,
        }
      )
      .then(() => {
        setValue(val);
        //setCounter((prevCount) => prevCount + 1);
        loadDataFromDB();
        // message.success({
        //   content: "Operation is Successfully !",
        //   duration: 7,
        // });
      });
  };

  const confirmall = async (uuid: any) => {
    try {
      await axios
        .put(
          `https://frisbackendurl.azurewebsites.net/requisitionsall/${uuid}`,
          {
            status: "APPROVED",
          }
        )
        .then(() => {
          setCounter((prevCount) => prevCount + 1);
          loadDataFromDB();
          message.success({
            content: "Operation is Successfully !",
            duration: 3,
          });
        });
    } catch (error) {}
  };

  const moveBack = async (params: any) => {
    navigate("/users/notification/releasedlogs");
  };

  // const saveLogs = async () => {
  //   message.loading("Please wait while the action is in progress...", 6.5);
  //   await axios
  //     .put(`http://localhost:5000/requestlog/${control_id}`, {
  //       status: "TREATED",
  //     })
  //     .then((item) => {
  //       //  setCounter((prevCount) => prevCount + 1);
  //       //  loadDataFromDB();
  //       // console.log(item);
  //       message.success({
  //         content: "Request had been updated Successfully !",
  //         duration: 5,
  //       });
  //       navigate("/manager/treatedlog");
  //     });
  // };

  useEffect(() => {
    loadDataFromDB();
  }, []);

  // useEffect(() => {
  //   let value: any = {};

  //   value = _.last(data);

  //   if (
  //     value !== undefined &&
  //     _.every(dataSource, (element: any) => element.button_ui === true)
  //   ) {
  //     saveLogs();
  //   }

  //   //if (value.button_ui_admin === true) {
  //   //  showPromiseConfirm();
  //   /// }
  // }, [dataSource]);

  // const arrayFunction = (element) =>{

  // }

  // useEffect(() => {
  //   let a = _.every(dataSource, (element: any) => {
  //     console.log(element.button_ui);
  //   });

  //   //console.log(a);
  // });

  useEffect(() => {
    buttonFunction();
  }, []);

  const buttonFunction = () => {
    if (Number(values) && counter == Number(values)) {
      setButtonDisabled(false);
    } else if (
      _.every(dataSource, (element: any) => element.button_ui === true)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      //console.log(hodqty);

      const newData: any = [...dataSource];
      const index = newData.findIndex((item: any) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        // setHodQty(newData[index].hod_qty);
        await axios
          .put(
            `https://frisbackendurl.azurewebsites.net/requisitionshodqty/${key}`,
            {
              manager_qty: newData[index].hod_qty,
              quantity: newData[index].hod_qty,
            }
          )
          .then((item) => {
            //  console.log("Hello");
            //  setCounter((prevCount) => prevCount + 1);
            //  loadDataFromDB();
            // console.log(item);
            message
              .loading("Action in progress..", 1.0)
              .then(() =>
                message.success("Successfully update the quantity", 3.5)
              );
          });

        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setHodQty(newData.hod_qty);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  console.log(moment().add(2, "hours").format("DD-MM-YYYY"));

  return (
    <Paper
      shadow="md"
      radius="lg"
      p="lg"
      withBorder
      style={{ padding: "0px", margin: "30px" }}
    >
      <div className="removeMargin">
        <div className="firstHalf">
          <div
            style={{
              padding: 40,
              height: "auto",
            }}
          >
            <ActionIcon
              component="button"
              variant="light"
              onClick={() => navigate(-1)}
            >
              <IconChevronsLeft size={35} color={"red"} />
            </ActionIcon>

            <br />
            <br />

            <Title order={3} color="dimmed">
              {" "}
            </Title>
            <p>
              <Text>
                The records of the item the admin. released are listed in the
                table below. Further details are available by{" "}
                <strong>expanding the icon </strong>next to the status column.
              </Text>
            </p>
            <Text>
              Moreover, keep in mind that the items have a{" "}
              <strong>48-hours retrival</strong> period.
            </Text>
            <Group position="apart" sx={{ padding: 15 }}>
              {hasSelected ? (
                <div className="mantine-1nqidi4">
                  <Tooltip
                    label="Accept All"
                    withArrow
                    arrowSize={5}
                    transition="skew-up"
                    transitionDuration={300}
                  >
                    <Buttons
                      color="lime"
                      rightIcon={<IconThumbUp />}
                      onClick={() => confirmall(selectedRowKeys)}
                    >
                      Accept ALL
                    </Buttons>
                  </Tooltip>

                  <span style={{ marginRight: 190, marginLeft: 110 }}>
                    <Text color="dimmed" style={{}}>
                      {" "}
                      {hasSelected
                        ? `Selected ${selectedRowKeys.length} item(s)`
                        : ""}
                    </Text>
                  </span>
                  <Tooltip
                    label="Reject All"
                    withArrow
                    arrowSize={5}
                    transition="skew-up"
                    transitionDuration={300}
                  >
                    <Buttons>Reject ALL</Buttons>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </Group>
            {/* <Form form={form} component={false}> */}
            <Table
              // rowSelection={rowSelection}

              scroll={{
                x: 1550,
                //y: 300,
              }}
              bordered
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              expandable={{
                expandedRowRender: (record: any) => (
                  <>
                    <span> Notes</span>{" "}
                    <p style={{ margin: 5 }}>
                      {" "}
                      <strong>{record.remarks} </strong>
                    </p>{" "}
                  </>
                ),
                rowExpandable: (record: any) => record.remarks !== null,
              }}
              pagination={{
                pageSize: 10,
              }}

              // onChange={onChange}
            />
            {/* </Form> */}
            {/* <Button
              type="primary"
              danger
              disabled={buttonDisabled}
              onClick={() => saveLogs()}
            >
              {" "}
              Save
            </Button> */}
            <Divider style={{ marginTop: 20 }} />
          </div>
        </div>

        <div className="secondHalf">
          <div className="custom-shape-divider-bottom-1671865759">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fills"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default EditedTable;
