import { useCallback, useEffect, useMemo, useState } from "react";
import { DatePicker } from "@mantine/dates";
import {
  createStyles,
  ThemeIcon,
  Text,
  SimpleGrid,
  Box,
  Stack,
  Badge,
  Card,
  Button,
  Dialog,
  Flex,
  Title,
  ActionIcon,
  Menu,
  TextInput,
  Tooltip,
  Modal,
  Image,
  useMantineTheme,
  Grid,
  Accordion,
  NumberInput,
  Skeleton,
  Table as Tables,
  Loader,
} from "@mantine/core";
import {
  IconSun,
  IconPhone,
  IconMapPin,
  IconAt,
  IconClipboardList,
  IconCalculator,
  IconHexagonNumber4,
  IconPlus,
  IconCirclePlus,
} from "@tabler/icons-react";
import moment from "moment";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/cjs/Page/AnnotationLayer.css";

// import {
//   MantineReactTable,
//   MantineReactTableProps,
//   MRT_Cell,
//   MRT_ColumnDef,
//   MRT_Row,
// } from "mantine-react-table";

import { datas, states } from "./data";
import { IconTrash, IconEdit } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Divider, List } from "antd";
import type { UploadProps } from "antd";
import { message, Upload, Table } from "antd";
import { randomId, useDisclosure } from "@mantine/hooks";
import type { ColumnsType } from "antd/es/table";
import { useForm } from "@mantine/form";
import axios from "axios";
const { Dragger } = Upload;

export type Person = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  age: number;
  state: string;
};

type ContactIconVariant = "white" | "gradient";

interface ContactIconStyles {
  variant: ContactIconVariant;
}

let data: any = [];
let dataVendor: any = [];

const useStyles = createStyles((theme, { variant }: ContactIconStyles) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.white,
  },

  icon: {
    marginRight: theme.spacing.md,
    backgroundImage:
      variant === "gradient"
        ? `linear-gradient(135deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
            theme.colors[theme.primaryColor][6]
          } 100%)`
        : "none",
    backgroundColor: "transparent",
  },

  title: {
    color:
      variant === "gradient"
        ? theme.colors.gray[6]
        : theme.colors[theme.primaryColor][0],
  },

  description: {
    color: variant === "gradient" ? theme.black : theme.white,
  },
}));

interface ContactIconProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "title"> {
  icon1: React.FC<any>;
  icon2: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
  quantity: React.ReactNode;
  names: React.ReactNode;
  variant?: ContactIconVariant;
}

function ContactIcon({
  icon1: Icon,
  icon2: Icon2,
  title,
  names,
  description,
  quantity,
  variant = "gradient",
  className,
  ...others
}: ContactIconProps) {
  const { classes, cx } = useStyles({ variant });
  return (
    <>
      <div className={cx(classes.wrapper, className)} {...others}>
        {variant === "gradient" ? (
          <ThemeIcon size={40} radius="md" className={classes.icon}>
            <Icon size="1.5rem" />
          </ThemeIcon>
        ) : (
          <Box mr="md">
            <Icon size="1.5rem" />
          </Box>
        )}

        <div>
          <Text size="xs" className={classes.title}>
            {title}
          </Text>
          <Text className={classes.description}>{description}</Text>
        </div>
      </div>

      <div className={cx(classes.wrapper, className)} {...others}>
        {variant === "gradient" ? (
          <ThemeIcon size={40} radius="md" className={classes.icon}>
            <Icon2 size="1.5rem" />
          </ThemeIcon>
        ) : (
          <Box mr="md">
            <Icon2 size="1.5rem" />
          </Box>
        )}

        <div>
          <div>
            <Text size="xs" className={classes.title}>
              {names}
            </Text>
            <Text className={classes.description}>
              <Badge color="pink" variant="light">
                {quantity}
              </Badge>
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}

interface ContactIconsListProps {
  data?: ContactIconProps[];
  variant?: ContactIconVariant;
}

const MOCKDATA = [
  { title: "Email", description: "hello@mantine.dev", icon: IconAt },
  { title: "Phone", description: "+49 (800) 335 35 35", icon: IconPhone },
  { title: "Address", description: "844 Morris Park avenue", icon: IconMapPin },
  { title: "Working hours", description: "8 a.m. – 11 p.m.", icon: IconSun },
];

export function ItemsIconsList({
  // data = MOCKDATA,
  variant,
}: ContactIconsListProps) {
  const items = data.map((item: any, index: any) => (
    <>
      <ContactIcon key={index} variant={variant} {...item} />
    </>
  ));
  return (
    <>
      <List size="small" header={<div>Description</div>} bordered>
        {" "}
        {data === undefined && data.length == 0 ? (
          <Loader variant="dots" size="xl" />
        ) : (
          <Stack style={{ padding: "26px" }}>{items}</Stack>
        )}
      </List>
    </>
  );
}

const ConsumblesLogs = () => {
  let { uuid } = useParams();
  const [dataDetail, setDataDetail] = useState([]);
  const theme = useMantineTheme();

  //Mantine React Table

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  let { user } = useSelector((state: any) => ({ ...state }));

  const [pdf, setPdf] = useState("");
  const [loading, setLoading] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [itemsAmount, setItemAmount] = useState(0);
  const [loadings, setLoadings] = useState(false);

  const [vendorNameConfidence, setVendorNameConfidence] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [result, setResult] = useState(0);
  const [vendorsInfo, setVendorsInfo] = useState([]);

  const [tableData, setTableData] = useState<Person[]>(() => datas);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const handleCreateNewRow = (values: Person) => {
    tableData.push(values);
    setTableData([...tableData]);
  };

  // const handleSaveRowEdits: MantineReactTableProps<Person>["onEditingRowSave"] =
  //   async ({ exitEditingMode, row, values }) => {
  //     if (!Object.keys(validationErrors).length) {
  //       tableData[row.index] = values;
  //       //send/receive api updates here, then refetch or update local table data for re-render
  //       setTableData([...tableData]);
  //       exitEditingMode(); //required to exit editing mode and close modal
  //     }
  //   };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  interface DataType {
    key: string;
    items: string;
    status: number;
    createdAt: any;
  }

  // Columns

  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
      responsive: ["lg"],
      render: (value: any, item: any, index: any) =>
        index === 0 ? index + 1 : index + 1,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      // render: (items: any, record: any) => {
      //   // console.log(record);

      //   return (
      //     <span className="fw-bolder">
      //       <Link to={"/admin/consumables/add/" + record.uuid}>
      //         {" "}
      //         {/* <Buttons
      //         variant="subtle"
      //         onClick={async () => {
      //           // await handler.open();
      //           datas = [];
      //           setOpened(true);
      //           // if (datas === null) {
      //           await loadDataFromDB(record.uuid);
      //           // }
      //         }}
      //       > */}
      //         <strong> {items} </strong>
      //         {/* </Buttons> */}
      //       </Link>
      //     </span>
      //   );
      // },
    },
    // {
    //   title: "Stocks (IN)",
    //   dataIndex: "stock_in",
    //   key: "stock_in",
    //   filteredValue: [searchValues],
    //   onFilter(value: any, record: any) {
    //     return (
    //       String(record.items).toLowerCase().includes(value.toLowerCase()) ||
    //       String(record.createdAt).toLowerCase().includes(value.toLowerCase())
    //     );
    //   },
    //   // render: (text) => <a>{text}</a>,
    // },

    // {
    //   title: "Invoice Date",
    //   dataIndex: "invoiceDate",
    //   key: "invoiceDate",
    // },

    {
      title: "Item",
      dataIndex: "items",
      key: "items",
    },

    {
      title: "Initial Stock_in",
      dataIndex: "stock_in",
      key: "stock_in",
    },

    {
      title: "Inputted Quantity",
      dataIndex: "inputtedquantity",
      key: "inputtedquantity",
    },

    {
      title: "Quantity Balance",
      dataIndex: "balance",
      key: "balance",
    },

    {
      title: "Vendor Name",
      dataIndex: "vendorsname",
      key: "vendorsname",
    },
    // {
    //   title: "Total Amaunt",
    //   dataIndex: "totalAmount",
    //   key: "totalAmount",
    // },
    {
      title: "CreatedAt",
      dataIndex: "inoviceDate",
      key: "inoviceDate",
      //sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => {
        return <Box> {moment(date).format("MMMM Do YYYY")} </Box>;
      },
    },

    // {
    //   title: "Action(s)",
    //   dataIndex: "",
    //   key: "uuid",
    //   render: (items: any, record: any) => {
    //     // console.log(record);
    //     return (
    //       <ActionIcon onClick={() => deleteItems(items.uuid)}>
    //         <IconTrash color="red" />
    //       </ActionIcon>
    //     );
    //   },
    // },
  ];

  const LoadsDataFromDB = () => {
    // console.log(user.name);
    data = [];
    fetch(`https://frisbackendurl.azurewebsites.net/consumbles/${uuid}`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data);
        // setOptions(res.data);
        setLoadings(true);

        res.data.forEach((value: any, index: any) => {
          data.push({
            key: value.uuid,
            title: "Items",
            description: value.items,
            icon1: IconClipboardList,
            icon2: IconCalculator,
            quantity: value.stock_in,
            names: "Quantity",
            stock_in: value.stock_in,
            id: value.uuid,
          });
        });
        //  setDataSource(data);
        setDataDetail(data);

        console.log(typeof dataDetail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const LoadsDatafronVendors = () => {
    // console.log(user.name);
    dataVendor = [];
    fetch(`https://frisbackendurl.azurewebsites.net/vendors/details/${uuid}`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data);
        // setOptions(res.data);

        res.data.forEach((value: any, index: any) => {
          dataVendor.push({
            key: value.uuid,
            items: value.items,
            quantity: value.inputtedquantity,
            invoiceNumber: value.invoiceNumber,
            balance: value.balance,
            inputtedquantity: value.inputtedquantity,
            totalAmount: value.totalAmount,
            vendorsname: value.vendorsname,
            stock_in: value.stock_in,
            inoviceDate: value.createdAt,
          });
        });
        //  setDataSource(data);
        setVendorsInfo(dataVendor);

        //  console.log(typeof dataDetail);
      })
      .finally(() => {
        //   setLoading(false);
      });
  };

  useEffect(() => {
    LoadsDataFromDB();
  }, []);

  useEffect(() => {
    LoadsDatafronVendors();
  }, []);

  const props: UploadProps = {
    //name: "file",
    multiple: false,
    accept: ".pdf",
    listType: "picture",
    action: `https://frisbackendurl.azurewebsites.net/upload/invoice/?uuid=${uuid}&who_uploaded=${user.name}`,
    //   headers: {
    //     token: "token",
    //     "Content-Type": "multipart/form-data"
    //   },

    onChange(info: any) {
      const { status, response } = info.file;

      if (status !== "uploading") {
        message.loading("Action in progress..", 2.9);
        message.loading("Scanning in progress..", 1.5);
      }

      // if (response.url) {
      //   setLoading(true);
      // }

      if (status === "done") {
        setLoading(true);
        console.log(response);
        setVendorName(response.result.fields.VendorName.content);
        setVendorAddress(response.result.fields.VendorAddress.content);
        //setCustomerName(response.result.fields.CustomerName.content);
        setItems(response.result.fields.Items.values);
        setPdfUrl(response.url);
        setVendorNameConfidence(
          parseFloat(response.result.fields.VendorName.confidence) * 100
        );
        ///setInvoiceDate();

        if (
          moment(new Date(response.result.fields.InvoiceDate.content)).isValid()
        ) {
          console.log("Valid date string");
          setInvoiceDate(new Date(response.result.fields.InvoiceDate.content));
        } else {
          console.log("Invalid date string");
          setInvoiceDate(new Date("01/01/1990"));
        }
        setTotalAmount(response.result.fields.InvoiceTotal.value.amount);
        setInvoiceNumber(response.result.fields.InvoiceId.content);

        console.log(response.result.fields.InvoiceId.content);

        setPdf(response.url);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setLoading(false);
        message.error(`${info.file.name} - ${status} file upload failed.`);
      }
    },

    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  //console.log(invoiceDate);

  const arrs = items.map((item: any, key: any) => {
    // const arrloops = item.map((item: any) => {
    //   return (
    //     <>
    //       {" "}
    //       <div>{item.Quantity.content}</div> <div>{item.Unit.content}</div>{" "}
    //       <div>{item.Description.content}</div>{" "}
    //     </>
    //   );
    // });

    // console.log(item.content);
    // setItemAmount(item.properties.Amount.content);
    //  console.log(item.properties.Quantity.content);

    return (
      <>
        {/* <SimpleGrid
          cols={4}
          mt="xl"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        > */}

        <tr key={key}>
          <td>{item.content.split(" ")[1]}</td>
          <td>{item.content.split(" ")[2]}</td>
          <td>{item.content.split(" ")[3]}</td>
          {/* <td>{item.properties.Description.content}</td> */}
          {/* <td>{element.mass}</td> */}
        </tr>
        {/* <TextInput
              placeholder="John Doe"
              withAsterisk
              sx={{ flex: 1 }}
              {...form.getInputProps("amount")}
              value={item.properties.Amount.content}
              defaultValue={item.properties.Amount.content}
            /> */}

        {/* <TextInput
              placeholder="John Doe"
              withAsterisk
              sx={{ flex: 1 }}
              {...form.getInputProps("amount")}
              value={
                item.properties.Amount.content === undefined
                  ? item.properties.Amount.content
                  : null
              }
              // defaultValue={item.properties.Amount.content}
              onChange={(e: any) => setItemAmount(e.target.value)}
            /> */}

        {/* <div key={key}> {item.properties.Amount.content}</div> */}
        {/* </SimpleGrid> */}
      </>
    );
  });

  const form = useForm({
    initialValues: {
      vendorName: vendorName,
      vendorAddress: vendorAddress,
      customerName: customerName,
      invoiceNumber: invoiceNumber,
      totalAmount: totalAmount,

      amount: 0,
      // items: [{ name: itemsAmount, active: false, key: randomId() }],
    },

    // validate: {
    //   email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
    //   password: (val) =>
    //     val.length <= 6
    //       ? "Password should include at least 6 characters"
    //       : null,
    // },
  });

  const handleSubmit = async (values: typeof form.values) => {
    try {
      axios
        .post(
          `https://frisbackendurl.azurewebsites.net/vendorsinfo/?uuid=${uuid}`,
          {
            vendorsName: vendorName,
            vendorsAddress: vendorAddress,
            totalprice: totalAmount,
            uuid,
            invoiceNumber: invoiceNumber,
            items: data[0].description,
            quantity,
            requester: user.name,
            pdfUrl: pdfUrl,
            invoiceDate: moment(invoiceDate).format("DD/MM/YYYY"),
            unitprice: unitPrice,
          }
        )
        .then(() => {
          message.success("Successful");
          close();
          LoadsDatafronVendors();
          LoadsDataFromDB();
        });
    } catch (err) {
      message.error("error");
    }
  };

  useEffect(() => {
    setResult(quantity * unitPrice);
    setTotalAmount(result);
    // if (result > 0) {
    //   setTotalAmount(result);
    // }
  }, [result, quantity, unitPrice]);

  return (
    <>
      <SimpleGrid cols={1} breakpoints={[{ maxWidth: 700, cols: 1 }]}>
        <Box
          sx={(theme) => ({
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
            backgroundColor: theme.white,
          })}
        >
          <ItemsIconsList />
        </Box>

        <Modal opened={opened} size="xl" onClose={close} withCloseButton={true}>
          <SimpleGrid
            cols={1}
            spacing="md"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <Card
              withBorder
              radius="md"
              p="xl"
              style={{ padding: "9px !important" }}
            >
              <div className="mantine-Badge-root mantine-rhsza5">
                {/* <span className="mantine-h9iq4m mantine-Badge-inner">
              File Upload
            </span> */}
              </div>
              {/* <Text
            variant="gradient"
            gradient={{ from: "gold", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            weight={650}
            style={{ marginTop: 20 }}
            size={25}
            className={classes.description}
            align="center"
          >
            Central Document Request-Types Logs
          </Text> */}

              <Accordion
                variant="separated"
                radius="md"
                defaultValue="customization"
                transitionDuration={1000}
              >
                <Card>
                  <Accordion.Item value="c">
                    <Accordion.Control>
                      Modify the <strong>quantity</strong> of this item{" "}
                      <strong>
                        {" "}
                        <span style={{ color: "lime" }}>with Invoice 🗃️ </span>
                      </strong>{" "}
                      Attachement.
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Text
                        size="sm"
                        mt="sm"
                        color="dimmed"
                        style={{ padding: 20 }}
                      >
                        Please upload the <strong>INVOICES</strong> from the
                        vendors or suppliers for each of the focused items. The
                        text extraction will be automated by the
                        <strong> built-in AI</strong>. If changes are necessary,
                        you should also validate the input. Please take note
                        that{" "}
                        <strong>
                          {" "}
                          numbers for the quantity, amount, and invoices number{" "}
                        </strong>{" "}
                        will be generated for storage. The portable document
                        format
                        <strong> (.PDF)</strong> format is appropriate.
                      </Text>

                      <div>
                        {" "}
                        <Dragger {...props} style={{ padding: 0, margin: 40 }}>
                          <p className="ant-upload-drag-icon">
                            {/* <InboxOutlined /> */}
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly{" "}
                            <strong>.PDF</strong> files
                          </p>
                        </Dragger>
                      </div>

                      <Grid mt={20}>
                        <Grid.Col span="auto">
                          <div id="ResumeContainer" style={{ marginTop: 0 }}>
                            <Document
                              file={pdf}
                              className={
                                loading ? "PDFDocument" : "PDFDocument"
                              }
                              //  onLoadSuccess={onDocumentLoadSuccess}
                              // className={loadings ? "scan" : ""}
                            >
                              <Page
                                width={550}
                                className={"PDFPage PDFPageOne"}
                                pageNumber={1}
                                scale={2.5}
                                height={900}
                              />
                            </Document>
                            <br />
                          </div>
                        </Grid.Col>
                      </Grid>

                      {!loading ? (
                        <>
                          <Skeleton height={20} radius="md" />
                          <Skeleton height={20} mt={6} radius="md" />
                          <Skeleton
                            height={20}
                            mt={6}
                            width="85%"
                            radius="md"
                          />
                          <Skeleton
                            height={20}
                            mt={6}
                            width="70%"
                            radius="md"
                          />
                          <Skeleton
                            height={20}
                            mt={6}
                            width="85%"
                            radius="md"
                          />
                          <Skeleton
                            height={20}
                            mt={6}
                            width="90%"
                            radius="md"
                          />
                        </>
                      ) : (
                        <Card>
                          <form onSubmit={form.onSubmit(handleSubmit)}>
                            <Stack>
                              <TextInput
                                placeholder="Vendors Name"
                                label="Vendors Name"
                                radius="lg"
                                {...form.getInputProps("vendorName")}
                                rightSection={
                                  <Badge
                                    variant="gradient"
                                    gradient={{ from: "indigo", to: "cyan" }}
                                  >
                                    {vendorNameConfidence}%
                                  </Badge>
                                }
                                rightSectionWidth={80}
                                value={
                                  vendorName !== undefined
                                    ? vendorName.toUpperCase()
                                    : "NIL"
                                }
                                onChange={(e) => setVendorName(e.target.value)}
                              />
                              <TextInput
                                placeholder="Vendors Address"
                                label="Vendors Address"
                                radius="lg"
                                {...form.getInputProps("vendorAddress")}
                                rightSection={
                                  <Badge
                                    variant="gradient"
                                    gradient={{ from: "indigo", to: "cyan" }}
                                  >
                                    {vendorNameConfidence}%
                                  </Badge>
                                }
                                rightSectionWidth={80}
                                value={
                                  vendorAddress !== undefined
                                    ? vendorAddress.toUpperCase()
                                    : "NIL"
                                }
                                onChange={(e) =>
                                  setVendorAddress(e.target.value)
                                }
                              />{" "}
                              {/* <TextInput
                            placeholder="Customer name"
                            label="Customer name"
                            radius="lg"
                            {...form.getInputProps("customerName")}
                            value={
                              customerName !== undefined
                                ? customerName.toUpperCase()
                                : "NIL"
                            }
                          /> */}
                              <DatePicker
                                placeholder="Invoice Date"
                                label="Invoice Date"
                                withAsterisk
                                inputFormat="DD MMM YYYY"
                                labelFormat="MM/YYYY"
                                value={invoiceDate}
                                // renderDay={(date) => {
                                //   const day = date.getDate();

                                //   return <div>{day || null}</div>;
                                // }}
                                // defaultValue={invoiceDate}
                                onChange={(e: any) => {
                                  setInvoiceDate(e);
                                }}
                              />
                              {/* <NumberInput
                            style={{ marginTop: 15 }}
                            label="Invoice Number"
                            hideControls
                            defaultValue={invoiceNumber}
                            // value={}
                            {...form.getInputProps("invoiceNumber")}
                          /> */}
                              <TextInput
                                placeholder="Invoice Number"
                                label="Invoice Number"
                                radius="lg"
                                {...form.getInputProps("invoiceNumber")}
                                // rightSection={
                                //   <Badge
                                //     variant="gradient"
                                //     gradient={{ from: "indigo", to: "cyan" }}
                                //   >
                                //     {vendorNameConfidence}%
                                //   </Badge>
                                // }
                                rightSectionWidth={80}
                                value={
                                  invoiceNumber !== undefined
                                    ? invoiceNumber
                                    : 0
                                }
                                onChange={(e: any) =>
                                  //console.log("fvf")
                                  setInvoiceNumber(e.target.value)
                                }
                              />
                              <NumberInput
                                label="Unit Price"
                                // defaultValue={1000}
                                {...form.getInputProps("unitPrice")}
                                // defaultValue={totalAmount}
                                value={unitPrice !== undefined ? unitPrice : 0}
                                parser={(value: any) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                // formatter={(value: any) =>
                                //   !Number.isNaN(parseFloat(value))
                                //     ? `₦ ${value}`.replace(
                                //         /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                //         ","
                                //       )
                                //     : " "
                                // }
                                onChange={(e: any) => {
                                  //   // console.log(e.target.value);
                                  setUnitPrice(+e);
                                }}
                              />
                              <NumberInput
                                label="Quantity"
                                // defaultValue={1000}
                                {...form.getInputProps("quantity")}
                                // defaultValue={totalAmount}
                                value={quantity !== undefined ? quantity : 0}
                                parser={(value: any) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                // formatter={(value: any) =>
                                //   !Number.isNaN(parseFloat(value))
                                //     ? `₦ ${value}`.replace(
                                //         /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                //         ","
                                //       )
                                //     : " "
                                // }
                                onChange={(e: any) => {
                                  setQuantity(+e);
                                }}
                              />
                              <NumberInput
                                label="Total Amount"
                                // defaultValue={1000}
                                {...form.getInputProps("totalAmount")}
                                // defaultValue={totalAmount}
                                value={
                                  result > 0
                                    ? result
                                    : 0 ||
                                      (totalAmount !== undefined
                                        ? totalAmount
                                        : 0)
                                }
                                parser={(value: any) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                hideControls
                                disabled
                                formatter={(value: any) =>
                                  !Number.isNaN(parseFloat(value))
                                    ? `₦ ${value}`.replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    : " "
                                }
                                onChange={(e: any) => {
                                  console.log(result);
                                  //   setTotalAmount(e);
                                  // if (result > 0) {
                                  //   setTotalAmount(result);
                                  // } else {
                                  //   setTotalAmount(e);
                                  // }
                                }}
                              />
                              {/* <> {items} </> */}
                            </Stack>
                            {/* <Tables>
                              <thead>
                                <tr>
                                  <th>Amount</th>
                                  <th>Quantity</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody> {arrs}</tbody>
                            </Tables> */}
                            <Button className="mt-5" type="submit">
                              Submit
                            </Button>
                          </form>
                        </Card>
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                </Card>

                <Card>
                  <Accordion.Item value="b">
                    <Accordion.Control>
                      Modify the quanitity of this item{" "}
                      <strong>
                        <span style={{ color: "red" }}>without</span>
                      </strong>{" "}
                      Invoice ❌ attachement{" "}
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Text
                        size="sm"
                        mt="sm"
                        color="dimmed"
                        style={{ padding: 20 }}
                      >
                        Filling out the parameters below will allow you to
                        change the quantity of this item even if there isn't an
                        invoice to attach.
                      </Text>
                      <Divider />

                      <Card>
                        <form onSubmit={form.onSubmit(handleSubmit)}>
                          <Stack>
                            <TextInput
                              placeholder="Vendors Name"
                              label="Vendors Name"
                              radius="lg"
                              {...form.getInputProps("vendorName")}
                              value={
                                vendorName !== undefined
                                  ? vendorName.toUpperCase()
                                  : "NIL"
                              }
                              onChange={(e) => setVendorName(e.target.value)}
                            />
                            <TextInput
                              placeholder="Vendors Address"
                              label="Vendors Address"
                              radius="lg"
                              {...form.getInputProps("vendorAddress")}
                              value={
                                vendorAddress !== undefined
                                  ? vendorAddress.toUpperCase()
                                  : "NIL"
                              }
                              onChange={(e) => setVendorAddress(e.target.value)}
                            />{" "}
                            {/* <TextInput
                            placeholder="Customer name"
                            label="Customer name"
                            radius="lg"
                            {...form.getInputProps("customerName")}
                            value={
                              customerName !== undefined
                                ? customerName.toUpperCase()
                                : "NIL"
                            }
                          /> */}
                            <DatePicker
                              placeholder="Invoice Date"
                              label="Invoice Date"
                              withAsterisk
                              inputFormat="DD MMM YYYY"
                              labelFormat="MM/YYYY"
                              value={invoiceDate}
                              // renderDay={(date) => {
                              //   const day = date.getDate();

                              //   return <div>{day || null}</div>;
                              // }}
                              // defaultValue={invoiceDate}
                              onChange={(e: any) => {
                                setInvoiceDate(e);
                              }}
                            />
                            {/* <NumberInput
                            style={{ marginTop: 15 }}
                            label="Invoice Number"
                            hideControls
                            defaultValue={invoiceNumber}
                            // value={}
                            {...form.getInputProps("invoiceNumber")}
                          /> */}
                            <TextInput
                              placeholder="Invoice Number"
                              label="Invoice Number"
                              radius="lg"
                              {...form.getInputProps("invoiceNumber")}
                              // rightSection={
                              //   <Badge
                              //     variant="gradient"
                              //     gradient={{ from: "indigo", to: "cyan" }}
                              //   >
                              //     {vendorNameConfidence}%
                              //   </Badge>
                              // }
                              rightSectionWidth={80}
                              value={
                                invoiceNumber !== undefined ? invoiceNumber : 0
                              }
                              onChange={(e: any) =>
                                //console.log("fvf")
                                setInvoiceNumber(e.target.value)
                              }
                            />
                            <NumberInput
                              label="Unit Price"
                              // defaultValue={1000}
                              {...form.getInputProps("unitPrice")}
                              // defaultValue={totalAmount}
                              value={unitPrice !== undefined ? unitPrice : 0}
                              parser={(value: any) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              // formatter={(value: any) =>
                              //   !Number.isNaN(parseFloat(value))
                              //     ? `₦ ${value}`.replace(
                              //         /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                              //         ","
                              //       )
                              //     : " "
                              // }
                              onChange={(e: any) => {
                                //   // console.log(e.target.value);
                                setUnitPrice(+e);
                              }}
                            />
                            <NumberInput
                              label="Quantity"
                              // defaultValue={1000}
                              {...form.getInputProps("quantity")}
                              // defaultValue={totalAmount}
                              value={quantity !== undefined ? quantity : 0}
                              parser={(value: any) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              // formatter={(value: any) =>
                              //   !Number.isNaN(parseFloat(value))
                              //     ? `₦ ${value}`.replace(
                              //         /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                              //         ","
                              //       )
                              //     : " "
                              // }
                              onChange={(e: any) => {
                                setQuantity(+e);
                              }}
                            />
                            <NumberInput
                              label="Total Amount"
                              // defaultValue={1000}
                              {...form.getInputProps("totalAmount")}
                              // defaultValue={totalAmount}
                              value={
                                result > 0
                                  ? result
                                  : 0 ||
                                    (totalAmount !== undefined
                                      ? totalAmount
                                      : 0)
                              }
                              parser={(value: any) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              hideControls
                              disabled
                              formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                  ? `₦ ${value}`.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  : " "
                              }
                              onChange={(e: any) => {
                                console.log(result);
                                //   setTotalAmount(e);
                                // if (result > 0) {
                                //   setTotalAmount(result);
                                // } else {
                                //   setTotalAmount(e);
                                // }
                              }}
                            />
                            {/* <> {items} </> */}
                          </Stack>
                          {/* <Tables>
                              <thead>
                                <tr>
                                  <th>Amount</th>
                                  <th>Quantity</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody> {arrs}</tbody>
                            </Tables> */}
                          <Button className="mt-5" type="submit">
                            Submit
                          </Button>
                        </form>
                      </Card>
                      {/* <Dragger {...props} >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger> */}

                      {/* 
                      <Grid mt={20}>
                        <Grid.Col span="auto">
                          <div id="ResumeContainer" style={{ marginTop: 0 }}>
                            <Document
                              file="https://frstore.blob.core.windows.net/frisops/35165-35165__FIRST%20REGISTRAR%20EMANDATE.PDF"
                              // className={loadings ? "scan" : "PDFDocument"}
                              //  onLoadSuccess={onDocumentLoadSuccess}
                              // className={loadings ? "scan" : ""}
                            >
                              <Page
                                width={550}
                                className={"PDFPage PDFPageOne"}
                                pageNumber={1}
                                scale={2.5}
                                height={900}
                              />
                            </Document>
                          </div>
                        </Grid.Col>
                      </Grid> */}
                    </Accordion.Panel>
                  </Accordion.Item>
                </Card>
              </Accordion>
            </Card>

            {/* <Grid gutter="md">
            <Grid.Col>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid> */}
          </SimpleGrid>

          {/* <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          sx={{
            width: "100%",
            gap: "24px",
          }}
        >
          {columns.map((column) => (
            <TextInput
              key={column.accessorKey}
              label={column.header}
              name={column.accessorKey}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
            />
          ))}
        </Stack>
      </form> */}

          {/* <Card withBorder mt={20} radius="md" shadow="md">
            Biodun
          </Card> */}

          <Flex
            sx={{
              padding: "20px",
              width: "100%",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            {/* <Button onClick={onClose} variant="subtle">
          Cancel
        </Button>
        <Button color="teal" onClick={handleSubmit} variant="filled">
          Create New Account
        </Button> */}
          </Flex>
          {/* </Dialog> */}
        </Modal>

        <Box
          sx={(theme) => ({
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
            backgroundColor: theme.colors.gold,
            // backgroundImage: `linear-gradient(135deg, ${
            //   theme.colors[theme.colors.blue[3]]
            // } 0%, ${theme.colors.gray[4]} 100%)`,
          })}
        >
          <Card shadow="sm" radius="md">
            <Button
              className="mb-5"
              onClick={open}
              leftIcon={<IconCirclePlus />}
            >
              Add Quantity
            </Button>

            <Text ta="center" className="mt-3 mb-5">
              Quantity Logs
            </Text>

            <Table columns={columns} dataSource={vendorsInfo} />
          </Card>
        </Box>
      </SimpleGrid>

      <div className="secondHalf">
        <div className="custom-shape-divider-bottom-1671865759">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>
  );
};

// interface Props {
//   columns: MRT_ColumnDef<Person>[];
//   onClose: () => void;
//   valuesprops: any;
//   onSubmit: (values: Person) => void;
//   open: boolean;
//   close: () => void;
//   overlayProps: any;
// }

//example of creating a mantine dialog modal for creating new rows
// export const CreateNewAccountModal = ({
//   open,
//   close,
//   columns,
//   onClose,
//   valuesprops,
//   overlayProps,
//   onSubmit,
// }: Props) => {
//   const [values, setValues] = useState<any>(() =>
//     columns.reduce((acc, column) => {
//       acc[column.accessorKey ?? ""] = "";
//       return acc;
//     }, {} as any)
//   );

//   //const [opened, { open, close }] = useDisclosure(false);

//   let { uuid } = useParams();
//   let { user } = useSelector((state: any) => ({ ...state }));

//   const [pdf, setPdf] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [vendorName, setVendorName] = useState("");
//   const [vendorAddress, setVendorAddress] = useState("");
//   const [customerName, setCustomerName] = useState("");
//   const [invoiceDate, setInvoiceDate] = useState(new Date());
//   const [items, setItems] = useState([]);
//   const [itemsAmount, setItemAmount] = useState(0);

//   const [vendorNameConfidence, setVendorNameConfidence] = useState(0);
//   const [invoiceNumber, setInvoiceNumber] = useState(0);
//   const [totalAmount, setTotalAmount] = useState(0);
//   ///console.log(uuid);

//   //console.log(user.name);

//   const handleSubmit = () => {
//     //put your validation logic here
//     onSubmit(values);
//     onClose();
//   };

//   const props: UploadProps = {
//     //name: "file",
//     multiple: false,
//     accept: ".pdf",
//     listType: "picture",
//     action: `http://localhost:5000/upload/invoice/?uuid=${uuid}&who_uploaded=${user.name}`,
//     //   headers: {
//     //     token: "token",
//     //     "Content-Type": "multipart/form-data"
//     //   },

//     onChange(info: any) {
//       const { status, response } = info.file;

//       if (status !== "uploading") {
//         message.loading("Action in progress..", 2.9);
//         message.loading("Scanning in progress..", 1.5);
//       }

//       // if (response.url) {
//       //   setLoading(true);
//       // }

//       if (status === "done") {
//         setLoading(true);
//         console.log(response);
//         setVendorName(response.result.fields.VendorName.content);
//         setVendorAddress(response.result.fields.VendorAddress.content);
//         //setCustomerName(response.result.fields.CustomerName.content);
//         setItems(response.result.fields.Items.values);
//         setVendorNameConfidence(
//           parseFloat(response.result.fields.VendorName.confidence) * 100
//         );
//         setInvoiceDate(new Date(response.result.fields.InvoiceDate.content));
//         setTotalAmount(response.result.fields.InvoiceTotal.value.amount);
//         setInvoiceNumber(response.result.fields.InvoiceId.content);

//         console.log(response.result.fields.InvoiceId.content);

//         setPdf(response.url);

//         message.success(`${info.file.name} file uploaded successfully.`);
//       } else if (status === "error") {
//         setLoading(false);
//         message.error(`${info.file.name} - ${status} file upload failed.`);
//       }
//     },

//     onDrop(e: any) {
//       console.log("Dropped files", e.dataTransfer.files);
//     },
//   };

//   const form = useForm({
//     initialValues: {
//       vendorName: vendorName,
//       vendorAddress: vendorAddress,
//       customerName: customerName,
//       invoiceNumber: invoiceNumber,
//       totalAmount: totalAmount,
//       amount: 0,
//       // items: [{ name: itemsAmount, active: false, key: randomId() }],
//     },

//     // validate: {
//     //   email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
//     //   password: (val) =>
//     //     val.length <= 6
//     //       ? "Password should include at least 6 characters"
//     //       : null,
//     // },
//   });

//   return (
//     <Modal
//       opened={open}
//       size="xl"
//       // title={`Pick an option to modify the item's quantity -  ${
//       //   valuesprops === undefined ? "" : valuesprops.description
//       // }`}
//       // overlayProps={{
//       //   // color:
//       //   //   theme.colorvaluScheme === "dark"
//       //   //     ? theme.colors.dark[9]
//       //   //     : theme.colors.gray[2],
//       //   // opacity: 0.55,
//       //   // blur: 3,
//       // }}
//       onClose={close}
//       withCloseButton={true}
//     >
//       <SimpleGrid
//         cols={1}
//         spacing="md"
//         breakpoints={[{ maxWidth: "sm", cols: 1 }]}
//       >
//         <Card
//           withBorder
//           radius="md"
//           p="xl"
//           style={{ padding: "9px !important" }}
//         >
//           <div className="mantine-Badge-root mantine-rhsza5">
//             {/* <span className="mantine-h9iq4m mantine-Badge-inner">
//               File Upload
//             </span> */}
//           </div>
//           {/* <Text
//             variant="gradient"
//             gradient={{ from: "gold", to: "cyan", deg: 45 }}
//             sx={{ fontFamily: "Greycliff CF, sans-serif" }}
//             weight={650}
//             style={{ marginTop: 20 }}
//             size={25}
//             className={classes.description}
//             align="center"
//           >
//             Central Document Request-Types Logs
//           </Text> */}

//           <Accordion
//             variant="separated"
//             radius="md"
//             defaultValue="customization"
//             transitionDuration={1000}
//           >
//             <Card>
//               <Accordion.Item value="c">
//                 <Accordion.Control>
//                   Modify the <strong>quantity</strong> of this item{" "}
//                   <strong>with</strong> Invoice Attachement
//                 </Accordion.Control>
//                 <Accordion.Panel>
//                   <Text
//                     size="sm"
//                     mt="sm"
//                     color="dimmed"
//                     style={{ padding: 20 }}
//                   >
//                     Please upload the <strong>INVOICES</strong> from the vendors
//                     or suppliers for each of the focused items. The text
//                     extraction will be automated by the
//                     <strong> built-in AI</strong>. If changes are necessary, you
//                     should also validate the input. Please take note that{" "}
//                     <strong>
//                       {" "}
//                       numbers for the quantity, amount, and invoices number{" "}
//                     </strong>{" "}
//                     will be generated for storage. The portable document format
//                     <strong> (.PDF)</strong> format is appropriate.
//                   </Text>

//                   {/* <Dragger {...props} >
//             <p className="ant-upload-drag-icon">
//               <InboxOutlined />
//             </p>
//             <p className="ant-upload-text">
//               Click or drag file to this area to upload
//             </p>
//             <p className="ant-upload-hint">
//               Support for a single or bulk upload. Strictly prohibit from
//               uploading company data or other band files
//             </p>
//           </Dragger> */}
//                   <div>
//                     {" "}
//                     <Dragger {...props} style={{ padding: 0, margin: 40 }}>
//                       <p className="ant-upload-drag-icon">
//                         {/* <InboxOutlined /> */}
//                       </p>
//                       <p className="ant-upload-text">
//                         Click or drag file to this area to upload
//                       </p>
//                       <p className="ant-upload-hint">
//                         Support for a single or bulk upload. Strictly{" "}
//                         <strong>.PDF</strong> files
//                       </p>
//                     </Dragger>
//                   </div>

//                   <Grid mt={20}>
//                     <Grid.Col span="auto">
//                       <div id="ResumeContainer" style={{ marginTop: 0 }}>
//                         <Document
//                           file={pdf}
//                           className={loading ? "PDFDocument" : "PDFDocument"}
//                           //  onLoadSuccess={onDocumentLoadSuccess}
//                           // className={loadings ? "scan" : ""}
//                         >
//                           <Page
//                             width={550}
//                             className={"PDFPage PDFPageOne"}
//                             pageNumber={1}
//                             scale={2.5}
//                             height={900}
//                           />
//                         </Document>
//                       </div>
//                     </Grid.Col>
//                   </Grid>

//                   {!loading ? (
//                     <>
//                       <Skeleton height={20} radius="md" />
//                       <Skeleton height={20} mt={6} radius="md" />
//                       <Skeleton height={20} mt={6} width="85%" radius="md" />
//                       <Skeleton height={20} mt={6} width="70%" radius="md" />
//                       <Skeleton height={20} mt={6} width="85%" radius="md" />
//                       <Skeleton height={20} mt={6} width="90%" radius="md" />
//                     </>
//                   ) : (
//                     <Card>
//                       <form>
//                         <Stack>
//                           <TextInput
//                             placeholder="Vendors Name"
//                             label="Vendors Name"
//                             radius="lg"
//                             {...form.getInputProps("vendorName")}
//                             rightSection={
//                               <Badge
//                                 variant="gradient"
//                                 gradient={{ from: "indigo", to: "cyan" }}
//                               >
//                                 {vendorNameConfidence}%
//                               </Badge>
//                             }
//                             rightSectionWidth={80}
//                             value={
//                               vendorName !== undefined
//                                 ? vendorName.toUpperCase()
//                                 : "NIL"
//                             }
//                           />
//                           <TextInput
//                             placeholder="Vendors Address"
//                             label="Vendors Address"
//                             radius="lg"
//                             {...form.getInputProps("vendorAddress")}
//                             rightSection={
//                               <Badge
//                                 variant="gradient"
//                                 gradient={{ from: "indigo", to: "cyan" }}
//                               >
//                                 {vendorNameConfidence}%
//                               </Badge>
//                             }
//                             rightSectionWidth={80}
//                             value={
//                               vendorAddress !== undefined
//                                 ? vendorAddress.toUpperCase()
//                                 : "NIL"
//                             }
//                           />{" "}
//                           {/* <TextInput
//                             placeholder="Customer name"
//                             label="Customer name"
//                             radius="lg"
//                             {...form.getInputProps("customerName")}
//                             value={
//                               customerName !== undefined
//                                 ? customerName.toUpperCase()
//                                 : "NIL"
//                             }
//                           /> */}
//                           <DatePicker
//                             placeholder="Invoice Date"
//                             label="Invoice Date"
//                             withAsterisk
//                             inputFormat="DD MMM YYYY"
//                             // labelFormat="MM/YYYY"
//                             value={invoiceDate}
//                             defaultValue={invoiceDate}
//                           />
//                           {/* <NumberInput
//                             style={{ marginTop: 15 }}
//                             label="Invoice Number"
//                             hideControls
//                             defaultValue={invoiceNumber}
//                             // value={}
//                             {...form.getInputProps("invoiceNumber")}
//                           /> */}
//                           <TextInput
//                             placeholder="Invoice Number"
//                             label="Invoice Number"
//                             radius="lg"
//                             {...form.getInputProps("invoiceNumber")}
//                             // rightSection={
//                             //   <Badge
//                             //     variant="gradient"
//                             //     gradient={{ from: "indigo", to: "cyan" }}
//                             //   >
//                             //     {vendorNameConfidence}%
//                             //   </Badge>
//                             // }
//                             rightSectionWidth={80}
//                             value={
//                               vendorName !== undefined ? invoiceNumber : "NIL"
//                             }
//                           />
//                           <NumberInput
//                             label="Total Amount"
//                             // defaultValue={1000}
//                             {...form.getInputProps("totalAmount")}
//                             defaultValue={totalAmount}
//                             value={totalAmount}
//                             parser={(value: any) =>
//                               value.replace(/\$\s?|(,*)/g, "")
//                             }
//                             formatter={(value: any) =>
//                               !Number.isNaN(parseFloat(value))
//                                 ? `₦ ${value}`.replace(
//                                     /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
//                                     ","
//                                   )
//                                 : "$ "
//                             }
//                           />
//                           {/* <> {items} </> */}
//                           <Table>
//                             <thead>
//                               <tr>
//                                 <th>Amount</th>
//                                 <th>Quantity</th>
//                                 <th>Description</th>
//                               </tr>
//                             </thead>
//                             <tbody> {arrs}</tbody>
//                           </Table>
//                         </Stack>
//                       </form>
//                     </Card>
//                   )}
//                 </Accordion.Panel>
//               </Accordion.Item>
//             </Card>

//             <Card>
//               <Accordion.Item value="b">
//                 <Accordion.Control>
//                   Modify the quanitity of this item <strong>without</strong>{" "}
//                   Invoice attachement{" "}
//                 </Accordion.Control>
//                 <Accordion.Panel>
//                   <Text
//                     size="sm"
//                     mt="sm"
//                     color="dimmed"
//                     style={{ padding: 20 }}
//                   >
//                     Please upload the <strong>INVOICES</strong> from the vendors
//                     or suppliers for each of the focused items. The text
//                     extraction will be automated by the
//                     <strong> built-in AI</strong>. If changes are necessary, you
//                     should also validate the input. Please take note that{" "}
//                     <strong>
//                       {" "}
//                       numbers for the quantity, amount, and invoices number{" "}
//                     </strong>{" "}
//                     will be generated for storage. The portable document format
//                     <strong> (.PDF)</strong> format is appropriate.
//                   </Text>

//                   {/* <Dragger {...props} >
//             <p className="ant-upload-drag-icon">
//               <InboxOutlined />
//             </p>
//             <p className="ant-upload-text">
//               Click or drag file to this area to upload
//             </p>
//             <p className="ant-upload-hint">
//               Support for a single or bulk upload. Strictly prohibit from
//               uploading company data or other band files
//             </p>
//           </Dragger> */}
//                   <div>
//                     {" "}
//                     <Dragger style={{ padding: 0, margin: 40 }}>
//                       <p className="ant-upload-drag-icon">
//                         {/* <InboxOutlined /> */}
//                       </p>
//                       <p className="ant-upload-text">
//                         Click or drag file to this area to upload
//                       </p>
//                       <p className="ant-upload-hint">
//                         Support for a single or bulk upload. Strictly{" "}
//                         <strong>.PDF</strong> files
//                       </p>
//                     </Dragger>
//                   </div>

//                   <Grid mt={20}>
//                     <Grid.Col span="auto">
//                       <div id="ResumeContainer" style={{ marginTop: 0 }}>
//                         <Document
//                           file="https://frstore.blob.core.windows.net/frisops/35165-35165__FIRST%20REGISTRAR%20EMANDATE.PDF"
//                           // className={loadings ? "scan" : "PDFDocument"}
//                           //  onLoadSuccess={onDocumentLoadSuccess}
//                           // className={loadings ? "scan" : ""}
//                         >
//                           <Page
//                             width={550}
//                             className={"PDFPage PDFPageOne"}
//                             pageNumber={1}
//                             scale={2.5}
//                             height={900}
//                           />
//                         </Document>
//                       </div>
//                     </Grid.Col>
//                   </Grid>
//                 </Accordion.Panel>
//               </Accordion.Item>
//             </Card>
//           </Accordion>
//         </Card>

//         {/* <Grid gutter="md">
//             <Grid.Col>
//               <Skeleton
//                 height={SECONDARY_COL_HEIGHT}
//                 radius="md"
//                 animate={false}
//               />
//             </Grid.Col>
//             <Grid.Col span={6}>
//               <Skeleton
//                 height={SECONDARY_COL_HEIGHT}
//                 radius="md"
//                 animate={false}
//               />
//             </Grid.Col>
//             <Grid.Col span={6}>
//               <Skeleton
//                 height={SECONDARY_COL_HEIGHT}
//                 radius="md"
//                 animate={false}
//               />
//             </Grid.Col>
//           </Grid> */}
//       </SimpleGrid>

//       {/* <form onSubmit={(e) => e.preventDefault()}>
//         <Stack
//           sx={{
//             width: "100%",
//             gap: "24px",
//           }}
//         >
//           {columns.map((column) => (
//             <TextInput
//               key={column.accessorKey}
//               label={column.header}
//               name={column.accessorKey}
//               onChange={(e) =>
//                 setValues({ ...values, [e.target.name]: e.target.value })
//               }
//             />
//           ))}
//         </Stack>
//       </form> */}

//       <Card withBorder mt={20} radius="md" shadow="md">
//         Biodun
//       </Card>

//       <Flex
//         sx={{
//           padding: "20px",
//           width: "100%",
//           justifyContent: "flex-end",
//           gap: "16px",
//         }}
//       >
//         {/* <Button onClick={onClose} variant="subtle">
//           Cancel
//         </Button>
//         <Button color="teal" onClick={handleSubmit} variant="filled">
//           Create New Account
//         </Button> */}
//       </Flex>
//       {/* </Dialog> */}
//     </Modal>
//   );
// };

// const validateRequired = (value: string) => !!value.length;
// const validateEmail = (email: string) =>
//   !!email.length &&
//   email
//     .toLowerCase()
//     .match(
//       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//     );
// const validateAge = (age: number) => age >= 18 && age <= 50;

export default ConsumblesLogs;
