import { useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Breadcrumbs,
  Anchor,
  Title,
  Divider,
  Card,
  Menu,
  Stack,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconUpload,
  IconPencilPlus,
  IconBell,
  IconScanEye,
  IconClipboardText,
  IconSettings,
  IconBellRinging,
  IconGitPullRequest,
  IconHome,
  IconFileLike,
  IconBooks,
  IconCheck,
  IconStack2,
  IconStackPush,
  IconUsers,
  IconMessageCircle,
  IconLogout,
  IconArrowNarrowLeft,
  IconSwitchHorizontal,
} from "@tabler/icons";
import { UserButton } from "../../../utils/UserButton";
import { LinksGroup, NavbarLinksGroup } from "../../../utils/LinksGroup";
import { LinksGroups } from "../../../utils/LinkIconOnly";
// import TabsDashboard from "./TabsDashboard";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import StatisticsView from "./StatisticsView";
import { HeroHeader } from "./HeroHeader";
import { GridLayout } from "./GridLayout";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { useIdle } from "@mantine/hooks";

//import MigratationFile from "./MigratationFile";
// import CertificateUpload from "./CertificateUpload";

// import Bar from "../progressBar/Bar";
// import Containers from "../progressBar/Container";
// import { useNProgress } from "@tanem/react-nprogress";

// import LoadingBar from "react-top-loading-bar";
// import { useSelector } from "react-redux";

const mockdata = [
  { label: "Dashboard", icon: IconGauge, linked: "/admin/dashboard" },

  {
    label: "Requisition",
    icon: IconGitPullRequest,
    linked: "/admin/requisition",
    // initiallyOpened: true,
    // links: [
    //   { label: "Document Table", link: "/doctable" },
    //   { label: "Transfer Module.", link: "/transfer" },
    // ],
  },

  {
    label: "Add Items",
    icon: IconPencilPlus,
    linked: "/admin/add-items",
    // initiallyOpened: true,
    // links: [
    //   { label: "Document Table", link: "/doctable" },
    //   { label: "Transfer Module.", link: "/transfer" },
    // ],
  },

  {
    label: "Consumables",
    icon: IconStack2,
    linked: "/admin/consumables",
    // initiallyOpened: true,
    // links: [
    //   { label: "Document Table", link: "/doctable" },
    //   { label: "Transfer Module.", link: "/transfer" },
    // ],
  },

  {
    label: "Notifications",
    icon: IconBellRinging,
    // initiallyOpened: true,
    links: [
      { label: "Pending Logs (P)", link: "/admin/notification/pendinglogs" },

      { label: "Issued Logs", link: "/admin/notification/treatedlogs" },
      //{ label: "Declined Logs", link: "/" },
      { label: "Released Logs", link: "/admin/notification/releasedlogs" },
    ],
  },

  {
    label: "Data Migration ",
    icon: IconStackPush,
    linked: "/admin/migration",
    // initiallyOpened: true,
    // links: [
    //   { label: "Document Table", link: "/doctable" },
    //   { label: "Transfer Module.", link: "/transfer" },
    // ],
  },

  // {
  //   label: "Manage Users'",
  //   icon: IconUsers,
  //   linked: "/admin/reports",
  //   // initiallyOpened: true,
  //   // links: [
  //   //   { label: "Document Table", link: "/doctable" },
  //   //   { label: "Transfer Module.", link: "/transfer" },
  //   // ],
  // },

  {
    label: "Reports",
    icon: IconClipboardText,
    linked: "/admin/reports",
    // initiallyOpened: true,
    // links: [
    //   { label: "History (Items Collected)", link: "/doctable" },
    //   { label: "Consumables Logs", link: "/transfer" },
    // ],
  },

  {
    label: "Settings",
    icon: IconSettings,
    linked: "/admin/settings",
  },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.white,
    paddingBottom: 0,
    // position: "relative",
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  link: {
    width: "55px",
    height: "55px",
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  active: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `2px solid ${
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.yellow[5]
    }`,
  },
  avatars: {
    border: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },

  footers: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `2px solid ${
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.yellow[5]
    }`,
  },
}));

const items = [
  { title: <IconHome />, href: "/", end: "false" },
  { title: "Correspondence", href: "", end: "true" },
].map((item, index) => (
  <Anchor component={Link} to={item.href} key={index}>
    {item.title}
  </Anchor>
));

export default function MainDashboard() {
  // Let user  = null

  let { user } = useSelector((state: any) => ({ ...state }));

  let { show } = useSelector((state: any) => ({ ...state }));

  const [active, setActive] = useState(2);

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [menuSetup, setMenuStep] = useState(true);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const links = mockdata.map((item) => (
    <>
      <LinksGroup {...item} label={item.label} to={item.links} />
    </>
  ));

  // const linkMenu = mockdata.map((item) => (
  //   <>
  //     <LinksGroup {...item} to={item.links} />
  //   </>
  // ));

  interface NavbarLinkProps {
    icon: React.FC<any>;
    label: string;
    active?: boolean;
    onClick?(): void;
  }

  const Logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGOUT",
      payload: null,
    });

    localStorage.removeItem("User");

    navigate("/");
  };

  function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
    const { classes, cx } = useStyles();
    return (
      <Tooltip label={label} position="right">
        <UnstyledButton
          onClick={Logout}
          className={cx(classes.link, { [classes.active]: active })}
        >
          <Icon size="1.2rem" stroke={1.5} />
        </UnstyledButton>
      </Tooltip>
    );
  }

  const linkMenu = mockdata.map((item) => (
    <LinksGroups {...item} label={item.label} to={item.links} />
  ));

  let userName = user.name;

  let userDetail = userName.split(" ");

  const idle = useIdle(1000000);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.blue[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        menuSetup ? (
          <Navbar
            height="auto"
            width={{ sm: 260 }}
            p="md"
            hidden={!opened}
            className={classes.navbar}
          >
            <IconArrowNarrowLeft
              className="bg-slate-700 text-white absolute rounded-full -right-3 z-50 lg:block sm:hidden shadow-lg cursor-pointer"
              color="orange"
              onClick={(e) => {
                console.log(e);
                setMenuStep((value) => !value);
              }}
              size={27}
            />
            <Navbar.Section
              grow
              className={classes.links}
              component={ScrollArea}
            >
              <div className={classes.linksInner}>{links}</div>
            </Navbar.Section>

            <Navbar.Section className={classes.footer}>
              <UserButton
                image={
                  user !== undefined
                    ? "data:image/png;base64," + user.pics
                    : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                }
                name="Admin's Role"
                email={
                  user.email !== undefined
                    ? user.email.substring(0, 22).concat("...")
                    : ""
                }
              />
            </Navbar.Section>
          </Navbar>
        ) : (
          <>
            <Navbar
              height="auto"
              width={{ base: 80 }}
              p="md"
              hidden={!opened}
              className={classes.navbar}
            >
              <IconArrowNarrowRight
                className="bg-slate-700 text-white absolute rounded-full -right-3 shadow-lg cursor-pointer"
                color="orange"
                style={{ transition: "ease-in-out" }}
                onClick={(e) => {
                  // console.log(e);
                  setMenuStep((value) => !value);
                }}
                size={27}
              />
              <Navbar.Section grow mt={50}>
                <Stack justify="center" spacing={0}>
                  {linkMenu}
                </Stack>
              </Navbar.Section>
              <Navbar.Section
                style={{ marginBottom: "40px" }}
                className={classes.footers}
              >
                <Stack justify="center" spacing={0}>
                  <NavbarLink icon={IconLogout} label={"Logout"} />
                </Stack>
              </Navbar.Section>
            </Navbar>
          </>
        )
      }
      footer={
        <Footer height={50} p="md">
          <Group className={classes.links} position="center">
            <Text align="center">© {new Date().getFullYear()}. </Text>
            <Text align="center">
              {" "}
              <>
                {" "}
                <strong> FRISPORTAL.</strong>
              </>
            </Text>
          </Group>

          {/* <Group spacing="xs" position="right" noWrap>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandTwitter size={12} stroke={1.5} />
            </ActionIcon>
          </Group> */}
        </Footer>
      }
      header={
        <>
          {/* <LoadingBar
            height={5}
            color="#2C5178"
            waitingTime={2000}
            progress={data.updates.values}
            onLoaderFinished={() => setProgress(0)}
          /> */}
          <Header height={70} p="md">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <Image
                src="https://i.ibb.co/ZgNdCXJ/logo.png"
                width={200}
                style={{ padding: 40 }}
              />

              {/* <Group>
                {" "}
                <Divider
                  orientation="vertical"
                  style={{ marginLeft: 20 }}
                  color="blue"
                />
                <Title
                  order={3}
                  style={{ marginLeft: "0px" }}
                  variant="gradient"
                  gradient={{ from: "blue", to: "gold", deg: 45 }}
                >
                  {" "}
                  Requisition Portal{" "}
                </Title>{" "}
                <Divider orientation="vertical" color="blue" />
              </Group> */}
            </div>
            <div style={{ marginTop: -35 }}>
              <Group position="right">
                <ActionIcon variant="transparent" color="blue">
                  <IconBell size={22} />
                </ActionIcon>

                <Menu
                  width={300}
                  shadow="md"
                  transition="pop-bottom-right"
                  transitionDuration={150}
                  withArrow
                  trigger="hover"
                  openDelay={100}
                  closeDelay={400}
                >
                  <Menu.Target>
                    <Avatar
                      radius="xl"
                      size="md"
                      style={{
                        boxShadow: `0 0 0 2px #c49b36`,
                        cursor: "pointer",
                      }}
                      variant="outline"
                      color="grape"
                      src={
                        user !== undefined
                          ? "data:image/png;base64," + user.pics
                          : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                      }
                      //"https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                    >
                      {userDetail[0].charAt(0) + "" + userDetail[1].charAt(0)}
                    </Avatar>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Card
                      withBorder
                      p="xl"
                      radius="md"
                      //className={}
                    >
                      <Card.Section
                        sx={{
                          backgroundImage: `url(https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80)`,
                          height: 140,
                        }}
                      />

                      <Avatar
                        src={
                          user !== undefined
                            ? "data:image/png;base64," + user.pics
                            : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                        }
                        size={80}
                        radius={80}
                        mx="auto"
                        mt={-30}
                        className={classes.avatars}
                      >
                        {userDetail[0].charAt(0) + "" + userDetail[1].charAt(0)}
                      </Avatar>

                      <Text align="center" size="lg" weight={500} mt="sm">
                        {user.name}
                      </Text>
                      <Text align="center" size="sm" color="dimmed">
                        {" "}
                        😎 Admin's Role
                      </Text>
                      <Menu.Label>{new Date().toDateString()} </Menu.Label>

                      <Menu.Divider />
                      <Menu.Label>Profile</Menu.Label>
                      <Menu.Item
                        icon={<IconSettings size={14} />}
                        onClick={() => console.log("log")}
                      >
                        Settings
                      </Menu.Item>
                      <Menu.Item icon={<IconMessageCircle size={14} />}>
                        Notification
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Label>Switch-off</Menu.Label>

                      <Menu.Item
                        color="red"
                        icon={<IconLogout size={14} />}
                        onClick={Logout}
                      >
                        Sign-out
                      </Menu.Item>
                    </Card>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </div>
          </Header>
        </>
      }
    >
      <Container>
        {idle ? <>{navigate("/")}</> : null}
        <Card shadow="md" radius="md">
          {/* <TabsDashboard /> */}

          <HeroHeader />

          <StatisticsView />
        </Card>

        <GridLayout />
      </Container>
    </AppShell>
  );
}

//  <Container my="xs">
//    <Card shadow="md" radius="md">
//      {/* <TabsDashboard /> */}
//      <HeroHeader />
//      <br /> <br />
//      <ConsumblesModule />
//    </Card>

//    {/* <GridLayout title={""} completed={0} total={0} description={""} /> */}
//  </Container>;
