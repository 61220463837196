import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Image,
  Anchor,
  Grid,
} from "@mantine/core";
import { Button as Buttons, message } from "antd";
import {
  CheckCircleTwoTone,
  HeartTwoTone,
  SmileTwoTone,
  LikeTwoTone,
  ApiTwoTone,
  AppstoreTwoTone,
  UserOutlined,
  WindowsFilled,
  TeamOutlined,
} from "@ant-design/icons";
import { TfiMicrosoftAlt } from "react-icons/tfi";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { auth, micrsoftAuthProvider } from "../../firebase";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 900,
    height: "100vh",
    backgroundSize: "cover",
    backgroundImage: "url(https://s4.gifyu.com/images/login_2.gif)",
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 900,
    maxWidth: 450,
    height: "100vh",
    paddingTop: 80,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
const Logins = () => {
  const { classes } = useStyles();

  const [loadings, setLoadings] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const microsoftLogin = async (index: any) => {
    setLoadings((prevLoadings) => {
      const newLoadings: any = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    auth
      .signInWithPopup(micrsoftAuthProvider)
      .then(async (userProfile: any) => {
        setTimeout(() => {
          setLoadings((prevLoadings) => {
            const newLoadings: any = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
          });
        }, 300000);

        //  console.log(micrsoftAuthProvider)

        const { user } = userProfile;

        const { credential } = userProfile;

        const idTokenResult = await user.getIdTokenResult();
        //console.log(credential.accessToken);

        await axios
          .post(
            // "http://localhost:4000/v1/loginuser",
            "https://fris-login.azurewebsites.net/v1/loginuser",
            // "https://frislogin.azurewebsites.net/v1/loginuser",
            //"https://firstregistrarsnigeria.com/login/v1/loginuser",
            {},
            {
              headers: {
                authtoken: idTokenResult.token,
                accesstoken: credential.accessToken,
              },
            }
          )
          .then((user: any) => {
            console.log(user.data.role);
            message
              // .loading("Action in progress..", 3.5)
              .loading(
                "Action is in progress. We're back and better !. 😂",
                4.5
              )
              .then(() => message.success("Login successfully", 3.5));

            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                email: user.data.email,
                name: user.data.name,
                pics: user.data.photo_url,
                isadmin: user.data.isadmin,
                delegate_role: user.data.delegate_role,
                token: credential.accessToken,
                role: user.data.role,
              },
            });

            if (user.data.role === "manager") {
              navigate("/manager/dashboard");
            } else if (user.data.role === "user") {
              navigate("/users/dashboard");
            } else if (user.data.role === "admin") {
              navigate("/admin/dashboard");
            } else if (user.data.role === "global") {
              navigate("/global/admin/dashboard");
            } else {
              navigate("/");
            }
          });
      })
      .catch((error) => {
        message.error({
          content: `Login Failed ! - ${error}`,
          duration: 4,
        });

        console.log(error.message);

        setTimeout(() => {
          setLoadings((prevLoadings) => {
            const newLoadings: any = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
          });
        }, 2000);
      });
  };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <div className="addButton">
          <Image
            width={130}
            height={90}
            fit="contain"
            className=""
            src="https://i.ibb.co/CQp6NZB/frislogo.jpg"
          />
        </div>
        <div style={{ marginTop: "141px" }}>
          <Title
            order={2}
            className={classes.title}
            align="center"
            size={20}
            mt="md"
            mb={50}
            fw={700}
          >
            Welcome back to{" "}
            <Title
              order={1}
              size={50}
              fw={800}
              className={classes.title}
              style={{ marginLeft: "0px" }}
              // variant="gradient"
              //   gradient={{ from: "blue", to: "orange", deg: 45 }}
            >
              FRIS PORTAL
            </Title>
          </Title>

          <Text mt="sm" ta={"center"}>
            A Web-based Requisition portal for effective Inventory Management.
            Please Sign in with your Microsoft Office 365 account. Also, keep in
            mind that only the Organizational Active account is permitted to use
            the solution.
          </Text>

          <Grid>
            <Grid.Col span="auto"></Grid.Col>
            <Grid.Col span={12} offset={3}>
              {" "}
              <Buttons
                loading={loadings[0]}
                onClick={() => microsoftLogin(0)}
                type="primary"
                className="button-85"
              >
                {" "}
                <WindowsFilled /> Login
              </Buttons>
            </Grid.Col>
            <Grid.Col span="auto"></Grid.Col>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default Logins;
