import { useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  Avatar,
  MediaQuery,
  Burger,
  useMantineTheme,
  createStyles,
  Group,
  Code,
  ScrollArea,
  Image,
  Container,
  ActionIcon,
  Breadcrumbs,
  Anchor,
  Title,
  Divider,
  Menu,
  Badge,
  Indicator,
  Card,
} from "@mantine/core";
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconUpload,
  IconBell,
  IconScanEye,
  IconHome,
  IconBooks,
  IconSettings,
  IconClipboardText,
  IconGitPullRequest,
  IconPencilPlus,
  IconBellRinging,
  IconSearch,
  IconPhoto,
  IconArrowsLeftRight,
  IconTrash,
  IconMessageCircle,
  IconAlertOctagon,
  IconCheck,
  IconLogout,
  IconUsers,
} from "@tabler/icons";
import { UserButton } from "../../../utils/UserButton";
import { LinksGroup, NavbarLinksGroup } from "../../../utils/LinksGroup";
// import TabsDashboard from "./TabsDashboard";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
//import SelectUI from "./SelectUI";
import { socket } from "../../../App";
import _ from "lodash";
//import { HeroHeader } from "./HeroHeader";
import { HeroHeader } from "./HeroHeader";
//import { GridLayout } from "./GridLayout";
import TransferModule from "./UserRoleModule";
import firebase from "firebase/compat/app";
import { IconUserShare } from "@tabler/icons-react";

// import CertificateUpload from "./CertificateUpload";

// import Bar from "../progressBar/Bar";
// import Containers from "../progressBar/Container";
// import { useNProgress } from "@tanem/react-nprogress";

// import LoadingBar from "react-top-loading-bar";
// import { useSelector } from "react-redux";

const mockdata = [
  { label: "Dashboard", icon: IconGauge, linked: "/global/admin/dashboard" },

  {
    label: "Notifications",
    icon: IconBellRinging,
    initiallyOpened: true,
    links: [
      { label: "Pending Logs", link: "/global/admin/pendinglogs" },
      { label: "Treated Logs", link: "/global/admin/treatedlog" },
      // { label: "Declined Logs", link: "/" },
      // { label: "Issued Logs", link: "/" },
    ],
  },
  {
    label: "Delegate User",
    icon: IconUserShare,
    linked: "/global/admin/transfer",
  },
  {
    label: "Manage User(s)",
    icon: IconUsers,
    linked: "/global/admin/manager-users",
  },
  { label: "Settings", icon: IconSettings, linked: "/global/admin/settings" },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.lime[4]
    }`,
  },

  avatars: {
    border: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
}));

// const Progress: React.FC<{ isAnimating: boolean }> = ({ isAnimating }) => {
//   const { animationDuration, isFinished, progress } = useNProgress({
//     isAnimating,
//   });

//   return (
//     <Containers animationDuration={animationDuration} isFinished={isFinished}>
//       <Bar animationDuration={animationDuration} progress={progress} />
//       {/*
//       This example doesn't use a spinner component so the UI stays
//       tidy. You're free to render whatever is appropriate for your
//       use-case.
//       */}
//     </Containers>
//   );
// };

const items = [
  { title: <IconHome />, href: "/", end: "false" },
  { title: "Correspondence", href: "", end: "true" },
].map((item, index) => (
  <Anchor component={Link} to={item.href} key={index}>
    {item.title}
  </Anchor>
));

export default function Layout() {
  let { user } = useSelector((state: any) => ({ ...state }));
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const location = useLocation();

  const [feeds, setFeeds] = useState([]);
  const [isNewFeed, setIsNewFeed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    socket.emit("initial_data");
    socket.on("get_data", getData);
    // socket.on("change_data", changeData);
    return () => {
      socket.off("get_data");
      socket.off("change_data");
    };
  }, []);

  const getData = (feeds: any) => {
    let filtered_array: any = _.filter(feeds, function (o: any) {
      if (o.requester_email == "adebay9.abiodun@firstregistrarsnigeria.com") {
        return o;
      } else if (o.approver_email == "YAYA.LAWAL@firstregistrarsnigeria.com") {
        return o;
      }
    });

    // console.log(filtered_array);

    if (
      filtered_array.length &&
      filtered_array.some((feed: any) => feed.read === false)
    ) {
      setIsNewFeed(true);
    } else {
      setIsNewFeed(false);
    }
    setFeeds(filtered_array);
  };

  // console.log(feeds);

  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const links = mockdata.map((item) => (
    <>
      <LinksGroup {...item} label={item.label} to={item.links} />
    </>
  ));

  const Logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGOUT",
      payload: null,
    });

    localStorage.removeItem("User");

    navigate("/");
  };

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.blue[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          height="auto"
          width={{ sm: 260 }}
          p="md"
          hidden={!opened}
          className={classes.navbar}
        >
          <Navbar.Section grow className={classes.links} component={ScrollArea}>
            <div className={classes.linksInner}>{links}</div>
          </Navbar.Section>
          <Navbar.Section className={classes.footer}>
            <UserButton
              image={
                user !== undefined
                  ? "data:image/png;base64," + user.pics
                  : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
              }
              name="Global's Role"
              email={
                user.email !== undefined
                  ? user.email.substring(0, 22).concat("...")
                  : ""
              }
            />
          </Navbar.Section>
        </Navbar>
      }
      footer={
        <Footer height={50} p="md">
          <Group className={classes.links} position="center">
            <Text align="center">© {new Date().getFullYear()}. </Text>
            <Text align="center">
              {" "}
              <>
                {" "}
                <strong> FRISPORTAL</strong>
              </>
            </Text>
          </Group>

          {/* <Group spacing="xs" position="right" noWrap>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandTwitter size={12} stroke={1.5} />
            </ActionIcon>
          </Group> */}
        </Footer>
      }
      header={
        <>
          {/* <LoadingBar
            height={5}
            color="#2C5178"
            waitingTime={2000}
            progress={data.updates.values}
            onLoaderFinished={() => setProgress(0)}
          /> */}
          <Header height={70} p="md">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <Image
                src="https://i.ibb.co/ZgNdCXJ/logo.png"
                width={200}
                style={{ padding: 40 }}
              />
            </div>
            <div style={{ marginTop: -35 }}>
              <Group position="right">
                <Indicator withBorder processing color="red" size={9}>
                  <Menu shadow="md" width={350} offset={3} withArrow>
                    <Menu.Target>
                      <ActionIcon variant="transparent" color="blue">
                        <IconBell size={22} />
                      </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Label>Notifications</Menu.Label>

                      {feeds.length ? (
                        feeds.map((feed: any) => {
                          return (
                            <>
                              <Menu.Item
                                icon={
                                  <IconAlertOctagon size={14} color={"red"} />
                                }
                              >
                                <>
                                  This control number -{" "}
                                  <strong>{feed.control_number} </strong>is
                                  being created by{" "}
                                  {feed.requester_email
                                    .split("@")[0]
                                    .toUpperCase()}{" "}
                                  {""}
                                  and needed the approval from -{" "}
                                  {feed.approver_email.split("@")[0]}
                                </>
                              </Menu.Item>
                            </>
                          );
                        })
                      ) : (
                        <Menu.Item key="nothing">
                          <p>No feeds to show!</p>
                        </Menu.Item>
                      )}

                      <Menu.Divider />

                      <Menu.Label>Danger zone</Menu.Label>
                    </Menu.Dropdown>
                  </Menu>
                </Indicator>

                <Menu
                  width={300}
                  shadow="md"
                  transition="pop-bottom-right"
                  transitionDuration={150}
                  withArrow
                  trigger="hover"
                  openDelay={100}
                  closeDelay={400}
                >
                  <Menu.Target>
                    <Avatar
                      radius="xl"
                      size="md"
                      style={{
                        boxShadow: `0 0 0 2px #c49b36`,
                        cursor: "pointer",
                      }}
                      variant="outline"
                      color="grape"
                      src={
                        user !== undefined
                          ? "data:image/png;base64," + user.pics
                          : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                      }
                      //"https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                    />
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Card
                      withBorder
                      p="xl"
                      radius="md"
                      //className={}
                    >
                      <Card.Section
                        sx={{
                          backgroundImage: `url(https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80)`,
                          height: 140,
                        }}
                      />

                      <Avatar
                        src={
                          user !== undefined
                            ? "data:image/png;base64," + user.pics
                            : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                        }
                        size={80}
                        radius={80}
                        mx="auto"
                        mt={-30}
                        className={classes.avatars}
                      />

                      <Text align="center" size="lg" weight={500} mt="sm">
                        {user.name}
                      </Text>
                      <Text align="center" size="sm" color="dimmed">
                        {" "}
                        👤 Global's Role
                      </Text>
                      <Menu.Label>{new Date().toDateString()} </Menu.Label>

                      <Menu.Divider />
                      <Menu.Label>Profile</Menu.Label>
                      <Menu.Item
                        icon={<IconSettings size={14} />}
                        // onClick={() => console.log("log")}
                      >
                        Settings
                      </Menu.Item>
                      <Menu.Item icon={<IconMessageCircle size={14} />}>
                        Notification
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Label>Switch-off</Menu.Label>

                      <Menu.Item
                        color="red"
                        icon={<IconLogout size={14} />}
                        onClick={Logout}
                      >
                        Sign-out
                      </Menu.Item>
                    </Card>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </div>
          </Header>
        </>
      }
    >
      <Container my="xs">
        <Card shadow="md" radius="md">
          {/* <TabsDashboard /> */}

          <HeroHeader />

          <TransferModule />
        </Card>

        {/* <GridLayout title={""} completed={0} total={0} description={""} /> */}
      </Container>
    </AppShell>
  );
}
