import {
  ActionIcon,
  Divider,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Popover,
  TextInput,
  Text,
} from "@mantine/core";
import {
  Button,
  List,
  Select,
  message,
  Upload,
  Steps,
  Table,
  Popconfirm,
} from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddItemContext from "../../AddItemsContext";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import { IconSquareCheck, IconSquareX } from "@tabler/icons";

let p: any;
let data: any = [];

interface DataType {
  key: string;
  items: string;
  status: number;
  createdAt: any;
}

const ApprovalLogGrid = (props: any) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [t, setT] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [counter, setCounter] = useState(0);
  const [values, setValues] = useState(0);

  const { addToItem, items } = useContext(AddItemContext);

  let { user } = useSelector((state: any) => ({ ...state }));

  let { show } = useSelector((state: any) => ({ ...state }));

  const navigate = useNavigate();

  let { control_id } = useParams();

  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "id",
      key: "id",
      render: (value: any, item: any, index: any) =>
        index === 0 ? index + 1 : index + 1,
    },
    {
      title: "Control-ID",
      dataIndex: "control_number",
      key: "control_number",
      responsive: ["lg"],
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Action(s)",
      dataIndex: "uuid",
      key: "uuid",
      //sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (datas: any, a: any) => {
        return (
          <Group position="center" spacing="sm">
            <Popconfirm
              title="Confirmation"
              // description="Are you sure to delete this task?"
              // onConfirm={confirm}
              onConfirm={() => confirm(a.key)}
              okText="Yes"
              cancelText="No"
            >
              <ActionIcon
                variant="subtle"
                component="button"
                disabled={a.button_ui}
                // onClick={() => console.log(a.button_ui)}
              >
                <IconSquareCheck size={24} color={"lime"} />
              </ActionIcon>
            </Popconfirm>
            {/* <ActionIcon variant="light">
              <IconSquareX size={24} color={"red"} />
            </ActionIcon> */}

            <Popover
              width={300}
              trapFocus
              position="top-end"
              withArrow
              shadow="md"
            >
              <Popover.Target>
                <ActionIcon
                  // variant=""

                  variant="subtle"
                  component="button"
                  disabled={a.button_ui}
                >
                  <IconSquareX size={24} color={"red"} />
                </ActionIcon>
              </Popover.Target>

              <Popover.Dropdown
                sx={(theme) => ({
                  background:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[7]
                      : theme.white,
                  // height: 50,
                })}
              >
                <TextInput
                  label="Email"
                  placeholder="john@doe.com"
                  size="xs"
                  mt="sm"
                />
                <Divider my="sm" variant="dashed" />
                <TextInput
                  label="Email"
                  placeholder="john@doe.com"
                  size="xs"
                  mt="xs"
                />
                <br />
                <Button>Ok</Button>
              </Popover.Dropdown>
            </Popover>
          </Group>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], index: any) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const loadDataFromDB = () => {
    data = [];

    fetch(`http://localhost:5000/requests/${control_id}`)
      .then((res) => res.json())
      .then((res) => {
        res.data.forEach((value: any, index: any) => {
          // console.log(counter);
          data.push({
            key: value.uuid,
            items: value.items,
            quantity: value.quantity,
            control_number: value.control_number,
            status: value.status,
            button_ui: value.button_ui,
            counts_of_items: value.count_of_items,
            id: value.id,
          });
          setValues(Number(value.count_of_items));
        });
        setDataSource(data);
      })
      .finally(() => {
        //   setLoading(false);
      });
    //  };
  };

  const confirm = async (uuid: any) => {
    await axios
      .put(`http://localhost:5000/requisitions/${uuid}`, {
        status: "APPROVED",
      })
      .then(() => {
        setCounter((prevCount) => prevCount + 1);
        loadDataFromDB();
        message.success({
          content: "Operation is Successfully !",
          duration: 7,
        });
      });
  };

  const saveLogs = async () => {
    console.log("Hello");
    await axios
      .put(`http://localhost:5000/requestlog/${control_id}`, {
        status: "TREATED",
      })
      .then((item) => {
        //  setCounter((prevCount) => prevCount + 1);
        //  loadDataFromDB();
        console.log(item);
        message.success({
          content: "Request had been updated Successfully !",
          duration: 7,
        });
        navigate("/admin/approval/");
      });
  };

  useEffect(() => {
    loadDataFromDB();
  }, []);

  // const arrayFunction = (element) =>{

  // }

  // useEffect(() => {
  //   let a = _.every(dataSource, (element: any) => {
  //     console.log(element.button_ui);
  //   });

  //   //console.log(a);
  // });

  useEffect(() => {
    buttonFunction();
  });

  const buttonFunction = () => {
    if (Number(values) && counter == Number(values)) {
      setButtonDisabled(false);
    } else if (
      _.every(dataSource, (element: any) => element.button_ui === true)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  return (
    <Paper
      shadow="md"
      radius="lg"
      p="lg"
      withBorder
      style={{ padding: "0px", margin: "30px" }}
    >
      <div className="removeMargin">
        <div className="firstHalf">
          <div
            style={{
              padding: 40,
              height: "auto",
            }}
          >
            <Text>
              Please click the <strong>save</strong> button to exit once you
              have finished handling the request below.
            </Text>
            <br /> <br />
            <Text color="dimmed" style={{ marginLeft: 10, top: 70 }}>
              {" "}
              {hasSelected
                ? `Selected ${selectedRowKeys.length} items`
                : ""}{" "}
            </Text>
            <Table
              rowSelection={rowSelection}
              className="table align-middle table-row-dashed fs-6 gy-4 mb-0"
              dataSource={dataSource}
              columns={columns}
              bordered
              // onChange={onChange}
            />
            <Button
              type="primary"
              danger
              disabled={buttonDisabled}
              onClick={() => saveLogs()}
            >
              {" "}
              Save
            </Button>
            <Divider style={{ marginTop: 20 }} />
          </div>
        </div>

        <div className="secondHalf">
          <div className="custom-shape-divider-bottom-1671865759">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fills"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fills"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default ApprovalLogGrid;
